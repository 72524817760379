import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { convertDateFormat, videoUrlToImgUrl } from "../../../utils/extension";

const Imagedetails = ({ data, category }) => {
  const [date, setDate] = useState();
  const [placeholderUrl, setPlaceholderUrl] = useState("");
  const [thumnailUrl, setThumnailUrl] = useState("");
  const [websiteScreenshotUrl, setWebsiteScreenshotUrl] = useState("");

  useEffect(() => {
    if (category?.toLowerCase() === "videos") {
      if (data?.image) {
        videoUrlToImgUrl(data?.image).then((url) => {
          setThumnailUrl(url);
        });
      }
    }
    const placeholderUrl = `https://ui-avatars.com/api/?color=text.secondary.main&name=${data?.title}`;
    const websiteScreenshot = `https://image.thum.io/get/auth/53787-sov/${data?.url}/`
    setWebsiteScreenshotUrl(websiteScreenshot)
    setPlaceholderUrl(placeholderUrl);
    convertDateFormat(data?.date).then((date) => {
      setDate(date);
    });
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${
          thumnailUrl ? thumnailUrl : data?.image ? data?.image : websiteScreenshotUrl ? websiteScreenshotUrl : placeholderUrl
        })`,
        height: { xs: "45vh", sm: "440px", lg: "640px" },
        backgroundSize: "cover",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        position: "relative",
      }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      ></Box>
      <Box
        zIndex={1}
        className="image-container"
        sx={{
          width: { xs: "90% !important", lg: "60%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{
            marginTop: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "12px",
            color: "#111111",
            width: "69px",
            borderRadius: "22px",
            height: "28px",
            background: "#FFFFFF",
            borderRadius: "22px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize="12px">{category}</Typography>
        </Box> */}
        <Box
          className="imageText"
          sx={{
            marginTop: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            textAlign: "center",
            color: "#FFFFFF",
            display:"flex",
            justifyContent:"center"
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "32px", md: "42px", lg: "42px" },
              maxWidth:{xs:"100%", md:"90%"},
              textAlign:"center"
            }}
          >
            {data?.title}
          </Typography>
        </Box>
        <Box
          className="imageSmText"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "16px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "18px",
            gap: "16px",
            color: "#FFFFFF",
           
          }}
        >

          <Typography sx={{ textAlign:"center",}} className="imageSmText">{`published on ${date}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Imagedetails;
