import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import MainContainer from '../components/MainContainer';
import LoadingDefault from '../components/_shared/LoadingDefault';
import PrimaryCard from '../components/PrimaryCard';
import OutlinedCard from '../components/Card/OutlinedCard';
import { decryptJSON, searchFilter } from '../utils/extension';


const SearchResults = () => {
    const location = useLocation();

    const [searchResults, setSearchResults] = useState();
    const [showLoading, setShowLoading] = useState(true)

    const filterHomeData = async (homeData, searchValue) => {
        await searchFilter(homeData, searchValue)
            .then((results) => {
                setSearchResults(results)
            })
    }
    useEffect(() => {
        setShowLoading(true)
        const queryString = location.search;
        // parse the query string to get individual parameters if needed
        const params = new URLSearchParams(queryString);
        const searchValue = params.get('q');
        if (searchValue) {
            const cachedData = localStorage.getItem('homeData');
            if (cachedData) {
                const homeData = decryptJSON(cachedData);
                if (homeData) {
                    // const data = JSON.parse(homeData);
                    filterHomeData(homeData, searchValue)
                } else {
                    // Data doesn't exist in localStorage, fetch it or set default values
                }
            }

            setShowLoading(false);
        }
    }, [location.search])

    return (
        <Box sx={{
            bgcolor: "background.main",
            width: "100%",
            color: "text.primary.main",
            paddingTop: "56px",
            paddingBottom: "72px",
        }}>
            <MainContainer>
                {
                    showLoading ? <LoadingDefault /> :
                        searchResults !== undefined ?
                            <Box sx={{
                                width: "100%",
                                color: "text.primary.main",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: { xs: "40px", md: "56px" },
                            }}>
                                {/* Arxiv results */}
                                {
                                    searchResults?.arxiv?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            ArXiv-Quant
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.arxiv
                                                    ?.map((data, index) => {

                                                        return (
                                                            <PrimaryCard
                                                                category="Arxiv"
                                                                data={data}
                                                            />
                                                        )
                                                    })}
                                            </Box>

                                        </Box>
                                        {/* End Tabs section */}
                                    </Box>
                                }


                                {/* SSRN results */}
                                {
                                    searchResults?.ssrn?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            SSRN-Quant
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.ssrn
                                                    ?.map((data, index) => {

                                                        return (
                                                            <PrimaryCard
                                                                category="Ssrn"
                                                                data={data}
                                                            />
                                                        )
                                                    })}
                                            </Box>

                                        </Box>
                                    </Box>
                                }

                                {/* Seminar results */}
                                {
                                    searchResults?.seminar?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Seminar
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.seminar?.map((data) => {
                                                    return <OutlinedCard data={data} />;
                                                })}

                                            </Box>

                                        </Box>
                                    </Box>
                                }


                                {/* Github results */}
                                {
                                    searchResults?.github?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Github
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.github?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="Github"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* Blogs results */}
                                {
                                    searchResults?.blogs?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Blogs
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.blogs?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="Blogs"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* Videos results */}
                                {
                                    searchResults?.video?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Videos
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.video?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="Videos"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* PWC results */}
                                {
                                    searchResults?.pwc?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            PWC
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.pwc?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="PWC"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* News results */}
                                {
                                    searchResults?.news?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            News
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.news?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="News"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* Linkedin results */}
                                {
                                    searchResults?.linkedin?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Linkedin
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.linkedin?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            category="Linkedin"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }


                                {/* Reddit results */}
                                {
                                    searchResults?.reddit?.length !== 0 &&
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "42px",
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                            fontWeight={500}
                                        >
                                            Reddit
                                        </Typography>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "42px",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "grid",
                                                    gridTemplateColumns: {
                                                        xs: "repeat(1, 1fr)",
                                                        sm: "repeat(2, 1fr)",
                                                        md: "repeat(3, 1fr)",
                                                    },
                                                    gap: { xs: "24px", lg: "32px" },
                                                    width:"100%"
                                                }}
                                            >
                                                {searchResults?.reddit?.map((data, index) => {

                                                    return (
                                                        <PrimaryCard
                                                            id={index}
                                                            category="Reddit"
                                                            data={data}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    </Box>
                                }

                            </Box> :
                            <Box sx={{
                                // height: "40vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
                                    fontWeight={500}>No results found</Typography>
                            </Box>
                }
            </MainContainer>
        </Box>

    )
}

export default SearchResults