import { createTheme } from "@mui/material";
import Poppins from '../assets/fonts/Poppins-Regular.ttf'
import "@fontsource/poppins"

export const lightTheme = createTheme({
    typography: {
        fontFamily: [
            'Poppins', 'Roboto'
        ].join(','),
    },
    tab: {
        fontFamily: [
            'Poppins', 'Roboto'
        ].join(','),
    },
    palette: {
        primary: {
            main: '#FFFFFF'
        },
        secondary: {
            main: '#F6F6F6'
        },
        background: {
            main: "#F6F6F6"
        },
        surface: {
            main: '#FFFFFF'
        },
        text: {
            primary: {
                main: '#000000'
            },
            secondary: {
                main: 'rgba(0, 0, 0, 0.5);'
            },
            
        },
        tab:{
            background: {
                main: "#3367B3"
            },
            text: {
                main: "#FFFFFF"
            }
        },
      
        viewMoreBtn: {
            background: {
                main: "#f5f5f5"
            },
            text: {
                main: "#000000"
            }
        }
    },
    breakpoints: {
        xs: 0,
        sm: 560,
        md: 990,
        lg: 1024,
        xl: 1441,
        mobile: 0,
        tablet1: 560,
        tablet2: 800,
        desktop: 1024,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "Poppins",
                    src: `url(${Poppins}) format("truetype")`
                },
            }
        }
    },
})

export const darkTheme = createTheme({
    typography: {
        fontFamily: [
            'Poppins', 'Roboto'
        ].join(','),
    },
    tab: {
        fontFamily: [
            'Poppins', 'Roboto'
        ].join(','),
    },
    palette: {
        primary: {
            main: '#1F1D2B'
        },
        secondary: {
            main: '#252836'
        },
        background: {
            main: "#212230"
        },
        surface: {
            main: '#252836'
        },
        text: {
            primary: {
                main: '#FFFFFF'
            },
            secondary: {
                main: '#7D7A89'
            }
        },
        viewMoreBtn: {
            background: {
                main: "#1E1F2E"
            },
            text: {
                main: "#FFFFFF"
            }
        },
        tab:{
            background: {
                main: "#373A50"
            },
            text: {
                main: "#FFFFFF"
            }
        }
    },
    breakpoints: {
        xs: 0,
        sm: 560,
        md: 990,
        lg: 1024,
        xl: 1441,
        mobile: 0,
        tablet1: 560,
        tablet2: 800,
        desktop: 1024,
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "Poppins",
                    src: `url(${Poppins}) format("truetype")`
                },
            }
        }
    },
})