import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Button = ({
  text,
  button_class,
  textStyles,
  url,
  state,
  backgroundColor = "#3367B3",
  border = "none",
  color = "white",
  onClick,
  disabled,
  target,
  isLoading,
}) => {
  return (
    <>
      <Link
        to={!onClick && url}
         target={target && target}
        state={state}
        style={{
          textDecoration: "none",
        }}
      >
        <button
          type="button"
          onClick={onClick}
          disabled={disabled}
          className={button_class}
          style={{
            position: "relative",
            borderRadius: "24px",
            paddingTop: "11px",
            paddingBottom: "11px",
            paddingLeft: "18px",
            paddingRight: "18px",
            fontWeight: "500",
            fontSize: "16px",
            color: color,
            backgroundColor: backgroundColor,
            border: border,
            outline: "none",
            cursor: "pointer",
          }}
        >
          {!disabled && (
            <Typography sx={{ lineHeight: "100%", ...textStyles }}>
              {text}
            </Typography>
          )}
          
           {disabled && <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <CircularProgress size={"15px"} color={"inherit"} />
            </Box>}
          
        </button>
      </Link>
    </>
  );
};

export default Button;
