import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import CategoryLayout from "./components/CategoryLayout";
import MasonryLayout from "../../components/MasonryLayout";
import { getDataFromDatabase } from "../../components/_shared/ApiRequests";
import { decryptJSON, encryptJSON } from "../../utils/extension";

const Home = () => {

  const [homeData, setHomeData] = useState();

  useEffect(() => {
    
    const cachedData = localStorage.getItem('homeData');
    cachedData && setHomeData(decryptJSON(cachedData))
    setTimeout(() => {
      getDataFromDatabase("home").then((list) => {
        const ciphertext = encryptJSON(list);
        localStorage.removeItem('homeData');
        localStorage.setItem('homeData', ciphertext);
        setHomeData(list);
      });
    }, 2000);
  
  }, []);

  useEffect(() => { }, [homeData]);

  return (
    <MainContainer>

      <Box
        sx={{
          bgcolor: "background.main",
          width: "100%",
          color: "text.primary.main",
          paddingTop: "56px",
        }}
      >
        <MasonryLayout>
          <CategoryLayout data={homeData?.arxiv} title="ArXiv">
          </CategoryLayout>

          <CategoryLayout category="ssrn" data={homeData?.ssrn} title="SSRN PAPERS">
          </CategoryLayout>

          <CategoryLayout data={homeData?.github} title="Github">
          </CategoryLayout>

          <CategoryLayout data={homeData?.linkedin} title="Linkedin">
          </CategoryLayout>

          <CategoryLayout data={homeData?.video} title="Videos">
          </CategoryLayout>

          <CategoryLayout data={homeData?.blogs} title="Blogs">
          </CategoryLayout>

          <CategoryLayout data={homeData?.pwc} title="PwC">
          </CategoryLayout>

          <CategoryLayout data={homeData?.reddit} title="Reddit">
          </CategoryLayout>

          <CategoryLayout data={homeData?.news} title="News">
          </CategoryLayout>

          <CategoryLayout data={homeData?.jobs} title="Jobs">
          </CategoryLayout>

          <CategoryLayout data={homeData?.repec} title="Repec">
          </CategoryLayout>

          <CategoryLayout data={homeData?.podcasts} title="Podcasts">
          </CategoryLayout>
        </MasonryLayout>
      </Box>
    </MainContainer>
  );
};

export default Home;
