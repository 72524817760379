import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MainContainer from "../components/MainContainer";
import Logo from "../assets/Icons/logo.svg";
import Search from "../appSearch/AppSearch";
import Button from "../components/Button";
import ThemeSwitchButton from "../components/ThemeSwitchButton";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarTab from "../components/NavbarTab";
import { useLocation, useNavigate } from "react-router-dom";

import { GlobalState } from "../App";
import NavOthersDropdown from "./NavOthersDropdown";
import { auth } from "../services/firebase/firebase.config";
import ProfileMenu from "./ProfileMenu";
import { useAuthState } from "react-firebase-hooks/auth";
import IconASvg from "../assets/Icons/IconASvg";
import IconSSvg from "../assets/Icons/IconSSvg";
import IconBlogSvg from "../assets/Icons/IconBlogSvg";
import GithubSvg from "../assets/Icons/GithubSvg";
import Message from "./Message";


const Navbar = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const { isDarkMode } = useContext(GlobalState);
  const [activeTab, setActiveTab] = useState("/");

  const location = useLocation();

  // context states
  const { userDetails, subscription } = useContext(GlobalState);
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    const currentUrlPath = location.pathname.split("/")[1];
    if (currentUrlPath !== "") {
      setActiveTab(currentUrlPath);
    } else {
      setActiveTab("home");
    }
  }, [location]);

  useEffect(() => {
  }, [])

  useEffect(() => {
  }, [user, loading]);




  function open_sidebar() {
    document.querySelector("body").style.overflow = "hidden";
    document.getElementById("mySidenav").style.display = "flex";
  }

  return (
    <>
      <Box
        sx={{
          paddingY: "20px",
          paddingBottom: "20px",
          borderBottom: isDarkMode
            ? "1px solid rgba(255, 255, 255, 0.08)"
            : "1px solid rgba(0, 0, 0, 0.08)",
          bgcolor: "primary.main",
          position:"fixed",
          top: "0",
          left: "0",
          width: "100%",
          zIndex: 100,
        }}
      >

        <MainContainer bgcolor="primary.main">
          {/* navigation bar top */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="20px"
          >
            <Box
              display="flex"
              gap="16px"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                user ?
                subscription &&
                  window.location.replace("/"):
                  window.location.replace("/public")
              }}
              alignItems="center"
            >
              <img width={md ? "32px" : "42px"} src={Logo} alt="logo" />
              <Box
                sx={{
                  display: md ? "none" : "block",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 12, lg: 16 },
                    color: "text.primary.main",
                    fontWeight: 400,
                    lineHeight: "120%",
                  }}
                >
                  ᴍʟ-ǫᴜᴀɴᴛ ғɪɴᴀɴᴄᴇ
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 11, lg: 16 },
                    color: "text.secondary.main",
                    fontWeight: 400,
                  }}
                >
                  Machine Learning & Quantitative Finance
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{ gap: { xs: "12px", sm: "24px", lg: "30px" } }}
              alignItems="center"
            >

              {/* <NavbarTab
               styles={{fontSize:"16", fontWeight:"700"}}
                  redirectTo="/pricing"
                  // iconName=""
                  iconSize="24"
                  activeTab={activeTab}
                  title="Pricing"
                /> */}

              <Box sx={{ display: "flex" }}>
                {(subscription && user) && <Search />}

                {!loading && !(user) && (
                  <Button
                  textStyles={{fontSize:"14px"}}
                    url="/login"
                    button_class="nav_login_btn"
                    text="Login"
                  />
                )}
              </Box>

              {!(subscription && user) ? (
                <ThemeSwitchButton />
              ) : md ? (
                <></>
              ) : (
                <ThemeSwitchButton />
              )}
              {user && <ProfileMenu />}

              {md ? (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    open_sidebar();
                  }}
                >
                  {subscription && user && (
                    <MenuIcon
                      style={{ color: isDarkMode ? "white" : "black" }}
                    />
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </MainContainer>
      </Box>
      {
      (!user && location?.pathname === "/public") &&
      <Message />
    }
      {md ? (
        <></>
      ) : subscription && userDetails ? (
        <Box
          sx={{
            borderBottom: isDarkMode
              ? "1px solid rgba(255, 255, 255, 0.08)"
              : "1px solid rgba(0, 0, 0, 0.08)",
            bgcolor: "primary.main",
            position:"fixed",
            top: "84px",
            left: "0",
            width: "100%",
            zIndex: 100,
          }}
        >
          <MainContainer bgcolor="primary.main">
            {/* navigation bar tabs */}
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Box display="flex">
                {/* nav tab container */}
                <NavbarTab
                  redirectTo="/"
                  iconName="home"
                  iconSize="24"
                  activeTab={activeTab}
                  title="Home"
                />
                <NavbarTab
                  redirectTo="arxiv"
                  iconName="aLetter"
                  iconSize="24"
                  activeTab={activeTab}
                  title="Arxiv"
                  icon={<IconASvg
                    color={activeTab === 'arxiv' ? "#3367B3" : "#9C9C9C"}
                  />}
                />
                <NavbarTab
                  redirectTo="ssrn"
                  iconName="activity"
                  iconSize="24"
                  activeTab={activeTab}
                  title="SSRN"
                  icon={<IconSSvg
                    color={activeTab === 'ssrn' ? "#3367B3" : "#9C9C9C"}
                  />}
                />
                <NavbarTab
                  redirectTo="blogs"
                  iconName="activity"
                  iconSize="24"
                  activeTab={activeTab}
                  title="Blogs"
                  icon={<IconBlogSvg
                    color={activeTab === 'blogs' ? "#3367B3" : "#9C9C9C"}
                  />}
                />

                <NavbarTab
                  redirectTo="github"
                  iconName="github"
                  iconSize="24"
                  activeTab={activeTab}
                  title="Github"
                // icon={<GithubSvg 
                //   color={activeTab === 'github' ? "#3367B3" : "#9C9C9C"}
                //    />}
                />
                <NavOthersDropdown />
              </Box>
            </Box>
          </MainContainer>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Navbar;
