import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import SingleLineEllipsis from "../../../components/_shared/SingleLineEllipsis";

const Textdetails = ({ data, category }) => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setShowLoading(false);
    }
  }, [data]);

  return (
    <Box
      className="textdetail-container"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: { xs: "40px", md: "80px" },
        alignText: "center",
        gap: { xs: "20px", lg: "150px" },
        width: "100%",
      }}
    >
      <Box
        className="textdetail-container-box"
        sx={{
          width: "60%",
          display: "flex",
          gap: { xs: "24px", md: "32px" },
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "22px", lg: "33px" } }}
            color="text.primary.main"
            fontWeight="700"
          >
            {data?.title}
          </Typography>{" "}
        </Box>
        <Box
          sx={{
            // marginTop: "32px",
            fontFamily: "Roboto",
            fontStyle: "normal",

            lineHeight: "28px",

            color: "text.primary.main",
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "14px", lg: "18px" }, fontWeight: 400 }}
          >
            {data?.description}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxWidth: { xs: "100%", sm: "30%" },
          flexWrap: "wrap",
          width: "100%",
        }}
        className="textdetail-contain-box2"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "Column",
            gap: "8px",
            width: "100%",
            color: "text.primary.main",
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "18px",
              textTransform: "uppercase",
              color: "#3367B3",
            }}
          >
            category
          </Typography>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "18px",
              color: "text.primary.main",
              marginLeft: "4px",
            }}
          >
            {category}
          </Typography>
        </Box>
        {data?.author && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              className="imageSmText"
              sx={{
                display: "flex",
                color: "#3367B3",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Authors
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {[data?.author?.split(",")][0].map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      background: "#1F1D2B",
                      borderRadius: "12px",
                      // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      display: "flex",
                      justifyContent: "center",
                      padding: "6px 24px",
                      alignItems: "center",
                      color: "#111111",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "white",
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

{data?.views?.toString() && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3367B3",
              }}
            >
              Views
            </Typography>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "text.primary.main",
                marginLeft: "4px",
              }}
            >
              {data?.views?.toString()}
            </Typography>
          </Box>
        )}

        {data?.score && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3367B3",
              }}
            >
              Score
            </Typography>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "text.primary.main",
                marginLeft: "4px",
              }}
            >
              {data?.score}
            </Typography>
          </Box>
        )}

        {data?.rank && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3367B3",
              }}
            >
              Rank
            </Typography>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "text.primary.main",
                marginLeft: "4px",
              }}
            >
              {data?.rank}
            </Typography>
          </Box>
        )}

        {data?.totalDownloads?.toString() && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3367B3",
              }}
            >
              Total Downloads
            </Typography>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "text.primary.main",
                marginLeft: "4px",
              }}
            >
              {data?.totalDownloads}
            </Typography>
          </Box>
        )}

        {data?.websiteLink && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              width: "100%",
              color: "text.primary.main",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                color: "#3367B3",
              }}
            >
              website Link
            </Typography>
            <a
            href={`https://${data?.websiteLink}`}
            target="_blank"
              style={{
                cursor:"pointer",
                textDecoration:"none"
              }} rel="noreferrer"
            >
            <Typography sx={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "text.primary.main",
                marginLeft: "4px",
              }}>{data?.websiteLink}</Typography>  
            </a>
          </Box>
        )}

        {
          data?.companyName && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "Column",
                gap: "8px",
                width: "100%",
                color: "text.primary.main",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#3367B3",
                }}
              >
                Company
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "text.primary.main",
                  marginLeft: "4px",
                }}
              >
                {data?.companyName}
              </Typography>
            </Box>
          )
        }

        {data?.url && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "Column",
              gap: "8px",
              maxWidth: "100%",
              color: "text.primary.main",
              flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                width: "100%",
                color: "#3367B3",
              }}
            >
              Link
            </Typography>

            <a
              target="_blank"
              style={{
                textDecoration: "none",
                maxWidth: "100%",
                width: "100%",
              }}
              href={data?.url} rel="noreferrer"
            >
              <SingleLineEllipsis
                style={{ fontSize: "16px" }}
                color="text.primary.main"
                text={data?.url?.toString()}
              />
            </a>
          </Box>
        )}

        {
          data?.skills && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "Column",
                gap: "8px",
                maxWidth: "100%",
                color: "text.primary.main",
                flexWrap: "wrap",
              }}
            >
              <Typography sx={{
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                width: "100%",
                color: "#3367B3",
              }}>
                Required Skills
              </Typography>
              {
                data?.skills?.map((skill, index) => {
                  return (
                    <Typography sx={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "18px",
                      color: "text.primary.main",
                      marginLeft: "4px",
                    }} key={index}>{skill}</Typography>
                  )
                })
              }
            </Box>
          )
        }

        {
          data?.location && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "Column",
                gap: "8px",
                width: "100%",
                color: "text.primary.main",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#3367B3",
                }}
              >
                Location
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "text.primary.main",
                  marginLeft: "4px",
                }}
              >
                {data?.location}
              </Typography>
            </Box>
          )
        }

        {
          data?.salary && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "Column",
                gap: "8px",
                width: "100%",
                color: "text.primary.main",
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "#3367B3",
                }}
              >
                Salary
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "text.primary.main",
                  marginLeft: "4px",
                }}
              >
                {data?.salary}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default Textdetails;
