import React, { useState, useEffect } from "react";
import { Box, Typography, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../../assets/Icons/logo.svg";
import {
  auth,
  logInWithEmailAndPassword,
} from "../../services/firebase/firebase.config";
import MainContainer from "../../components/MainContainer";
import MyTextField from "./component/MyTextField";
import Button from "../../components/Button";
import GoogleSignInButton from "./component/GoogleSiginButton";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { checkUserExist, checkUserSubscription, createUser } from "../../components/_shared/ApiRequests";
import {
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { GetFirebaseToken } from "../../utils/extension";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadingDefault from "../../components/_shared/LoadingDefault";
import { Info, InfoOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";
const textStyle = {
  color: "#FFF",
  textAlign: "center",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "0.155px",
};

const CustomTypography = styled(Typography)(({ sm, md }) => ({
  fontSize: sm ? "16px" : md ? "18px" : "22px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
}));

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errormessage, setErrormessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("365"));

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const register = async () => {
    setIsLoading(true);
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;
      if (user) {
        setErrormessage("");
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          setOpen(true)
          setErrormessage("Invalid Email/Password");
          break;
        case 'auth/invalid-email':
          setErrormessage("Invalid Email/Password");
          break;
        case 'auth/user-not-found':
          setErrormessage("Invalid Email/Password");
          break;
        case 'auth/too-many-requests':
          setErrormessage("Too many attempts to sign in. Please try again later.");
          break;
        case 'auth/weak-password':
          setErrormessage("Password is not strong enough. Add additional characters including special characters and numbers.");
          break;
        default:
          setOpen(true)
          setErrormessage("Please check your email/password and try again later.");
          break;
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (user === null || user === undefined) {
      } else {
        setIsLoading(true);
        checkUserExist(auth).then(async (data) => {
          if (data.userExist === false) {
            await createUser(auth);
            navigate("/");
          } else {
            navigate("/");
          }
        }).catch((e) => {
          setIsLoading(false)
          auth.signOut()

        })

      }
    }
  }, [user, loading]);



  if (loading) {
    return <LoadingDefault />;
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const dialogStyles = {
    // Add your custom styles here
    backgroundColor: 'your-color-here',
    // Add other styles as needed
  };

  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: 'https://www.ml-quant.com/login',
    handleCodeInApp: false
  };


  return (
    <MainContainer>
      <Helmet>
        <title>ML-Quant - Machine Learning and Quantitative Finance</title>
        <meta
          name="description"
          content="Login - ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, and Reddit. Email. Password. Login. Fogot Password? Or Continue with Google."
        />
        <meta
          name="keywords"
          content="ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, Reddit, Jobs, Repec, PwC"
        />
      </Helmet>
      {open && <Box bgcolor={"background.main"}>
        <Dialog
          sx={{
            borderRadius: "24px !important",
            '& .MuiPaper-root': {
              background: "#212230"
            },
            // '& .MuiBackdrop-root': {
            //   backgroundColor: 'transparent' // Try to remove this to see the result
            // }
          }}
          style={{
            backgroundColor: "#00000027",
          }}
          PaperProps={{
            style: {
              backgroundColor: 'background.main',
            }
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{padding:"0px", mb:"4px"}} color={"text.primary.main"} bgcolor={"background.main"}>{"Welcome back to our website! For our valued old website users."}</DialogTitle>
          <DialogContent bgcolor={"background.main"}>
            <DialogContentText bgcolor={"background.main"} id="alert-dialog-slide-description" color={"text.secondary.main"}>
              We've made it easy to reset your password. If you've forgotten your login credentials or need to update your password for security reasons, you can request a password reset link. Simply click the 'Reset Password' button below, and we'll send you an email with instructions on how to reset your password and regain access to your account.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{
            padding: "6px"
          }} bgcolor={"background.main"}>
            <Button text="Cancel" onClick={() => handleClose()}>Disagree</Button>
            <Button text="Reset Password" onClick={async () => { await sendPasswordResetEmail(auth, email, actionCodeSettings).then(d => alert("You will receive password reset link shortly.")).catch(e => alert(e)); handleClose() }}>Agree</Button>
          </DialogActions>
          <Box sx={{
            width: "100%",
            height: "6px"
          }}></Box>
        </Dialog>
      </Box>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: md ? "62px 0" : "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "52px", height: "52px", marginBottom: "10px" }}
            />
            <CustomTypography md={md} sm={sm} sx={{ color: "text.primary.main", width: "100%", textAlign: "center" }}>
              Welcome to ML-QUANT Finance.
            </CustomTypography>
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: sm? "12px": "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "center",
                maxWidth: "350px",
                fontFamily: "Poppins",
              }}
            >
              ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, and
              Reddit
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "9px",
            }}
          >
            <MyTextField
              label="Email"
              placeholder="Enter your email"
              state={email}
              setState={setEmail}
            // error={errormessage}
            // helperText={errormessage && "invalid credentials"}
            />
            <MyTextField
              label="Password"
              placeholder="Enter your password"
              state={password}
              setState={setPassword}
              // error={errormessage}
              // helperText={errormessage && "invalid credentials"}
              type="password"
            />
          </Box>
          <Box sx={{ marginTop: md?"32px": "40px", display: "flex", flexDirection: "column", gap: "8px" }}>
            <Button
              button_class="login_btn"
              text="Login"
              textStyles={textStyle}
              onClick={register}
              disabled={isLoading}
            />
            {errormessage &&
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <InfoOutlined htmlColor="red" sx={{ width: "14px", height: "14px", }} />
                <Typography sx={{ color: "red", fontSize: "12px" }}>{errormessage}</Typography>
              </Box>
            }
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            onClick={async () => {
              navigate("/reset")
            }}
          >
            <Typography
              sx={{
                color: "#3367B3",
                textAlign: "center",
                fontSize: "12.76px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.255px",
              }}
            >
              Fogot Password?
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "text.secondary.main",
              textAlign: "center",
              fontSize: "13.672px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginTop: "30px",
            }}
          >
            Or Continue with
          </Typography>
          <Box sx={{ marginTop: "30px" }}>
            <GoogleSignInButton />
          </Box>
          <Box sx={{ marginTop: "25px" }}>
            <Typography
              sx={{
                color: "text.primary.main",
                textAlign: "center",
                fontSize: "12.76px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.255px",
              }}
            >
              Dont't have an account?{" "}
              <Link
                component={RouterLink}
                to="/signup"
                style={{ textDecoration: "none", color: "#3367B3" }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Login;
