import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React,{useContext} from 'react'
import MainContainer from '../../components/MainContainer'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GlobalState } from '../../App';
import ProfileTabs from './component/ProfileTabs';
import ProfileTabtwo from './component/ProfileTabtwo';
import { useEffect } from 'react';

const Profile = () => {

    const { isDarkMode } = useContext(GlobalState);
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    const md = useMediaQuery(theme.breakpoints.down("md"));

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <div
            style={{ borderRadius: "20px" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            color: 'text.primary.main',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}>Settings</Typography>

                    <Box marginTop={'45px'}
                      >
                        <Tabs
                            indicatorColor="none"
                            sx={{
                                maxHeight: { xs: "45px", md: "50px" },
                                minHeight: "auto",
                                
                            }}
                            style={{
                                padding: "3px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: " 0.876712px solid #373A50",
                                borderRadius: " 14.0274px",
                                
                            }}
                            value={value}
                            onChange={handleChange}
                        >
                            <Tab
                                sx={{
                                    fontSize: {
                                        xs: "11px !important",
                                        md: "12px !important",
                                    },
                                    minWidth: "auto",
                                    padding: {
                                        xs: "12px 20px 12px 20px",
                                        md: "13px 43px 13px 43px",
                                    },
                                }}
                                style={{
                                    minHeight: "auto",
                                    color: value === 0 ? "white" : "#7D7A89",
                                    flex: "1",
                                    textAlign: "center",
                                    borderRadius: "11px",
                                    backgroundColor:
                                        value === 0
                                            ? isDarkMode
                                                ? "#373A50"
                                                : "#3367B3"
                                            : "transparent",
                                    textTransform: "capitalize",
                                    letterSpacing: "0.9px",
                                    lineHeight: "110%",
                                    fontWeight: "600",
                                }}
                                label="Profile"
                                {...a11yProps(0)}
                            />
                           <Tab
                                sx={{
                                    fontSize: {
                                        xs: "11px !important",
                                        md: "12px !important",
                                    },
                                    minWidth: "auto",
                                    padding: {
                                        xs: "12px 20px 12px 20px",
                                        md: "13px 43px 13px 43px",
                                    },
                                }}
                                style={{
                                    minHeight: "auto",
                                    color: value === 1 ? "white" : "#7D7A89",
                                    flex: "1",
                                    textAlign: "center",
                                    borderRadius: "11px",
                                    backgroundColor:
                                        value === 1
                                            ? isDarkMode
                                                ? "#373A50"
                                                : "#3367B3"
                                            : "transparent",
                                    textTransform: "capitalize",
                                    letterSpacing: "0.9px",
                                    lineHeight: "110%",
                                    fontWeight: "600",
                                }}
                                label="Membership"
                                {...a11yProps(1)}
                            /> 

                        </Tabs>
                         {value === 0 ? (
                             <TabPanel value={value} index={0}> 
                               <ProfileTabs/>
                            </TabPanel> 
                         ) : value === 1 && (
                           <TabPanel value={value} index={1}>
                                <ProfileTabtwo/>
                          </TabPanel>
                        )} 
                    </Box>

                </Box>
            </Box>
        </MainContainer>
    )
}

export default Profile