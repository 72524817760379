import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";

const NavbarTab = ({
  title,
  activeTab,
  iconName,
  iconViewBox,
  iconSize,
  redirectTo,
  styles,
  isDropdownItem,
  icon
}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Link
      style={{ listStyleType: "none", textDecoration: "none" }}
      to={redirectTo}
    >
      <Box
        display="flex"
        gap="12px"
        alignItems="center"
        height="100%"
        paddingLeft="23px"
        paddingRight="23px"
        paddingY={isDropdownItem ? "0px" : "16px"}
        color="text.secondary.main"
        sx={{ cursor: "pointer" }}
        borderBottom={
          isDropdownItem
            ? "none"
            : md
            ? "none"
            : activeTab.toLowerCase() === title.toLowerCase()
            ? "2px solid #3367B3"
            : "2px solid transparent"
        }
      >
        {/* {(!isDropdownItem && iconName) && (
          <Icon
            viewbox={iconViewBox}
            fill={
              activeTab.toLowerCase() === title.toLowerCase()
                ? "#3367B3"
                : "#9C9C9C"
            }
            name={iconName}
            size={iconSize}
          />
        )} */}
        {
          icon ? icon : (!isDropdownItem && iconName) && (
            <Icon
              viewbox={iconViewBox}
              fill={
                activeTab.toLowerCase() === title.toLowerCase()
                  ? "#3367B3"
                  : "#9C9C9C"
              }
              name={iconName}
              size={iconSize}
            />
          )
        }

        <Typography
          style={styles}
          fontSize={14}
          color={
            activeTab.toLowerCase() === title.toLowerCase()
              ? "#3367B3"
              : "#9C9C9C"
          }
          fontWeight={
            activeTab.toLowerCase() === title.toLowerCase() ? 600 : 400
          }
        >
          {title}
        </Typography>
      </Box>
    </Link>
  );
};

export default NavbarTab;
