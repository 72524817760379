import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import sammyLine from '../../images/sammyLine.svg'
import MainContainer from '../../components/MainContainer'
import { GlobalState } from '../../App';
import { BACKEND_URL, BACKEND_URL_LOCALHOST } from '../../utils/extension'
import { auth } from '../../services/firebase/firebase.config'
import { URL_TO_USE } from '../../components/_shared/ApiRequests'

const buttonstyle = {
  height: '57px',
  padding: '17px 63px',
  borderRadius: '54.687px',
  border: '1px solid #3367B3',
  background:'transparent',
  color: '#FFF',
  fontSize: '15.495px',
fontStyle: 'normal',
fontWeight: '700',
cursor: 'pointer'
}
const PaymentStatusScreen = () => {
  const { isDarkMode } = useContext(GlobalState);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate()
  
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    new URLSearchParams(window.location.search);
    if (window.location.pathname.split("/")[2] === "success=true") {
      setMessage("Payment Successful!");
      setSuccess(true)
    }

    if (window.location.pathname.split("/")[2] === "failed=true") {
      setMessage(
        "Payment Failed - Please try again!"
      );
      setSuccess(false)
    }
  }, []);

  useEffect(() => {

  }, [message])


  return (
    <MainContainer>
      <Box sx={{
        paddingBottom:"80px"
      }} marginTop={'25vh'}>
        <Box sx={{gap:"24px"}} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography sx={{
            color: 'text.primary.main',
            fontSize: md ? '24px' : '48px',
            fontStyle: 'normal',
            fontWeight: '900',
            lineHeight: '63.984px',
            textAlign: 'center'
          }}>{message}
          </Typography>
          {!success && <Box>
            <img src={sammyLine} alt='sammyLine' style={{ width: md ? '300px' : '402px' }} />
          </Box>}
          <Box display={'flex'} gap={'24px'} flexDirection={md && 'column'}>
            {success && <button style={buttonstyle} onClick={()=>navigate('/')}>Explore ML-Quant</button>}
            {!success && <form action={`${URL_TO_USE}subscription/create-checkout-session/${auth?.currentUser?.uid}`} method="POST">
              <button style={buttonstyle} className='' type="submit">
                Try Again!
              </button>
            </form>}
          </Box>
        </Box>
      </Box>
    </MainContainer>
  )

}


export default PaymentStatusScreen