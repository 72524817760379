import React, { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  SearchRounded,
} from "@mui/icons-material";
import { GlobalState } from "../App";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate()
  const { searchText } = useContext(GlobalState);
  const [appSearch, setAppSearch] = useState("")

  const handleSearch = () => {
    if (appSearch !== "") {
      if (searchInput?.current !== "") {
        navigate(`/search?q=${appSearch}`)
      }
    }
  }

  const searchInput = document.getElementById('searchInput');
  const submitButton = document.getElementById('submitButton');

  // on press enter search
  useEffect(() => {
    // if (appSearch !== "") {
      searchInput?.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
          if (appSearch !== "") {
            event.preventDefault();
            submitButton.click();
          } else{
            navigate("/")
          }
        }
      });
    // }
    window.onpopstate = function (event) {
      navigate("/")
      setAppSearch("")
      searchInput?.blur();
    };
  }, [appSearch])

  return (
    <Box
      sx={{
        bgcolor: "secondary.main",
        borderRadius: "32px",
        display: "flex",
        alignItems: "center",

        minWidth: sm ? "0px" : lg ? "280px" : "352px",
        maxWidth: sm ? "250px" : lg ? "280px" : "352px",
        width: "100%",
        transition: "width 1s",
        transitionTimingFunction: "ease",
        justifyContent: "flex-start",
      }}
    >
      <input
      required
        id="searchInput"
        placeholder="Search for anything"
        value={appSearch}
        // onFocus={() => {navigate("/search")}}
        onChange={(e) => {
          setAppSearch(e.target.value);
          searchText.current = e.target.value;
        }}
        style={{
          padding: "8px 8px",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          marginLeft: "6px",

          color: localStorage.getItem("theme")
            ? "rgba(0, 0, 0, 0.9)"
            : "rgba(255, 255, 255, 0.9)",
          fontWeight: 400,
          fontSize: "12px",
          width: "100%",
          transition: "width 2s",
          transitionTimingFunction: "ease-in",
        }}
      />

      <Box
        id="submitButton"
        onClick={() => { handleSearch() }}
        sx={{
          width: { xs: 15, lg: 15 },
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "tab.background.main",
          padding: "8px 20px",
          borderTopRightRadius: "32px",
          borderBottomRightRadius: "32px",
          cursor: "pointer"
        }}
        display="flex"
      >
        <SearchRounded
          sx={{
            color: localStorage.getItem("theme")
              ? "white"
              : "rgba(255, 255, 255, 0.5)",
            marginTop: "1px",
          }}
          fontSize="15px"
        />
      </Box>
    </Box>
  );
};

export default Search;
