import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import MainContainer from './components/MainContainer'
import Button from './components/Button'
import { Link } from 'react-router-dom'
import logo from '../src/assets/Icons/logo.svg'
import { GlobalState } from './App'
const Newsletter = () => {
    const { isDarkMode } = useContext(GlobalState);
    return (
        <Box sx={{ width: "100%", paddingBottom: "50px", backgroundColor: "background.main", flexDirection:'column', display: "flex", alignItems: 'center', justifyContent:"center", paddingTop: "15px" }}>
            <MainContainer>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: {xs:"8px", md:"16px"},
                    // border: "1px solid #EEE",
                    // padding: {xs:"16px", md:"24px"}
                }}>
                    <Box display={"flex"} bgcolor={isDarkMode ?  "#1E1F2E": "white"} flexDirection={"column"} justifyContent={"center"} paddingX={{
                        xs: "20px",
                        md: "56px",
                    }} 
                    paddingY={{
                        xs: "16px",
                        md: "16px",
                    }} 
                    borderRadius={"32px"} border={"0.876712px solid rgb(55, 58, 80)"} alignItems={"center"}>
                    <img src={logo} height={"50px"} width={"50px"} alt='ML-Quant' style={{marginBottom: "12px"}}/>
                    <Typography sx={{
                        fontSize: {xs:"16px", md:"24px"},
                        color: "text.primary.main",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: {xs:"12px", md:"12px"}
                    }}>Join Our ML-Quant Community Today!</Typography>
                  
                    <Typography sx={{
                        fontSize: {xs:"12px", md:"16px"},
                        color: "text.primary.main",
                        opacity: 0.8,
                        textAlign: "center",
                        marginBottom: {xs:"12px", md:"16px"}
                    }}>Stay ahead with exclusive ML-Quant updates! Join our community of 5000+ subscribers.</Typography>

                    <Button
                        target={"_blank"}
                        url={"https://blog.ml-quant.com/subscribe?utm_source=menu&simple=true&next=https%3A%2F%2Fblog.ml-quant.com%2Farchive"}
                        textStyles={{
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}
                        
                        text={"Subscribe Now"}
                    />
                    <Box height={"12px"}></Box>
                    <Typography sx={{
                        fontSize: {xs:"12px", md:"14px"},
                        color: "text.secondary.main",
                        marginTop: {xs:"0px", md:"12px"},
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                    }}>Already part of our community? <a style={{
                        listStyleType: "none",
                        textDecoration:"none"
                    }} href="https://blog.ml-quant.com/" target="_blank" rel="noopener noreferrer"><Typography sx={{ color: "#3367B3",  fontSize: {xs:"12px", md:"14px"}, cursor: "pointer" }}>Visit our blog</Typography></a></Typography>
                    </Box>
                </Box>

            </MainContainer>

        </Box>
    )
}

export default Newsletter