import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { GlobalState } from "../App";
import Image from "../components/Image";
import { auth, logout } from "../services/firebase/firebase.config";
import { useNavigate } from "react-router-dom";

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const logOut = async () => {
    await auth.signOut()
    window.location.reload();
  };

  const { userDetails, subscription } = React.useContext(GlobalState);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: {xs:24,md:28}, height:{xs:24,md:28} }}>
              <Image category="profile" title={userDetails?.email} cover={userDetails?.photoURL !== null ? userDetails?.photoURL : ""} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            p: "10px",
            bgcolor: "background.main",
            overflow: "visible",
            WebkitBoxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
            MozBoxShadow: " 2px 10px 27px -8px rgba(0,0,0,0.75)",
            boxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 20,
              height: 20,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.main",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {subscription && (
          <MenuItem
            sx={{ display: "flex", alignItems: "center !important" }}
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
          >
            <Avatar>
              {" "}
              <Image
              category="profile"
                title={userDetails?.email}
                cover={userDetails?.photoURL}
              />{" "}
            </Avatar>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "100%",
                alignSelf: "center",
                color: "text.primary.main",
              }}
            >
              Profile
            </Typography>
          </MenuItem>
        )}
        <Divider
          sx={{ marginTop: "4px !important", marginBottom: "4px !important" }}
        />
        <MenuItem
          sx={{ display: "flex", gap: "8px" }}
          onClick={() => {
            logOut();
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              width: "16px !important",
              height: "16px !important",
              minWidth: "0px !important",
            }}
          >
            <Logout sx={{ color: "text.secondary.main" }} fontSize="small" />
          </ListItemIcon>

          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "100%",
                alignSelf: "center",
                color: "text.primary.main",
              }}
            >
              Logout
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
