import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import { styled } from "@mui/system";
import Logo from "./assets/Icons/logo.svg";
import MainContainer from "./components/MainContainer";

const FooterContainer = styled("footer")({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "auto",
  gap: "30px",
});

const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
});

const LogoImage = styled("img")({
  width: "30px",
  height: "30px",
  marginRight: "10px",
});

const SocialIconsContainer = styled("div")({
  marginBottom: "10px",
});

const IconButtonStyled = styled(IconButton)({
  marginRight: "8px",
});

const NavigationContainer = styled("nav")({
  display: "flex",
 
  flexWrap: "wrap",
 
  width: "100%",
});

const LinksContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  marginTop: "8px",
  flexDirection: "column",
  gap: "12px",
});

const LinkStyled = styled(Link)({
  textDecoration: "none",
  marginLeft: "8px",
  marginRight: "8px",
  marginBottom: "8px",
  fontFamily: "Poppins",
  fontSize: "14px !important",
  cursor:"pointer",

});

function Footer() {
  const navigate = useNavigate()
  const theme = useTheme();
  return (
    <MainContainer bgcolor={theme.palette.primary.main}>
      <FooterContainer sx={{ bgcolor: theme.palette.primary.main }}>
        <LogoContainer>
          <Box
          onClick={()=>{
            navigate("/")
          }}
          sx={{
            cursor:"pointer",
            display:"flex",
            gap:{xs:"8px",sm:"16px"},
            alignItems:"center",
            flexDirection:"column",
          }}
          
          >
            <img width="42px" src={Logo} alt="logo" />
            <Box

              sx={{
              
                display:"block",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 12, lg: 16 },
                  color: "text.primary.main",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                ᴍʟ-ǫᴜᴀɴᴛ ғɪɴᴀɴᴄᴇ
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 11, lg: 16 },
                  color: "text.secondary.main",
                  fontWeight: 400,
                }}
              >
                Machine Learning & Quantitative Finance
              </Typography>
            </Box>
          </Box>
        </LogoContainer>

        <NavigationContainer sx={{
          flexDirection:{xs:"row", sm:"row", alignItems:{xs:"center", sm:"flex-start"}},
          gap: {xs:"40px",sm:"40px"},
          justifyContent: {xs:"space-between",sm:"space-around"},
          }}>
          <LinksContainer
          >
            <LinkStyled sx={{ color: "text.primary.main" }} to="/">
              Home
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Arxiv">
              Arxiv
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/SSRN">
              SSRN
            </LinkStyled>
          </LinksContainer>

          <LinksContainer >
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Seminar">
              Seminar
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Github">
              Github
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Blogs">
              Blogs
            </LinkStyled>
          </LinksContainer>

          <LinksContainer >
            <LinkStyled sx={{ color: "text.primary.main" }} to="/News">
              News
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/pwc">
              PWC
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Videos">
              Videos
            </LinkStyled>
          </LinksContainer>

          <LinksContainer >
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Linkedin">
              Linkedin
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/Reddit">
              Reddit
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/jobs">
              Jobs
            </LinkStyled>
          </LinksContainer>

          <LinksContainer >
            <LinkStyled sx={{ color: "text.primary.main" }} to="/repec">
              Repec
            </LinkStyled>
            <LinkStyled sx={{ color: "text.primary.main" }} to="/podcasts">
              Podcasts
            </LinkStyled>
          </LinksContainer>
        </NavigationContainer>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign:"center"
          }}
        >
          <SocialIconsContainer>
            <IconButtonStyled aria-label="Facebook">
              <Facebook style={{ color: "white" }} />
            </IconButtonStyled>
            <IconButtonStyled aria-label="Twitter">
              <Twitter style={{ color: "white" }} />
            </IconButtonStyled>
            <IconButtonStyled aria-label="Instagram">
              <Instagram style={{ color: "white" }} />
            </IconButtonStyled>
          </SocialIconsContainer>

          <Typography
            sx={{
              color: "text.primary.main",
              fontSize: "12px",
              fontWeight: "300",
            }}
          >
            &copy; {new Date().getFullYear()} ML-QUANT FINANCE. All rights
            reserved.
          </Typography>
        </Box>
      </FooterContainer>
    </MainContainer>
  );
}

export default Footer;
