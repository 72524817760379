import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import profileImage from "../../../images/profileImage.png";
import CustomIcon from "../../../assets/Icons/CustomIcon";
import MyTextField from "../../auth/component/MyTextField";
import Button from "../../../components/Button";
import { GlobalState } from "../../../App";
import Image from "../../../components/Image";
import { checkUserExist, updateUserDetails } from "../../../components/_shared/ApiRequests";
import { auth } from "../../../services/firebase/firebase.config";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { Opacity } from "@mui/icons-material";
const textStyle = {
  color: "#FFF",
  textAlign: "center",
  fontSize: "15.495px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "0.155px",
};
const ProfileTabs = () => {
  const { userDetails } = useContext(GlobalState);

  const [userName, setUserName] = useState()

  const [email, setEmail] = useState(userDetails.email)

  const [loading, setLoading] = useState()

  const navigate = useNavigate()

  const updateUserName = async () => {
    setLoading(true)
    setTimeout(async () => {
      await updateUserDetails({ username: userName }).then((res) => {
        setLoading(false)
      })
    }, 1000);
  }

  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: 'https://www.ml-quant.com/login',
    handleCodeInApp: false
  };

  useEffect(() => {
    setLoading(true)
    checkUserExist(auth).then(async (data) => {
      setUserName(data?.username)
    })
    setLoading(false)
  }, [auth.currentUser])
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "42px",
          borderRadius: '50%'
        }}
      >
        {userDetails?.photoURL && <Image
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "60px",
          }}
          title={userDetails?.email}
          cover={userDetails?.photoURL}
        />}
        {/* <Box
          position={"absolute"}
          sx={{
            left: "63%",
            top: "66%",
            width: "24px",
            height: "24px",
            borderRadius: "29px",
            background: isDarkMode ? "#373A50" : "rgb(51, 103, 179)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomIcon color={isDarkMode ? "" : "white"} />
        </Box> */}
      </Box>
      <Box>
        <MyTextField state={userName}
          setState={setUserName}
          label={"User name"}
          placeholder={"Enter your user name"}
        />

        <MyTextField id="white" state={email} setState={() => { }} label={"Email"} placeholder={"Enter your email"} />

      </Box>
      <Box sx={{ marginTop: "40px" }}>
        <Button onClick={updateUserName} isLoading={loading} button_class="login_btn" text="Save" textStyles={textStyle} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", marginTop: "18px", }}>
        <Typography sx={{ color: "white", flexWrap: "wrap" }}>Lost access to your account? <span onClick={
          async () => {
            await sendPasswordResetEmail(auth, email, actionCodeSettings);
            alert(
              "You will receive the password reset link shortly on your registered email!"
            );
          }
        } style={{ cursor: "pointer", color: "rgb(51, 103, 179)" }}>Reset</span> </Typography>

      </Box>
    </Box>
  );
};

export default ProfileTabs;
