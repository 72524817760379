import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import "./App.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./utils/theme";
import { useEffect, useRef, useState } from "react";
import { createContext } from "react";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Reset from "./pages/auth/Reset";
import ProtectedRoutes from "./ProtectedRoutes";
import Navbar from "./navbar/Navbar";
import Sidebar from "./components/Sidebar";
import HomePublic from "./pages/home/HomePublic";
import { getAllFilteresList, getDataFromDatabase } from "./components/_shared/ApiRequests";
import ImageTemplate from "./components/ImageTemplate";
import { Helmet } from "react-helmet";
import Dexie from 'dexie';
import Details from "./pages/detail/Details";
export const GlobalState = createContext("");

// Cache storage
const dataCache = {};

export async function getCachedData(key) {
  // If the data for the key exists in the cache, return it
  if (dataCache[key]) {
    return dataCache[key];
  }

  // Fetch data if not in the cache
  const data = await getDataFromDatabase("home/public");

  // Store the data in cache and return
  dataCache[key] = data;
  return data;
}

// Initialize IndexedDB database
const db = new Dexie('MyDatabase');
db.version(1).stores({
  myData: '++id,data'
});

// Function to store data in IndexedDB with a specified key
export const storeDataInIndexedDB = async (key, data) => {
  try {
    // Check if data with the specified key already exists
    const existingData = await db.myData.get(key);
    if (existingData) {
      // If data exists, delete it
      await db.myData.delete(key);
      // console.log('Previous data deleted for key:', key);
    }
    
    // Insert new data with the specified key
    await db.myData.put({ id: key, data });
    // console.log('Data stored successfully in IndexedDB with key:', key);
  } catch (error) {
    console.error('Error storing data in IndexedDB:', error);
  }
}

// Function to retrieve data from IndexedDB based on a specified key
export const getDataFromIndexedDB = async (key) => {
  try {
    const retrievedData = await db.myData.get(key);
    // console.log('Data retrieved successfully from IndexedDB:', retrievedData);
    return retrievedData;
  } catch (error) {
    console.error('Error retrieving data from IndexedDB:', error);
    return null;
  }
}

function App() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [isDarkMode, setIsDarkMode] = useState(true);
  const searchText = useRef("");
  const [subscription, setSubscription] = useState(null);
  const [userDetails, setUserDetails] = useState();

  // GLOBAL FILTERS DATA HANDLING STATES
  const [ARXIVCategories, setARXIVCategories] = useState([]);
  const [LinkedinPeople, setLinkedinPeople] = useState([]);
  const [MLTrending, setMLTrending] = useState([]);
  const [PapersWithCode, setPapersWithCode] = useState([]);
  const [SSRNPaperArticles, setSSRNPaperArticles] = useState([]);

  const getFiltersList = async () => {
    await getAllFilteresList()
      .then((res) => {
        setARXIVCategories(res?.ARXIVCategories?.map(function (element) {
          return element.toLowerCase();
        }))
        setLinkedinPeople(res?.LinkedinPeople.map(function (element) {
          return element.toLowerCase();
        }))
        setMLTrending(res?.MLTrending.map(function (element) {
          return element.toLowerCase();
        }))
        setPapersWithCode(res?.PapersWithCode.map(function (element) {
          return element.toLowerCase();
        }))
        setSSRNPaperArticles(res?.SSRNPaperArticles.map(function (element) {
          return element.toLowerCase();
        }))
      }).catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
    getFiltersList()
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = darkTheme.palette.background.main;
  }, [isDarkMode]);

  return (
    <Box sx={{
      paddingTop: md ? "60px" : "156px",
      // width: "100%",
    }}>
      <GlobalState.Provider
        value={{
          isDarkMode, setIsDarkMode,
          subscription, setSubscription,
          userDetails, setUserDetails,
          searchText,
          ARXIVCategories,
          LinkedinPeople,
          MLTrending,
          PapersWithCode,
          SSRNPaperArticles,
        }}
      >
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <BrowserRouter>
            <Helmet>
              <title>ML-Quant - Machine Learning and Quantitative Finance</title>
              <meta
                name="description"
                content="Machine Learning and Quantitative Finance"
              />
              <meta
                name="keywords"
                content="ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, Reddit, Jobs, Repec, PwC"
              />
            </Helmet>
            <Navbar />
            {md ? <Sidebar /> : <></>}
            <Routes>
              <Route path="/public" element={<HomePublic />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/ssrnimagetemp" element={<ImageTemplate />} />
              <Route path="/detail/:category/:id" element={<Details />} />
              <Route path="/*" element={<ProtectedRoutes />}></Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </GlobalState.Provider>
    </Box>
  );
}

export default App;
