import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { checkUserExist, checkUserSubscription, createUser } from "./components/_shared/ApiRequests";

import ArXiv from "./pages/arxiv/ArXiv";
import Ssrn from "./pages/ssrn/Ssrn";
import Seminar from "./pages/Seminar";
import Github from "./pages/github/Github";
import Blogs from "./pages/blogs/Blogs";
import Videos from "./pages/videos/Videos";
import Pwc from "./pages/pwc/Pwc";
import Reddit from "./pages/reddit/Reddit";
import Linkedin from "./pages/linkedin/Linkedin";
import News from "./pages/news/News";
import Details from "./pages/detail/Details";
import Pricing from "./pages/pricing/Pricing";
import Profile from "./pages/profile/Profile";
import ProfileCancelationPage from "./pages/profile/ProfileCancelationPage";
import Home from "./pages/home/Home";
import Footer from "./Footer";
import PaymentStatusScreen from "./pages/pricing/PaymentStatus";
import { GlobalState } from "./App";
import LoadingDefault from "./components/_shared/LoadingDefault";
import SearchResults from "./appSearch/SearchResults";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./services/firebase/firebase.config";
import Newsletter from "./Newsletter";
import Jobs from "./pages/jobs/Jobs";
import Repec from "./pages/repec/Repec";
import Podcasts from "./pages/podcasts/Podcasts";

const ProtectedRoutes = () => {
  //Global context states
  const { setUserDetails, subscription, setSubscription, searchText } =
    useContext(GlobalState);
    
  const navigate = useNavigate();

  const [loading2, setLoading] = useState(true);


  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      if (user === null || user === undefined) {
        navigate("/public");
      } else {
        setUserDetails(user);
        checkSubscription();
      }
    }
  }, [user, loading]);

  const checkSubscription = async () => {
    const subscriptionStatus = await checkUserSubscription(auth);
    setSubscription(subscriptionStatus?.data);
    // setSubscription(subscriptionStatus?.data);
    navigate(!subscriptionStatus?.data && "/pricing");
    
    setLoading(false);
  }


  useEffect(() => { }, [subscription]);

  if (loading2) {
    return <LoadingDefault />;
  }
  if (!loading2 && !subscription) {
    return (
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    );
  }
  if (subscription) {
    return (
        <Box sx={{minHeight:"100vh"}}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/arxiv" element={<ArXiv />} />
            <Route path="/ssrn" element={<Ssrn />} />
            {/* <Route path="/seminar" element={<Seminar />} /> */}
            <Route path="/github" element={<Github />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/pwc" element={<Pwc />} />
            <Route path="/reddit" element={<Reddit />} />
            <Route path="/linkedin" element={<Linkedin />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/repec" element={<Repec />} />
            <Route path="/podcasts" element={<Podcasts />} />
            <Route path="/news" element={<News />} />
          
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/cancelSubscription" element={<ProfileCancelationPage />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/payment/:result" element={<PaymentStatusScreen />} />
          </Routes>
          <Newsletter />
          <Footer />
        </Box>
    );
  }
};

export default ProtectedRoutes;
