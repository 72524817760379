import React from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import MultilineEllipsis from './_shared/MultilineEllipsis';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from "@mui/material";

const Tooltip = ({ data, place, id, containerStyles }) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <ReactTooltip
            delayShow={300}
            delayHide={300}
            clickable={true}
            style={{
                backgroundColor: "#171616",
                display: md ? "none" : "flex",
                borderRadius: "12px",
                zIndex: 5,
            }}
            place={"top"}
            id={`my-tooltip-${id}`}
            content={
                <Box sx={{
                    maxWidth: "200px",
                    maxHeight: "120px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    ...containerStyles
                }}>
                    {
                        data?.description ?
                            <> <MultilineEllipsis
                                style={{ fontweight: "700", color: "white", fontSize: "14px", marginBottom: "2px" }}
                                color="text.secondary.main"
                                text={data?.title}
                            />
                                <hr /> </> :
                            <Typography
                                color="text.secondary.main"
                                sx={{ fontweight: "700", color: "white", fontSize: "14px", marginBottom: "2px" }}
                            >
                                {data?.title}
                            </Typography>
                    }
                    <MultilineEllipsis
                        style={{ "-webkit-line-clamp": "15", fontweight: "700", color: "white", marginTop: "8px" }}
                        color="text.secondary.main"
                        text={data?.description}
                    />
                    <a style={{}} href={data?.url} target='_blank' rel="noreferrer">  <ButtonBase sx={{ color: "#3367B3" }}>Read more</ButtonBase></a>
                </Box>
            }
        />
    )
}

export default Tooltip