import { Box, TextField, Typography, colors, useMediaQuery, useTheme } from '@mui/material'
import logo from '../../assets/Icons/logo.svg'
import React, { useEffect, useState } from 'react'
import MyTextField from './component/MyTextField'
import Button from '../../components/Button'
import GoogleSiginButton from './component/GoogleSiginButton'
import MainContainer from '../../components/MainContainer'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth } from "../../services/firebase/firebase.config";
import { sendPasswordResetEmail } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
const textStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.155px',
}
const Reset = () => {
    const [email, setEmail] = useState("");
    // const [user, setUser] = useState()
    const [user, loading, error] = useAuthState(auth);

    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const sm = useMediaQuery(theme.breakpoints.down("365"));

    var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'https://www.ml-quant.com/login',
        handleCodeInApp: false
    };

    useEffect(() => {
        if (user) {
            setEmail(user?.email)
        }
    }, []);
    return (
        <MainContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '62px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: "10px" }}>
                        <img src={logo} alt='logo' style={{ width: '52px', height: '52px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{
                            color: 'text.primary.main',
                            fontSize: { sm: "18px", md: "22px" },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            fontFamily: 'Poppins'
                        }}>Reset Password</Box>
                        <Box sx={{
                            color: 'text.secondary.main',
                            fontSize: "14px",
                            fontStyle: 'normal',
                            fontWeight: '500',
                            textAlign: 'center',
                            maxWidth: '350px',
                            fontFamily: 'Poppins'
                        }}>To reset your password, please click on the 'Forgot Password' link below and follow the instructions sent to your registered email address.</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '9px' }}>
                        <MyTextField label="Email" placeholder="Enter your email" state={email} setState={setEmail} />
                    </Box>
                    <Box sx={{ marginTop: md ? "32px" : '40px' }}>
                        <Button onClick={async () => {
                            if (email.length > 0) {
                                await sendPasswordResetEmail(auth, email, actionCodeSettings);
                                alert(
                                    "You will receive the password reset link shortly on your registered email!"
                                );
                            } else {
                                alert("Please enter your email!")
                            }
                        }} button_class="login_btn" text="Send me magic link" textStyles={textStyle} />
                    </Box>
                    <Box sx={{ marginTop: '25px' }}>
                        <Typography sx={{
                            color: 'text.primary.main',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: '0.255px',
                        }}>Already have an account? <Link to="/login" style={{ textDecoration: 'none', color: '#3367B3' }}>Sign In</Link></Typography>
                    </Box>
                </Box>
            </Box>
        </MainContainer>
    )
}

export default Reset