import { Box, Typography } from '@mui/material'
import React from 'react'
import adobePDFIcon from '../assets/icon-adobe-pdf.svg'
import watermark from '../assets/watermark-revision.png'
import facebook from '../assets/fb.png'
import twitter from '../assets/twitter.png'
import link from '../assets/link.png'

const ImageTemplate = ({
    title,
    date,
    views,
    data
}) => {
    return (

        <Box 
        sx={{
            display: "flex", flexDirection: "column", bgcolor: "white", padding: "17px 15px 8px", gap: "24px", '&[disabled]': {
                pointerEvents: 'none',
            }, overflow: "hidden", backgroundImage: `url(${watermark})`, backgroundSize: "50px",
            aspectRatio: "368/240",
            maxHeight: "240px"
        }} borderRadius={"12px"}>
            {/* Head section */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px", justifyContent: "space-between" }}>
                <Box display={"flex"} gap={"4px"}>
                    <Box sx={{ bgcolor: "#073973", border: "2px solid #073973", padding: "2px 4px 2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={adobePDFIcon} width={"5px"} height={"5px"} alt="pdfIcon" />&nbsp;<Typography fontWeight={300} sx={{ color: "white", fontSize: "6px" }}>Download This Paper</Typography>
                    </Box>
                    <Box sx={{ border: "2px solid #073973", padding: "8px 16px 5px" }}>
                        <Typography sx={{ fontSize: "6px" }} fontWeight={300} color={"#073973"}>Download This Paper</Typography>
                    </Box>
                </Box>
                <Box display={"flex"} gap={"5px"}>
                    <img src={facebook} className='social-icon' width={"6px"} height="5px" />
                    <img src={twitter} width={"5px"} height="5px" />
                    <img src={link} width={"5px"} height="5px" />
                </Box>
            </Box>

            {/* Body section */}
            <Box sx={{ zIndex: 2, display: "flex", alignItems: "flex-start", gap: "15px", flexWrap: "wrap", flexDirection: { xs: "column", md: "row", bgcolor: "white" } }}>

                <Box sx={{ flex: 2, padding: "8px 8px", display: "flex", flexDirection: "column", border: "1px solid #d8dde6", gap: "5px", bgcolor: "white" }}>
                    <Typography sx={{ fontSize: "5px", }}>
                        {title}
                    </Typography>

                    <Typography sx={{ fontSize: "5px", color: "#666", fontWeight: "300", display: "flex", gap: "5px" }}>- Pages <span>Posted: {date}</span></Typography>
                    <Typography sx={{ fontSize: "5px", color: "#666", fontWeight: "300", display: "flex", gap: "5px" }}>{data?.author}</Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography fontSize={"5px"}>Abstract</Typography>

                        <Typography sx={{ fontSize: "5px", color: "#505050", fontWeight: "300", marginBottom: "5px" }}>{data?.description}
                        </Typography>

                        <Typography fontSize={"5px"} sx={{ marginBottom: "5  px", fontWeight: "700", color: "#505050", }}>
                            Keywords:
                            <span style={{ fontWeight: "300", color: "#505050", marginLeft: "5px" }}>
                                {"SSRN"}
                            </span>
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ flex: 1, maxWidth: "300px", display: "flex", flexDirection: "column", gap: "12px" }}>

                    <Box sx={{ border: "1px solid #d8dde6", padding: "7px 12px", display: "flex", flexDirection: "column", gap: "7px", bgcolor: "white" }}>
                        <Typography sx={{ fontSize: "5px" }}>Do you have a job opening that you would like to promote on SSRN?</Typography>

                        <Box sx={{ bgcolor: "#073973", border: "2px solid #073973", padding: "4px 8px 3px", width: "fit-content" }}>
                            <Typography fontWeight={"300"} sx={{ color: "white", fontSize: "5px" }}>Place Job Opening</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#f6f7fa", paddingX: "12px" }}>
                        <Box sx={{ padding: "8px 1px 2px", borderBottom: "1px solid #d8dde6" }}>
                            <Typography sx={{ fontSize: "5px", color: "#505050", fontWeight: "300" }}>Paper statistics</Typography>
                        </Box>
                        <Box display={"flex"}>
                            <Box sx={{ padding: "8px 1px 2px", display: "flex", flexDirection: "column", gap: "3px" }}>
                                <Typography sx={{ fontSize: "5px", color: "#505050", fontWeight: "300" }}>DOWNLOADS</Typography>
                                <Typography sx={{ fontSize: "3px", color: "#505050", fontWeight: "300" }}>{data?.totalDownloads?.toString() ?? 0}</Typography>
                            </Box>

                            <Box sx={{ padding: "8px 1px 2px", display: "flex", flexDirection: "column", gap: "3px" }}>
                                <Typography sx={{ fontSize: "5px", color: "#505050", fontWeight: "300" }}>VIEWS</Typography>
                                <Typography sx={{ fontSize: "3px", color: "#505050", fontWeight: "300" }}>{views}</Typography>
                            </Box>
                        </Box>

                        {/* <Box sx={{padding:"8px 1px 2px", display:"flex", gap:"20px"}}>
                                <Typography sx={{fontSize:"5px", color:"#505050", fontWeight:"300"}}>23</Typography>
                                <Typography fontWeight={300} sx={{fontSize:"5px", color:"#00579e", fontWeight:"300"}}>References</Typography>
                            </Box> */}
                    </Box>
                </Box>

            </Box>
        </Box>

    )
}

export default ImageTemplate