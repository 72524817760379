import React from 'react'
import Google from '../../../assets/Icons/Google.svg'
import { Link } from 'react-router-dom'
import { Typography, useTheme, useMediaQuery } from '@mui/material'
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth'
import { auth } from '../../../services/firebase/firebase.config'
const GoogleSiginButton = ({onClick}) => {
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const googleProvider = new GoogleAuthProvider();
    const signInWithGoogle = async () => {
      try {
        await signInWithPopup(auth, googleProvider);
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    };
  

    return (
        <Link onClick={signInWithGoogle}
            style={{
                textDecoration: "none"
            }}>
            <button
                style={{
                    borderRadius: "24px",
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "white",
                    backgroundColor: !localStorage.getItem("theme")  ? "#2A2D3E" : "#3367B3",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    width: md?'260px':'350px',
                    height: '52px'
                }}
            >
                <img src={Google} alt='google' style={{
                    width: '24px',
                    height: '24px',
                }} />
                <Typography sx={{
                    lineHeight: "100%",
                    color: '#FFF',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                }}>
                    Continue with Google
                </Typography>
            </button>
        </Link>
    )
}

export default GoogleSiginButton