import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import PriceDiv from "./PriceDiv";
import { GlobalState } from "../../../App";
import { BACKEND_URL, BACKEND_URL_LOCALHOST } from "../../../utils/extension";
import { auth, logout } from "../../../services/firebase/firebase.config";
import axios from "axios";
import { URL_TO_USE, cancelUserSubscription } from "../../../components/_shared/ApiRequests";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const buttonstyle = {
  padding: "17px 63px",
  borderRadius: "54.687px",
  border: "1px solid #3367B3",
  background: "#3367B3",
  color: "#FFF",
  fontSize: "15.495px",
  fontStyle: "normal",
  fontWeight: "700",
  cursor: "pointer",
};

const Cards = ({ subscribed, price, priceId, text }) => {
  const { isDarkMode } = useContext(GlobalState);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  //Global context states
  const { userDetails } = useContext(GlobalState);

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "32px",
        border: "2px solid #3367B3",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!subscribed && (
        <Box
          sx={{
            position: "absolute",
            borderRadius: "0px 24px 0px 24px",
            background: "#1967FF",
            left: md ? "65.6%" : "64.279%",
            right: "0px",
            padding: "10px 10px 10px 20px",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontSize: md ? "10px" : "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.58px",
            }}
          >
            7 Days free trial
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          padding: "42px 25px 24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: isDarkMode ? "#FFF" : "black",
              fontFamily: "Poppins",
              fontSize: md ? "16px" : "22px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.989px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            ML & Quant Group
          </Typography>
          <Box
            sx={{
              width: md ? "226px" : "350.001px",
              borderBottom: isDarkMode
                ? "5px solid rgba(255, 255, 255, 0.08)"
                : "5px solid black",
              marginTop: "10px",
            }}
          ></Box>
          <Typography
            sx={{
              color: isDarkMode
                ? "rgba(255, 255, 255, 0.50)"
                : "rgba(0, 0, 0, 0.5)",
              textAlign: "center",
              fontSize: md ? "10px" : "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginTop: "16px",
            }}
          >
            Newsletter, Website, and Slack Access
          </Typography>
          <Box sx={{ marginTop: "14px" }}>
            <PriceDiv amount={price} per={text} />
          </Box>
        </Box>
        {subscribed ? (
          <Box marginTop={"22px"}>
            {
              <button
                onClick={() => {
                  navigate("/cancelSubscription")
                }}
                style={{
                  ...buttonstyle,
                  backgroundColor: "#FF562E",
                  border: "none",
                }}
                className="subscribe_btn"
                type="submit"
              >
                Cancel subscription
              </button>
            }
          </Box>
        ) : (
          <Box onClick={() => !userDetails && navigate("/login")} marginTop={"22px"}>
            {
              <form
                action={userDetails && `${URL_TO_USE}subscription/create-checkout-session/${auth?.currentUser?.uid}/${priceId}`}
                method="POST"
              >
                <button
                  style={buttonstyle}
                  className="subscribe_btn"
                  type="submit"
                >
                  Subscribe
                </button>
              </form>
            }
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Cards;
