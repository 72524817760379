import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GithubHomeCard from "./homeCards/GithubHomeCard.jsx";
import BlogsHomeCard from "./homeCards/BlogsHomeCard.jsx";
import PwCHomeCard from "./homeCards/PwCHomeCard.jsx";
import NewsHomeCard from "./homeCards/NewsHomeCard.jsx";
import LinkedinHomeCard from "./homeCards/LinkedinHomeCard.jsx";
import VideoHomeCard from "./homeCards/VideoHomeCard.jsx";
import { Link } from "react-router-dom";
import { GlobalState } from "../../../App.js";
import Loading from "../../../components/_shared/lottie/Loading.jsx";
import ArxivCard from "./homeCards/ArxivCard.jsx";
import SSRNCard from "./homeCards/SSRNCard.jsx";
import RedditCard from "./homeCards/RedditCard.jsx";
import JobsCard from "./homeCards/JobsCard.jsx";
import RepecCard from "./homeCards/RepecCard.jsx";
import PodcastsCard from "./homeCards/PodcastsCard.jsx";

const CategoryLayout = ({ title, children, data, category }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ borderRadius: "20px", width:"fit-content" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);

  const [filteredList, setFilteredList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { isDarkMode, LinkedinPeople, SSRNPaperArticles, ARXIVCategories } = useContext(GlobalState);

  const filterLinkedInDataList = (dataList) => {

    let dataListLatest = dataList?.latest?.filter((item) => {
      const name = item?.properties?.Name?.title[0]?.text?.content;
      const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
      return !containsForbiddenName;
    })

    let dataListMonthly = dataList?.monthly?.filter((item) => {
      const name = item?.properties?.Name?.title[0]?.text?.content;
      const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
      return !containsForbiddenName;
    })

    let dataListWeekly = dataList?.weekly?.filter((item) => {
      const name = item?.properties?.Name?.title[0]?.text?.content;
      const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
      return !containsForbiddenName;
    })

    let dataListAll = dataList?.all?.filter((item) => {
      const name = item?.properties?.Name?.title[0]?.text?.content;
      const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
      return !containsForbiddenName;
    })

    setFilteredList({
      latest: dataListLatest,
      monthly: dataListMonthly,
      weekly: dataListWeekly,
      all: dataListAll
    })
  }

  const filteredSSRNDataList = (dataList) => {
    let dataListLatest = dataList?.latest?.filter((item) => {
      const title = item?.properties?.Title?.title[0]?.text?.content;
      const containsForbiddenWord = SSRNPaperArticles.some(word => title.includes(word));
      return !containsForbiddenWord;
    })

    let dataListWeekly = dataList?.weekly?.filter((item) => {
      const title = item?.properties?.Title?.title[0]?.text?.content;
      const containsForbiddenWord = SSRNPaperArticles.some(word => title.includes(word));
      return !containsForbiddenWord;
    })

    let dataListMonthly = dataList?.monthly?.filter((item) => {
      const title = item?.properties?.Title?.title[0]?.text?.content;
      const containsForbiddenWord = SSRNPaperArticles.some(word => title.includes(word));
      return !containsForbiddenWord;
    })

    let dataListAll = dataList?.all?.filter((item) => {
      const title = item?.properties?.Title?.title[0]?.text?.content;
      const containsForbiddenWord = SSRNPaperArticles.some(word => title.includes(word));
      return !containsForbiddenWord;
    })

    setFilteredList({
      latest: dataListLatest,
      monthly: dataListMonthly,
      weekly: dataListWeekly,
      all: dataListAll
    })

  }

  const filterARXIVDataList = (dataList) => {
    let dataListLatest = dataList?.latest?.filter((item) => {
      const category = item?.properties?.Category?.rich_text[0]?.text?.content;
      return !ARXIVCategories?.includes(category?.toString()?.toLowerCase())
    })

    let dataListWeekly = dataList?.weekly?.filter((item) => {
      const category = item?.properties?.Category?.rich_text[0]?.text?.content;
      return !ARXIVCategories?.includes(category?.toString()?.toLowerCase())
    })

    let dataListMonthly = dataList?.monthly?.filter((item) => {
      const category = item?.properties?.Category?.rich_text[0]?.text?.content;
      return !ARXIVCategories?.includes(category?.toString()?.toLowerCase())
    })

    let dataListAll = dataList?.all?.filter((item) => {
      const category = item?.properties?.Category?.rich_text[0]?.text?.content;
      return !ARXIVCategories?.includes(category?.toString()?.toLowerCase())
    })

    setFilteredList({
      latest: dataListLatest,
      monthly: dataListMonthly,
      weekly: dataListWeekly,
      all: dataListAll
    })
  }

  useEffect(() => {
  }, [filteredList])

  useEffect(() => {

    if (data) {
      title === "ArXiv" ? filterARXIVDataList(data)
        : title === "SSRN PAPERS" ? filteredSSRNDataList(data)
          : title === "Linkedin" ? filterLinkedInDataList(data) : setFilteredList(data)

      setLoading(false);
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        bgcolor: "surface.main",
        // maxWidth: { xs: "327px", sm: "350px", md: "420px" },
        maxWidth: { xs: "350px", sm: "350px", md: "420px" },
        width: "100%",
        borderRadius: "20px",
        marginBottom: { xs: "24px", lg: "35px" },
        gap: "18px",
      }}
    >
      {/* Title section */}
      <Box
        sx={{
          width: "100%",
          paddingTop: "24px",
          paddingBottom: "21px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography>{title}</Typography>
      </Box>

      {/* Tabs section */}
      <Box sx={{ width: "100%", borderRadius: "20px !important" }}>
        <Box
          sx={{
            paddingX: "15px",
            borderRadius: "14px",
            width: "100%",
            minWidth: "auto",
            maxHeight: { xs: "45px", md: "50px" },
          }}
        >
          <Tabs
            indicatorColor="none"
            sx={{
              bgcolor: "primary.main",
              maxHeight: { xs: "45px", md: "50px" },
              minHeight: "auto",
              // '.MuiTabs-indicator': {
              //   height: '0px',
              // }
            }}
            style={{
              padding: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: " 0.876712px solid #373A50",
              borderRadius: " 14.0274px",
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab
              sx={{
                fontSize: { xs: "11px !important", md: "12px !important" },
                minWidth: "auto",
                padding: {
                  xs: "12px 20px 12px 20px",
                  md: "13px 43px 13px 43px",
                },
              }}
              style={{
                minHeight: "auto",
                color: value === 0 ? "white" : "#7D7A89",
                flex: "1",
                textAlign: "center",
                borderRadius: "11px",
                backgroundColor:
                  value === 0
                    ? isDarkMode
                      ? "#373A50"
                      : "#3367B3"
                    : "transparent",
                textTransform: "capitalize",
                letterSpacing: "0.9px",
                lineHeight: "110%",
                fontWeight: value === 0 ? "600" : "600",
              }}
              label="Latest"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                fontSize: { xs: "11px !important", md: "12px !important" },
                minWidth: "auto",
                padding: {
                  xs: "12px 20px 12px 20px",
                  md: "13px 43px 13px 43px",
                },
              }}
              style={{
                minHeight: "auto",
                color: value === 1 ? "white" : "#7D7A89",
                flex: "1",
                textAlign: "center",
                borderRadius: "11px",
                backgroundColor:
                  value === 1
                    ? isDarkMode
                      ? "#373A50"
                      : "#3367B3"
                    : "transparent",
                textTransform: "capitalize",
                letterSpacing: "0.9px",
                lineHeight: "110%",
                fontWeight: value === 1 ? "600" : "600",
              }}
              label="Weekly"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                fontSize: { xs: "11px !important", md: "12px !important" },
                minWidth: "auto",
                padding: {
                  xs: "12px 20px 12px 20px",
                  md: "13px 43px 13px 43px",
                },
              }}
              style={{
                minHeight: "auto",
                color: value === 2 ? "white" : "#7D7A89",
                flex: "1",
                textAlign: "center",
                borderRadius: "11px",
                backgroundColor:
                  value === 2
                    ? isDarkMode
                      ? "#373A50"
                      : "#3367B3"
                    : "transparent",
                textTransform: "capitalize",
                letterSpacing: "0.9px",
                lineHeight: "110%",
                fontWeight: value === 2 ? "600" : "600",
              }}
              label="Monthly"
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                fontSize: { xs: "11px !important", md: "12px !important" },
                minWidth: "auto",
                padding: {
                  xs: "12px 20px 12px 20px",
                  md: "13px 43px 13px 43px",
                },
              }}
              style={{
                minHeight: "auto",
                color: value === 3 ? "white" : "#7D7A89",
                flex: "1",
                textAlign: "center",
                borderRadius: "11px",
                backgroundColor:
                  value === 3
                    ? isDarkMode
                      ? "#373A50"
                      : "#3367B3"
                    : "transparent",
                textTransform: "capitalize",
                letterSpacing: "0.9px",
                lineHeight: "110%",
                fontWeight: value === 3 ? "600" : "600",
              }}
              label="All"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <>
            {loading ? (
              <Loading />
            ) : (
              <Box
                sx={{
                  // maxWidth: { xs: "100%", sm: "350px", md: "420px" },
                  // overflowX:"hidden",
                  width: "100%",
                  paddingTop: "18px",
                  display: "flex",
                  flexWrap: "wrap",
                  bgcolor: "surface.main",
                  borderRadius: "20px",
                }}
              >
                {filteredList?.latest
                  ?.filter((value, index, self) => {
                    const firstIndex = self.findIndex(
                      (other) => (
                        other?.properties?.Title ? (other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text)
                          :
                          (other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text))
                    );
                    // If the current item is the first occurrence, keep it.
                    return index === firstIndex;
                  })
                  ?.sort((a, b) => {
                    if (title === "SSRN PAPERS") {
                      return (b?.properties['Abstract Views']?.number || 0) - (a?.properties['Abstract Views']?.number || 0)
                    }
                  })
                  .map((element, index) => {
                    return index < 5 ? (
                      title === "Github" ? (
                        <GithubHomeCard key={element} category="github" data={element} />
                      ) : title === "Blogs" ? (
                        <BlogsHomeCard key={element} category="blogs" data={element} />
                      ) : title === "PwC" ? (
                        <PwCHomeCard key={element} category="pwc" data={element} />
                      ) : title === "News" ? (
                        <NewsHomeCard key={element} category="news" data={element} />
                      ) : title === "Linkedin" ? (
                        <LinkedinHomeCard key={element} category="linkedin" data={element} />
                      ) : title === "Videos" ? (
                        <VideoHomeCard key={element} category="videos" data={element} />
                      ) : title === "ArXiv" ? (
                        <ArxivCard key={element} category="arxiv" data={element} />
                      ) : title === "SSRN PAPERS" ? (
                        <SSRNCard key={element} category="ssrn" data={element} />
                      ) : title === "Reddit" ? (
                        <RedditCard key={element} category="reddit" data={element} />
                      ) : title === "Jobs" ? (
                        <JobsCard key={element} category="jobs" data={element} />
                      ) : title === "Repec" ? (
                        <RepecCard key={element} category="repec" data={element} />
                      ) : title === "Podcasts" ? (
                        <PodcastsCard key={element} category="podcasts" data={element} />
                      ) : <></>
                    ) : (
                      <></>
                    );
                  })}

                {filteredList?.latest?.length > 5 ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    to={`/${title === "SSRN PAPERS" ? "ssrn" : title}`}
                  >
                    <Box
                      sx={{
                        paddingY: "15px",
                        bgcolor: "viewMoreBtn.background.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "14px",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "372px" },
                        marginTop: "40px",
                        marginBottom: "16px",
                        border: isDarkMode
                          ? "0.876712px solid #373A50"
                          : "none",
                      }}
                    >
                      <Typography
                        color="viewMoreBtn.text.main"
                        fontWeight={600}
                        fontSize="12px"
                      >
                        VIEW MORE
                      </Typography>
                    </Box>
                  </Link>
                ) : (
                  <></>
                )}
                {children}
              </Box>
            )}
          </>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <>
            {loading ? (
              <Loading />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "18px",
                  display: "flex",
                  flexWrap: "wrap",
                  bgcolor: "surface.main",
                  borderRadius: "20px",
                }}
              >
                {filteredList?.weekly
                  ?.filter((value, index, self) => {
                    const firstIndex = self.findIndex(
                      (other) => (
                        other?.properties?.Title ? (other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text)
                          :
                          (other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text))
                    );
                    // If the current item is the first occurrence, keep it.
                    return index === firstIndex;
                  })
                  ?.sort((a, b) => {
                    if (title === "SSRN PAPERS") {
                      return (b?.properties['Abstract Views']?.number || 0) - (a?.properties['Abstract Views']?.number || 0)
                    }
                  })
                  .map((element, index) => {
                    return index < 5 ? (
                      title === "Github" ? (
                        <GithubHomeCard category="github" data={element} />
                      ) : title === "Blogs" ? (
                        <BlogsHomeCard category="blogs" data={element} />
                      ) : title === "PwC" ? (
                        <PwCHomeCard category="pwc" data={element} />
                      ) : title === "News" ? (
                        <NewsHomeCard category="news" data={element} />
                      ) : title === "Linkedin" ? (
                        <LinkedinHomeCard category="linkedin" data={element} />
                      ) : title === "Videos" ? (
                        <VideoHomeCard category="videos" data={element} />
                      ) : title === "ArXiv" ? (
                        <ArxivCard category="arxiv" data={element} />
                      ) : title === "SSRN PAPERS" ? (
                        <SSRNCard category="ssrn" data={element} />
                      ) : title === "Reddit" ? (
                        <RedditCard category="reddit" data={element} />
                      ) : title === "Jobs" ? (
                        <JobsCard key={element} category="jobs" data={element} />
                      ) : title === "Repec" ? (
                        <RepecCard key={element} category="repec" data={element} />
                      ) : title === "Podcasts" ? (
                        <PodcastsCard key={element} category="podcasts" data={element} />
                      ) : <></>
                    ) : (
                      <></>
                    );
                  })}
                {filteredList?.weekly?.length > 5 ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    to={`/${title === "SSRN PAPERS" ? "ssrn" : title}`}
                  >
                    <Box
                      sx={{
                        paddingY: "15px",
                        bgcolor: "viewMoreBtn.background.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "14px",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "372px" },
                        marginTop: "40px",
                        marginBottom: "16px",
                        border: isDarkMode
                          ? "0.876712px solid #373A50"
                          : "none",
                      }}
                    >
                      <Typography
                        color="viewMoreBtn.text.main"
                        fontWeight={600}
                        fontSize="12px"
                      >
                        VIEW MORE
                      </Typography>
                    </Box>
                  </Link>
                ) : (
                  <></>
                )}
                {children}
              </Box>
            )}
          </>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* cards section */}
          <>
            {loading ? (
              <Loading />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "18px",
                  display: "flex",
                  flexWrap: "wrap",
                  bgcolor: "surface.main",
                  borderRadius: "20px",
                }}
              >
                {filteredList?.monthly
                  ?.filter((value, index, self) => {
                    const firstIndex = self.findIndex(
                      (other) => (
                        other?.properties?.Title ? (other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text)
                          :
                          (other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text))
                    );
                    // If the current item is the first occurrence, keep it.
                    return index === firstIndex;
                  })
                  ?.sort((a, b) => {
                    if (title === "SSRN PAPERS") {
                      return (b?.properties['Abstract Views']?.number || 0) - (a?.properties['Abstract Views']?.number || 0)
                    }
                  })
                  .map((element, index) => {

                    return index < 5 ? (
                      title === "Github" ? (
                        <GithubHomeCard category="github" data={element} />
                      ) : title === "Blogs" ? (
                        <BlogsHomeCard category="blogs" data={element} />
                      ) : title === "PwC" ? (
                        <PwCHomeCard category="pwc" data={element} />
                      ) : title === "News" ? (
                        <NewsHomeCard category="news" data={element} />
                      ) : title === "Linkedin" ? (
                        <LinkedinHomeCard category="linkedin" data={element} />
                      ) : title === "Videos" ? (
                        <VideoHomeCard category="videos" data={element} />
                      ) : title === "ArXiv" ? (
                        <ArxivCard category="arxiv" data={element} />
                      ) : title === "SSRN PAPERS" ? (
                        <SSRNCard category="ssrn" data={element} />
                      ) : title === "Reddit" ? (
                        <RedditCard category="reddit" data={element} />
                      ) : title === "Jobs" ? (
                        <JobsCard key={data} category="jobs" data={element} />
                      ) : title === "Repec" ? (
                        <RepecCard key={data} category="repec" data={element} />
                      ) : title === "Podcasts" ? (
                        <PodcastsCard key={data} category="podcasts" data={element} />
                      ) : <></>
                    ) : (
                      <></>
                    );
                  })}
                {filteredList?.monthly?.length > 5 ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    to={`/${title === "SSRN PAPERS" ? "ssrn" : title}`}
                  >
                    <Box
                      sx={{
                        paddingY: "15px",
                        bgcolor: "viewMoreBtn.background.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "14px",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "372px" },
                        marginTop: "40px",
                        marginBottom: "16px",
                        border: isDarkMode
                          ? "0.876712px solid #373A50"
                          : "none",
                      }}
                    >
                      <Typography
                        color="viewMoreBtn.text.main"
                        fontWeight={600}
                        fontSize="12px"
                      >
                        VIEW MORE
                      </Typography>
                    </Box>
                  </Link>
                ) : (
                  <></>
                )}
                {children}
              </Box>
            )}
          </>
          {/* cards section */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* cards section */}
          <>
            {loading ? (
              <Loading />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "18px",
                  display: "flex",
                  flexWrap: "wrap",
                  bgcolor: "surface.main",
                  borderRadius: "20px",
                }}
              >
                {/* {
                  // filteredList?.all
                 (  title?.toString() === "Blogs" || title?.toString() === "PwC" || filteredList?.all === undefined ) ? [...filteredList.latest, ...filteredList.monthly, ...filteredList.weekly] : filteredList?.all
                    ?.filter((value, index, self) => {
                      const firstIndex = self.findIndex(
                        (other) => (
                          other?.properties?.Title ? (other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text)
                            :
                            (other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text))
                      );
                      // If the current item is the first occurrence, keep it.
                      return index === firstIndex;
                    })
                    ?.sort((a, b) => {
                      if (title === "SSRN PAPERS") {
                        return (b?.properties['Abstract Views']?.number || 0) - (a?.properties['Abstract Views']?.number || 0)
                      }
                    })
                    .map((element, index) => {
                      return index < 5 ? (
                        title === "Github" ? (
                          <GithubHomeCard category="github" data={element} />
                        ) : title === "Blogs" ? (
                          <BlogsHomeCard category="blogs" data={element} />
                        ) : title === "PwC" ? (
                          <PwCHomeCard category="pwc" data={element} />
                        ) : title === "News" ? (
                          <NewsHomeCard category="news" data={element} />
                        ) : title === "Linkedin" ? (
                          <LinkedinHomeCard category="linkedin" data={element} />
                        ) : title === "Videos" ? (
                          <VideoHomeCard category="videos" data={element} />
                        ) : title === "ArXiv" ? (
                          <ArxivCard category="arxiv" data={element} />
                        ) : title === "SSRN PAPERS" ? (
                          <SSRNCard category="ssrn" data={element} />
                        ) : title === "Reddit" ? (
                          <RedditCard category="reddit" data={element} />
                        ) : title === "Jobs" ? (
                          <JobsCard key={data} category="jobs" data={element} />
                        ) : title === "Repec" ? (
                          <RepecCard key={data} category="repec" data={element} />
                        ) : title === "Podcasts" ? (
                          <PodcastsCard key={data} category="podcasts" data={element} />
                        ) : <></>
                      ) : (
                        <></>
                      );
                    })
                    } */}

                {
                  // Filter and map the elements based on the conditions
                  (filteredList?.all === undefined) ?
                    [...filteredList.latest, ...filteredList.monthly, ...filteredList.weekly]
                      .filter((value, index, self) => {
                        const firstIndex = self.findIndex(other => (
                          other?.properties?.Title ?
                            (other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text)
                            : (other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text)
                        ));
                        return index === firstIndex;
                      })
                      .sort((a, b) => {
                        if (title === "SSRN PAPERS") {
                          return (b?.properties['Abstract Views']?.number || 0) - (a?.properties['Abstract Views']?.number || 0);
                        }
                        return 0; // Ensure a default return value
                      })
                      .map((element, index) => (
                        index < 5 ? (
                          // Render the appropriate card based on the title
                          title === "Github" ? <GithubHomeCard category="github" data={element} />
                            : title === "Blogs" ? <BlogsHomeCard category="blogs" data={element} />
                              : title === "PwC" ? <PwCHomeCard category="pwc" data={element} />
                                : title === "News" ? <NewsHomeCard category="news" data={element} />
                                  : title === "Linkedin" ? <LinkedinHomeCard category="linkedin" data={element} />
                                    : title === "Videos" ? <VideoHomeCard category="videos" data={element} />
                                      : title === "ArXiv" ? <ArxivCard category="arxiv" data={element} />
                                        : title === "SSRN PAPERS" ? <SSRNCard category="ssrn" data={element} />
                                          : title === "Reddit" ? <RedditCard category="reddit" data={element} />
                                            : title === "Jobs" ? <JobsCard key={data} category="jobs" data={element} />
                                              : title === "Repec" ? <RepecCard key={data} category="repec" data={element} />
                                                : title === "Podcasts" ? <PodcastsCard key={data} category="podcasts" data={element} />
                                                  : null
                        ) : null 
                      ))
                    :
                    // If filteredList.all is defined and not empty, render its contents
                    (filteredList?.all?.length > 0 && filteredList.all.map(element => (
                      title === "Github" ? <GithubHomeCard category="github" data={element} />
                        : title === "Blogs" ? <BlogsHomeCard category="blogs" data={element} />
                          : title === "PwC" ? <PwCHomeCard category="pwc" data={element} />
                            : title === "News" ? <NewsHomeCard category="news" data={element} />
                              : title === "Linkedin" ? <LinkedinHomeCard category="linkedin" data={element} />
                                : title === "Videos" ? <VideoHomeCard category="videos" data={element} />
                                  : title === "ArXiv" ? <ArxivCard category="arxiv" data={element} />
                                    : title === "SSRN PAPERS" ? <SSRNCard category="ssrn" data={element} />
                                      : title === "Reddit" ? <RedditCard category="reddit" data={element} />
                                        : title === "Jobs" ? <JobsCard key={data} category="jobs" data={element} />
                                          : title === "Repec" ? <RepecCard key={data} category="repec" data={element} />
                                            : title === "Podcasts" ? <PodcastsCard key={data} category="podcasts" data={element} />
                                              : null
                    )))
                }



                {filteredList?.all?.length > 5 ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    to={`/${title === "SSRN PAPERS" ? "ssrn" : title}`}
                  >
                    <Box
                      sx={{
                        paddingY: "15px",
                        bgcolor: "viewMoreBtn.background.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "14px",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "372px" },
                        marginTop: "40px",
                        marginBottom: "16px",
                        border: isDarkMode
                          ? "0.876712px solid #373A50"
                          : "none",
                      }}
                    >
                      <Typography
                        color="viewMoreBtn.text.main"
                        fontWeight={600}
                        fontSize="12px"
                      >
                        VIEW MORE
                      </Typography>
                    </Box>
                  </Link>
                ) : (
                  <></>
                )}
                {children}
              </Box>
            )}
          </>
          {/* cards section */}
        </TabPanel>
      </Box>
      {/* End Tabs section */}
    </Box>
  );
};

export default CategoryLayout;
