import React from 'react';
import Masonry from 'react-masonry-css';


const MasonryLayout = ({ children }) => {

    const breakpointObj = {
        default: 3,
        3000: 3,
        2000: 3,
        1370: 2,
        1000: 2,
        670: 1
    }
    return (
        <Masonry className='masonryLayout' columnClassName='masonryColumn' style={{ display: "flex", gap: "35px", justifyContent: "center", }} breakpointCols={breakpointObj}>

            {children}

        </Masonry>
    )
}

export default MasonryLayout
