import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import MainContainer from '../components/MainContainer'
import { GlobalState } from '../App';

const Message = () => {
    const { isDarkMode } = useContext(GlobalState);
    return (
        <MainContainer bgcolor={isDarkMode ?"rgb(8 14 17)" : "#1DA1F2"}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                paddingY:"16px",
                justifyContent:"center",
                paddingX:{xs:"0px",sm:"24px"},
                marginTop:{xs:"16px", md:"0px"}
            }}>
                <Typography variant='body1' sx={{display:"flex", alignItems:"center", color:"white", gap:"6px", flexWrap:"wrap", }}>Are you looking for the newsletter?
                    <a style={{textDecoration:"none"}} href='http://blog.ml-quant.com/' target='_blank' rel="noreferrer"><span style={{cursor:"pointer", color:isDarkMode ? "#0147ac" : "black", fontSize:"15px"}}>click here</span></a>
                </Typography>
            </Box>
        </MainContainer>
    )
}

export default Message