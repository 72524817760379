import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import PrimaryCard from "./components/PrimaryCard";
import GithubLogo from "../../assets/github.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  getDataFromDatabase,
} from "../../components/_shared/ApiRequests";
import Loading from "../../components/_shared/lottie/Loading";
import { useContext } from "react";
import { GlobalState, getDataFromIndexedDB, storeDataInIndexedDB } from "../../App";
import Dropdown from "../../components/Dropdown";
import { Helmet } from "react-helmet";
import { DB_KEY } from "../../utils/extension";

const Github = () => {

  // data sort by handling state
  const [sortBy, setSortBy] = useState("Score")

  const [value, setValue] = React.useState(0);
  const { isDarkMode } = useContext(GlobalState);

  const [githubData, setGithubData] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [activePageNumber, setActivePageNumber] = useState(1);

  // view more handling states
  const [activeLatestViewMore, setActiveLatestViewMore] = useState(true);
  const [activeWeeklyViewMore, setActiveWeeklyViewMore] = useState(true);
  const [activeMonthlyViewMore, setActiveMonthlyViewMore] = useState(true);
  const [activeAllViewMore, setActiveAllViewMore] = useState(true);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ display: "flex" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activePageNumber === 1) {
      getCachedData().then((data => {
        const rData = data;
        
        const retrievedData = rData.data
        if (retrievedData) {
          setDataAndStopLoading(retrievedData)
        }
      }))
    }
    getDataFromDatabase("github", activePageNumber).then(async (listData) => {
      await storeDataInIndexedDB(DB_KEY.GITHUB, listData)
      const retrievedData = await getDataFromIndexedDB(DB_KEY.GITHUB);
      const list = retrievedData.data
      if (list) {
        // setArxivData(list);
        if (list?.latest?.length === 0) {
          setActiveLatestViewMore(false);
        }
        if (list?.weekly?.length === 0) {
          setActiveWeeklyViewMore(false);
        }
        if (list?.monthly?.length === 0) {
          setActiveMonthlyViewMore(false);
        }
        if(list?.all?.length === 0) {
          setActiveAllViewMore(false);
        }

        setDataAndStopLoading(list)
      }
    });
  }, [activePageNumber]);

  const getCachedData = async () => {
    const data = await getDataFromIndexedDB(DB_KEY.GITHUB);
    return data;
  }

  const setDataAndStopLoading = (data) => {
    setDataList(data);
    setShowLoading(false);
    setPaginationLoading(false);
  }

  useEffect(() => {
    if (dataList.length !== 0) {
      setGithubData((prevList) => {
        if (prevList?.length !== 0) {
          // Spread the previous list and append the new data
          return {
            latest: [...prevList?.latest, ...dataList?.latest],
            monthly: [...prevList?.monthly, ...dataList?.monthly],
            weekly: [...prevList?.weekly, ...dataList?.weekly],
            all: [...prevList?.all, ...dataList?.all],
          };
        } else {
          // If prevList is undefined or empty, set dataList directly
          return dataList;
        }
      });
    }
  }, [dataList]);

  useEffect(() => {

  },[sortBy])

  return (
    <Box
      sx={{
        bgcolor: "background.main",
        width: "100%",
        color: "text.primary.main",
        paddingTop: "56px",
      }}
    >

      <Helmet>
        <title>ML-Quant GitHub: Machine Learning & Quantitative Finance</title>
        <meta
          name="description"
          content="Explore ML-Quant's GitHub Data List page, your gateway to a wealth of open-source resources. Discover curated repositories, tools, and projects at the intersection of Machine Learning and Quantitative Finance. Enhance your coding prowess with valuable insights and collaborative projects. Accessible, powerful, and community-driven, ML-Quant's GitHub Data List is your key to navigating the convergence of technology and finance"
        />
        <meta
          name="keywords"
          content="Github, Machine Learning, Quantitative Finance, Algorithmic Trading, Predictive Modeling, Financial Analytics, Data-driven Insights, Finance Technology, ML-Quant Blogs, Technology in Finance, Financial Trends, Market Analysis, Investment Strategies, Data Science, Artificial Intelligence, Finance Blogosphere"
        />
      </Helmet>
      <MainContainer>
        <Box
          sx={{
            width: "100%",
            color: "text.primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "40px", md: "56px" },
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "150px", sm: "250px", lg: "444px" },
              maxHeight: "200px",
              aspectRatio: "444/200",
              width: "100%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                aspectRatio: "444/200",
                objectFit: "contain",
              }}
              alt="ArXiv logo"
              src={GithubLogo}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >
            {/* Tabs section */}
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "42px",
              }}
            >

              <Box sx={{
                display: "flex", width: "100%",
                justifyContent: "space-between", flexWrap: "wrap", flexDirection: { xs: "column", md: "row" },
                alignItems: "center", gap: { xs: "40px", md: "0px" },
                // justifyContent: "center", position:"relative"
              }}>
                <Box sx={{ minWidth: "168px", display: { xs: "none", md: "flex" }, width: { xs: "0px", md: "auto" }, }}></Box>
                <Box
                  sx={{
                    // marginX: "15px",
                    bgcolor: "primary.main",
                    borderRadius: "14px",
                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    maxHeight: { xs: "45px", md: "50px" },
                    display: "flex",
                  }}
                >
                  <Tabs
                    indicatorColor="none"
                    sx={{
                      maxHeight: { xs: "45px", md: "50px" },
                      minHeight: "auto",
                    }}
                    style={{
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 0.876712px solid #373A50",
                      borderRadius: " 14.0274px",
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 0 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 0
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 0 ? "600" : "600",
                      }}
                      label="Latest"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 1 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 1
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 1 ? "600" : "600",
                      }}
                      label="Weekly"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 2 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 2
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 2 ? "600" : "600",
                      }}
                      label="Monthly"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        fontSize: { xs: "11px !important", md: "12px !important" },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 3 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 3
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 3 ? "600" : "600",
                      }}
                      label="All"
                      {...a11yProps(3)}
                    />
                  </Tabs>

                </Box>

                <Box
                  sx={{ width: { xs: "100%", md: "fit-content" }, display: "flex", justifyContent: "flex-start" }}
                >
                  <Dropdown
                    options={["All", "Contributors", "Forks", "Last Updated", "Stars", "Score", "Views"]}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  />
                </Box>
              </Box>

              {value === 0 ? (
                <TabPanel value={value} index={0}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {githubData?.latest
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase()?.toString() === "last updated" ? new Date(b?.properties["Last Update"]?.date?.start)?.getTime() -
                              new Date(a?.properties["Last Update"]?.date?.start)?.getTime() :
                              sortBy.toLowerCase()?.toString() === "stars" ? b?.properties?.Stars?.number - a?.properties?.Stars?.number :
                                sortBy.toLowerCase()?.toString() === "forks" ? b?.properties?.Forks?.number - a?.properties?.Forks?.number :
                                  sortBy.toLowerCase()?.toString() === "score" ? b?.properties?.Score?.number - a?.properties?.Score?.number :
                                    sortBy.toLowerCase()?.toString() === "contributors" && parseInt(b?.properties?.Contributors?.rich_text[0]?.plain_text) -
                                    parseInt(a?.properties?.Contributors?.rich_text[0]?.plain_text)
                          )
                          ?.map((data, index) => {
                            var stars = data?.properties?.Stars?.number;
                            var score = data?.properties?.Score?.number;
                            const {
                              id,
                              cover,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Last Update": {
                                  date: { start },
                                },
                                Forks: { number },
                                URL: { url }
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text?.text.content,
                                date: start,
                                stars: stars,
                                forks: number,
                                url: url,
                                score: score
                              },
                            ];
                            return (
                              <PrimaryCard
                                category="Github"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeLatestViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          opacity: activeLatestViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {githubData?.weekly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name.title[0].plain_text === value.properties?.Name.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase()?.toString() === "last updated" ? new Date(b.properties["Last Update"].date.start).getTime() -
                              new Date(a.properties["Last Update"]?.date?.start).getTime() :
                              sortBy.toLowerCase()?.toString() === "stars" ? b?.properties?.Stars?.number - a?.properties?.Stars?.number :
                                sortBy.toLowerCase()?.toString() === "forks" ? b?.properties?.Forks?.number - a?.properties?.Forks?.number :
                                  sortBy.toLowerCase()?.toString() === "score" ? b?.properties?.Score?.number - a?.properties?.Score?.number :
                                    sortBy.toLowerCase()?.toString() === "contributors" && parseInt(b?.properties?.Contributors?.rich_text[0]?.plain_text) -
                                    parseInt(a.properties.Contributors.rich_text[0].plain_text)
                          )
                          ?.map((data, index) => {
                            var stars = data?.properties?.Stars?.number;
                            var score = data?.properties?.Score?.number;
                            const {
                              id,
                              cover,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Last Update": {
                                  date: { start },
                                },
                                Forks: { number },
                                URL: { url }
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text?.text.content,
                                date: start,
                                stars: stars,
                                forks: number,
                                url: url,
                                score: score
                              },
                            ];

                            return (
                              <PrimaryCard
                                category="Github"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeWeeklyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          cursor: "pointer",
                          opacity: activeWeeklyViewMore ? 1 : 0.1,
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {githubData?.monthly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name?.title[0].plain_text === value.properties?.Name?.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase()?.toString() === "last updated" ? new Date(b.properties["Last Update"].date.start).getTime() -
                              new Date(a.properties["Last Update"].date.start).getTime() :
                              sortBy.toLowerCase()?.toString() === "stars" ? b?.properties?.Stars?.number - a?.properties?.Stars?.number :
                                sortBy.toLowerCase()?.toString() === "forks" ? b?.properties?.Forks?.number - a?.properties?.Forks?.number :
                                  sortBy.toLowerCase()?.toString() === "score" ? b?.properties?.Score?.number - a?.properties?.Score?.number :
                                    sortBy.toLowerCase()?.toString() === "contributors" && parseInt(b.properties?.Contributors?.rich_text[0]?.plain_text) -
                                    parseInt(a?.properties?.Contributors?.rich_text[0]?.plain_text)
                          )
                          ?.map((data, index) => {
                            var stars = data?.properties?.Stars?.number;
                            var score = data?.properties?.Score?.number;
                            const {
                              id,
                              cover,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Last Update": {
                                  date: { start },
                                },
                                Forks: { number },
                                URL: { url }
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text?.text.content,
                                date: start,
                                stars: stars,
                                forks: number,
                                url: url,
                                score: score
                              },
                            ];

                            return (
                              <PrimaryCard
                                category="Github"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          cursor: "pointer",
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 3 && (
                <TabPanel value={value} index={3}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {
                          githubData?.all
                        // [...githubData?.latest, ...githubData?.weekly, ...githubData?.monthly]
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name?.title[0].plain_text === value.properties?.Name?.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase()?.toString() === "last updated" ? new Date(b?.properties["Last Update"]?.date?.start).getTime() -
                              new Date(a.properties["Last Update"]?.date?.start).getTime() :
                              sortBy.toLowerCase()?.toString() === "stars" ? b?.properties?.Stars?.number - a?.properties?.Stars?.number :
                                sortBy.toLowerCase()?.toString() === "forks" ? b?.properties?.Forks?.number - a.properties?.Forks?.number :
                                  sortBy.toLowerCase()?.toString() === "score" ? b?.properties?.Score?.number - a?.properties?.Score?.number :
                                    sortBy.toLowerCase()?.toString() === "contributors" && parseInt(b?.properties?.Contributors?.rich_text[0]?.plain_text) -
                                    parseInt(a?.properties?.Contributors?.rich_text[0]?.plain_text)
                          )
                          ?.map((data, index) => {
                            var stars = data?.properties?.Stars?.number;
                            var score = data?.properties?.Score?.number;
                            const {
                              id,
                              cover,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Last Update": {
                                  date: { start },
                                },
                                Forks: { number },
                                URL: { url }
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text?.text.content,
                                date: start,
                                stars: stars,
                                forks: number,
                                url: url,
                                score: score
                              },
                            ];

                            return (
                              <PrimaryCard
                                category="Github"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeAllViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          cursor: "pointer",
                          opacity: activeAllViewMore ? 1 : 0.1,
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              )}
            </Box>
            {/* End Tabs section */}
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Github;
