import React from 'react'

const IconSSvg = ({color}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0,0,250,250"
            width="24px" height="24px"
            fill-rule="nonzero"
            fill={color || "#9c9c9c"}
        >
            <g fill={color || "#9c9c9c"}
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{mixBlendMode: "normal"}}
            >
                <g transform="scale(10.66667,10.66667)">
                    <path d="M11.879,12.868c-1.809,-0.521 -3.126,-1.159 -3.949,-1.917c-0.824,-0.758 -1.236,-1.694 -1.236,-2.807c0,-1.26 0.503,-2.302 1.511,-3.126c1.007,-0.824 2.316,-1.236 3.929,-1.236c1.098,0 2.078,0.212 2.937,0.637c0.861,0.425 1.527,1.011 2,1.758c0.227,0.358 0.399,0.733 0.517,1.123c0.2,0.661 -0.312,1.327 -1.003,1.327v0c-0.503,0 -0.905,-0.368 -1.018,-0.858c-0.133,-0.574 -0.409,-1.051 -0.83,-1.431c-0.615,-0.555 -1.484,-0.833 -2.604,-0.833c-1.041,0 -1.853,0.229 -2.435,0.688c-0.582,0.46 -0.873,1.097 -0.873,1.911c0,0.653 0.276,1.206 0.829,1.657c0.553,0.451 1.494,0.864 2.824,1.238c1.33,0.374 2.369,0.787 3.119,1.237c0.752,0.451 1.309,0.977 1.672,1.577c0.361,0.601 0.543,1.308 0.543,2.12c0,1.297 -0.506,2.335 -1.516,3.115c-1.012,0.78 -2.363,1.17 -4.055,1.17c-1.1,0 -2.125,-0.211 -3.077,-0.632c-0.952,-0.421 -1.687,-0.998 -2.202,-1.73c-0.253,-0.359 -0.444,-0.741 -0.573,-1.147c-0.212,-0.666 0.299,-1.348 0.998,-1.348h0.01c0.492,0 0.888,0.354 1.011,0.83c0.151,0.585 0.475,1.072 0.971,1.461c0.714,0.561 1.668,0.841 2.862,0.841c1.113,0 1.967,-0.228 2.561,-0.682c0.592,-0.454 0.889,-1.073 0.889,-1.856c0,-0.784 -0.273,-1.391 -0.824,-1.818c-0.549,-0.429 -1.545,-0.851 -2.988,-1.269z">
                    </path>
                </g>
            </g>
        </svg>
    )
}

export default IconSSvg