import { Box, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, useMediaQuery, useTheme } from "@mui/material";
import logo from "../../assets/Icons/logo.svg";
import React, { useState } from "react";
import MyTextField from "./component/MyTextField";
import Button from "../../components/Button";
import GoogleSiginButton from "./component/GoogleSiginButton";
import { Link } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import { auth } from "../../services/firebase/firebase.config";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { checkUserExist, createUser } from "../../components/_shared/ApiRequests";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import LoadingDefault from "../../components/_shared/LoadingDefault";
import { InfoOutlined } from "@mui/icons-material";

const textStyle = {
  color: "#FFF",
  textAlign: "center",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "0.155px",
};

const SignUp = () => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormessage, setErrormessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("365"));

  const register = async () => {
    setIsLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password).then(async (u) => {
        const user = u.user;
        if (user) {
          setErrormessage(false);
          await createUser(auth).then(() => {
            window.location.replace("/login")
          }).catch((e) => {
            setErrormessage("Signup Failed! Please try again!")
            window.location.replace("/signup")
            setIsLoading(false)
          });
        }

      }).catch((e) => {
        setIsLoading(false)
        setErrormessage("Signup Failed! Please try again!");
      });
    } catch (error) {
      // console.log(error.code)
      switch (error.code) {
        case 'auth/email-already-in-use':
          setErrormessage("Email already exists.");
          break;
        case 'auth/invalid-email':
          setErrormessage("Invalid Email");
          break;
        case 'auth/operation-not-allowed':
          setErrormessage("Error during sign up. Please sign up again");
          break;
        case 'auth/weak-password':
          setErrormessage("Password is not strong enough. Add additional characters including special characters and numbers.");
          break;
        default:
          console.log(error.message);
          break;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (user === null || user === undefined) {
      } else {
        setIsLoading(true)
        checkUserExist(auth).then(async (data) => {
          if (data.userExist === false) {
            await createUser(auth);
            window.location.replace("/")
          } else {
            navigate("/")
          }
        }).catch((e) => {
          console.log(e)
          setIsLoading(false)
          auth.signOut()
        })

      }
    }
  }, [user, loading]);


  if (loading) {
    return <LoadingDefault />;
  }

  return (
    <MainContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: md ? "62px 0" : "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: "10px" }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "52px", height: "52px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "text.primary.main",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: "500",
                // lineHeight: "normal",
                textAlign: "center",
              }}
            >
              <DialogTitle sx={{ padding: "0px", mb: "4px", fontSize: { xs: "18px", md: "22px" } }} color={"text.primary.main"} bgcolor={"background.main"}>{"Create your free account"}</DialogTitle>
            </Box>
            <Box
              sx={{
                color: "text.secondary.main",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "center",
                maxWidth: "350px",
                fontFamily: "Poppins",
              }}
            >
              ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, and
              Reddit
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "9px",
            }}
          >
            <MyTextField
              label="Email"
              placeholder="Enter your email"
              state={email}
              setState={setEmail}
            // error={errormessage}
            // helperText={errormessage && "invalid credentials"}
            />
            <MyTextField
              label="Password"
              placeholder="Enter your password"
              state={password}
              setState={setPassword}
              type="password"
            // error={errormessage}
            // helperText={errormessage && "invalid credentials"}
            />
          </Box>
          <Box sx={{ marginTop: "40px", display: "flex", flexDirection: "column", gap: "8px" }}>
            <Button
              button_class="login_btn"
              text="Create Account"
              textStyles={textStyle}
              onClick={register}
              disabled={isLoading}
            />
            {errormessage &&
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <InfoOutlined htmlColor="red" sx={{ width: "14px", height: "14px", }} />
                <Typography sx={{ color: "red", fontSize: "12px" }}>{errormessage}</Typography>
              </Box>
            }
          </Box>
          <Typography
            sx={{
              color: "text.secondary.main",
              textAlign: "center",
              fontSize: "13.672px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginTop: "32px",
            }}
          >
            Or Continue with
          </Typography>
          <Box sx={{ marginTop: "32px" }}>
            <GoogleSiginButton />
          </Box>
          <Box sx={{ marginTop: "18px" }}>
            <Typography
              sx={{
                color: "text.primary.main",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.255px",
              }}
            >
              have an account?{" "}
              <Link to={"/login"} style={{ textDecoration: "none", color: "#3367B3" }}>
                Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default SignUp;
