import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import sammyLine from "../../images/sammyLine.svg";
import Button from "../../components/Button";
import MainContainer from "../../components/MainContainer";
import { GlobalState } from "../../App";
import { cancelUserSubscription } from "../../components/_shared/ApiRequests";
import { auth, logout } from "../../services/firebase/firebase.config";
import { useNavigate } from "react-router-dom";
const textStyle = {
  textAlign: "center",
  fontSize: "15.495px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "0.155px",
};
const ProfileCancelationPage = () => {
  const { isDarkMode } = useContext(GlobalState);

  const navigate = useNavigate()
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [cancelingSubscription, setCancelingSubscription] = useState(false)

  const cancelSubscription = async () => {
    setCancelingSubscription(true)
    await cancelUserSubscription(auth)
      .then(async (d) => {
        await logout();
        window.location.reload();
      })
      .catch((e) => {
        alert("You don't have a valid subscription! ");
        console.log(e);
      });
  };
  return (
    <MainContainer>
      <Box margin={"64px"}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography
            sx={{
              color: "text.primary.main",
              fontSize: md ? "24px" : "48px",
              fontStyle: "normal",
              fontWeight: "900",
              lineHeight: "63.984px",
              textAlign: "center",
            }}
          >
            We're sorry to see you go!
          </Typography>
          <Box>
            <img
              src={sammyLine}
              alt="image"
              style={{ width: md ? "300px" : "402px" }}
            />
          </Box>
          <Box display={"flex"} gap={"24px"} flexDirection={md && "column"}>
            <Button
              onClick={() => navigate(-1)}
              button_class="profile_btn"
              text="Go Back"
              textStyles={textStyle}
              backgroundColor="transparent"
              border={"1px solid #3367B3"}
              color={isDarkMode ? "white" : "black"}
            />
            <Button
              onClick={() => cancelSubscription()}
              button_class="profile_btn"
              text="Confirm"
              textStyles={textStyle}
              backgroundColor="#FF562E"
              disabled={cancelingSubscription}
            />
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default ProfileCancelationPage;
