import React from 'react'

const IconBlogSvg = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0,0,250,250"
        width="20px" height="20px"
            fill-rule="nonzero"
            fill={color || "#9c9c9c"}
        >
            <g fill={color || "#9c9c9c"}
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{mixBlendMode: "normal"}}
            >
                <g transform="scale(5.33333,5.33333)">
                    <path d="M14.9707,5.97266c-0.51192,0.00812 -1.00124,0.21224 -1.36719,0.57031c-0.01991,0.01848 -0.03945,0.03736 -0.05859,0.05664c-0.44602,0.45523 -0.64841,1.09576 -0.54492,1.72461v31.67578c0.00011,1.10452 0.89548,1.99989 2,2h11.5c5.7751,0 10.5,-4.7249 10.5,-10.5c0,-3.79677 -2.04961,-7.12989 -5.08984,-8.97461c1.90565,-1.72834 3.11934,-4.20832 3.13672,-6.96484c0.03278,-5.24598 -4.25368,-9.56055 -9.5,-9.56055h-10.22656c-0.11551,-0.01933 -0.2325,-0.02848 -0.34961,-0.02734zM17,10h8.54688c3.07568,0 5.51922,2.45914 5.5,5.53516c-0.01921,3.0465 -2.4523,5.46484 -5.5,5.46484h-7.54687h-1zM17,25h1h7.54688h0.95313c3.6129,0 6.5,2.8871 6.5,6.5c0,3.6129 -2.8871,6.5 -6.5,6.5h-9.5z">
                    </path>
                </g>
            </g>
        </svg>

    )
}

export default IconBlogSvg