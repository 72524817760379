import React,{ useContext} from 'react'
import { Box, Typography } from '@mui/material'
import Cards from '../../pricing/components/Cards'
import { GlobalState } from '../../../App';

const ProfileTabtwo = () => {
    const { isDarkMode, subscription } = useContext(GlobalState);
  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Box sx={{marginTop:'42px'}}>
        <Cards subscribed={subscription} />
        </Box>
        {/* <Box sx={{marginTop:'42px'}}>
            <Typography sx={{ color: isDarkMode? '#7D7A89':'rgba(0, 0, 0, 0.5)',}}>
                Valid till: 12/08/24
            </Typography>
        </Box> */}
    </Box>
  )
}

export default ProfileTabtwo