import styled from "styled-components";
import React from "react";
const MyComponent = styled("Box")({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: "#B6E7FF",
  color: "#2C69E6",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "12px",
  "@media (max-width: 780px)": {
    width: "31.42px !important",
    height: "31.42px !important",
    fontStyle: "normal !important",
    fontSize: "16.4281px !important",
    fontWeight: "600 !important",
    marginLeft: "8.98px !important",
  },
});
const MyComponent2 = styled("Box")({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: "#FFDDB6",
  color: "#E64D2C",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "12px",
  "@media (max-width: 780px)": {
    width: "31.42px !important",
    height: "31.42px !important",
    fontStyle: "normal !important",
    fontSize: "16.4281px !important",
    fontWeight: "600 !important",
    marginLeft: "8.98px !important",
  },
});
const MyComponent3 = styled("Box")({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: "#C9B6FF",
  color: "#7E2CE6",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "12px",
  "@media (max-width: 780px)": {
    width: "31.42px !important",
    height: "31.42px !important",
    fontStyle: "normal !important",
    fontSize: "16.4281px !important",
    fontWeight: "600 !important",
    marginLeft: "8.98px !important",
  },
});
const MyComponent4 = styled("Box")({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: "#B6FFE5",
  color: "#1F7A38",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "12px",
  "@media (max-width: 780px)": {
    width: "31.42px !important",
    height: "31.42px !important",
    fontStyle: "normal !important",
    fontSize: "16.4281px !important",
    fontWeight: "600 !important",
    marginLeft: "8.98px !important",
  },
});
export const Icon1 = ({count}) => {
  return (
    <MyComponent
      sx={{
        backgroundColor: "#FFDDB6",
        color: "#E64D2C",
      }}
    >
        {count ? count : "0"}
    </MyComponent>
  );
};
export const Icon2 = ({count}) => {
  return (
    <MyComponent2
      sx={{
        backgroundColor: "#FFDDB6",
        color: "#E64D2C",
      }}
    >
    {count ? count : "0"}
    </MyComponent2>
  );
};
export const Icon3 = ({count}) => {
  return (
    <MyComponent3
      sx={{
        backgroundColor: "#FFDDB6",
        color: "#E64D2C",
      }}
    >
      {count ? count : "0"}
    </MyComponent3>
  );
};
export const Icon4 = ({count}) => {
  return (
    <MyComponent4
      sx={{
        backgroundColor: "#FFDDB6",
        color: "#E64D2C",
      }}
    >
    {count ? count : "0"}
    </MyComponent4>
  );
};