import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalState } from '../App';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// Custom theme 
const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
    },
});

const Dropdown = ({ options, value, onChange }) => {
    const { isDarkMode } = useContext(GlobalState);
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ minWidth: 170, border: "none", outline: "none !important", }}>
                <FormControl fullWidth sx={{
                    // backgroundColor: isDarkMode
                    //     ? "#252836 !important"
                    //     : "transparent",
                    '&.MuiFormControl-root': {
                        border: "1px solid #373A50",
                        borderRadius: "11px",
                        backgroundColor: isDarkMode
                            ? "#252836"
                            : "transparent",
                    },
                    "& .MuiMenu-paper": {
                        backgroundColor: isDarkMode
                            ? "#252836"
                            : "transparent",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "0 !important",
                        borderRadius: "11px",
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '.MuiOutlinedInput': { border: 0 },
                }}>
                    <Select
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        color: isDarkMode ? "white" : "black",
                                        backgroundColor: isDarkMode
                                            ? "rgba(55, 58, 80, 1)"
                                            : "white",
                                            '& .Mui-selected':{
                                                backgroundColor:"#3367b3 !important",
                                                '&:hover':{
                                                    backgroundColor:"#3367b3 !important",
                                                }
                                            }
                                    }
                                }
                            }
                        }}
                        renderValue={(value) => (value === '' ? 'Select an option' : value)}
                        sx={{
                            color: isDarkMode ? "#B1B1B1" : "black", fontSize: "14px", outline: "none", borderRadius: "16px", ".MuiInputBase-input": {
                                padding: "10px 16px",
                                outline: "none",
                                '&:hover': {
                                    border: "none",
                                },
                                '&:focus': {
                                    border: "none",

                                },
                            },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        onChange={onChange}
                    >
                        {options?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
};

export default Dropdown;
