import React from 'react'

const  IconASvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0,0,250,250"
            width="28px" height="28px"
            fill-rule="nonzero"
            fill={color || "#9c9c9c"}>
            <g fill={color || "#9c9c9c"}
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{mixBlendMode: "normal"}}
            >
                <g transform="scale(3.55556,3.55556)">
                    <path d="M16.186,52.505c0,-0.913 0.295,-2.239 0.766,-3.535l10.49,-28.816c1.561,-4.332 4.184,-6.306 8.396,-6.306c4.449,0 7.043,1.886 8.664,6.306l10.576,28.816c0.502,1.385 0.707,2.387 0.707,3.506c0,3.035 -2.268,5.187 -5.42,5.187c-3.123,0 -4.744,-1.415 -5.688,-4.892l-1.443,-4.715h-14.556l-1.443,4.538c-1.031,3.595 -2.652,5.068 -5.539,5.068c-3.419,0 -5.51,-1.945 -5.51,-5.157zM40.877,39.895l-4.98,-16.383h-0.234l-4.833,16.383z">
                    </path>
                </g>
            </g>
        </svg>
    )
}

export default IconASvg