import axios from "axios";
import {
  BACKEND_URL,
  BACKEND_URL_LOCALHOST,
  GetFirebaseToken,
} from "../../utils/extension";
import { auth } from "../../services/firebase/firebase.config";

const useLocalhost = false;

export const URL_TO_USE = useLocalhost ? BACKEND_URL_LOCALHOST : BACKEND_URL

export const getAllFilteresList = () => {
  return new Promise(async(resolve, reject) => {
    axios.get(`${URL_TO_USE}filters`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
    })
      .then((response) => { 
        resolve(response?.data?.data[0])
      })
      .catch((error) => reject(error));
  })
}

export const getDataFromDatabase = async (dbName, pageNumber) => {

  return new Promise(async (resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${URL_TO_USE}${dbName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
      data: {
        page: pageNumber
      }
    };

    await axios
      .request(config)
      .then((response) => {
        switch (dbName) {
          case "home":
            resolve(response.data.data[0]);
            break;
          case "home/public":
            resolve(response.data.data[0]);
            break;
          case "arxiv":
            resolve(response.data.data[0].arxiv);
            break;
          case "ssrn":
            resolve(response.data.data[0].ssrn);
            break;
          case "github":
            resolve(response.data.data[0].github);
            break;
          case "pwc":
            resolve(response.data.data[0].pwc);
            break;
          case "videos":
            resolve(response.data.data[0].videos);
            break;
          case "blogs":
            resolve(response.data.data[0].blogs);
            break;
          case "reddit":
            resolve(response.data.data[0].reddit);
            break;
          case "news":
            resolve(response.data.data[0].news);
            break;
          case "linkedin":
            resolve(response.data.data[0].linkedin);
            break;
          case "seminars":
            resolve(response.data.data[0].seminar);
            break;
          case "jobs":
            resolve(response.data.data[0].jobs);
            break;
          case "repec":
            resolve(response.data.data[0].repec);
            break;
          case "podcasts":
            resolve(response.data.data[0].podcasts);
            break;
          default:
            reject(false);
            break;
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDataById = async (dbName, id) => {
  return new Promise(async (resolve, reject) => {
    let data = JSON.stringify({
      id: id,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${URL_TO_USE}${dbName.toLowerCase()}/detail`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createUser = async (auth) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${URL_TO_USE}signup`,
      headers: {
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkUserSubscription = async (auth) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${URL_TO_USE}subscription/status`,
      headers: {
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
    };
    axios
      .request(config)
      .then((response) => {
        // resolve({
        //   data: true
        // });
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const cancelUserSubscription = async (auth) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "post",
      url: `${URL_TO_USE}subscription/cancel`,
      headers: {
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
    };
    await axios
      .request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkUserExist = async (auth) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "get",
      url: `${URL_TO_USE}user`,
      headers: {
        Authorization: "Bearer " + (await GetFirebaseToken(auth)),
      },
    };
    await axios
      .request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSearchedData = (search) => {
  return new Promise(async (resolve, reject) => {
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_TO_USE}home/search/${search}`,
        headers: {
          'Authorization': 'Bearer ' + await GetFirebaseToken(auth)
        }
      };

      axios.request(config)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        });
    } catch (error) {
      reject(error)
    }
  })
}

export const updateUserDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${URL_TO_USE}user`,
        headers: {
          Authorization: "Bearer " + (await GetFirebaseToken(auth)),
        },
        data: data
      };

      axios
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error)
    }
  })
}
