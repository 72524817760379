import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import CategoryLayout from "./components/CategoryLayout";
import MasonryLayout from "../../components/MasonryLayout";
import { checkUserExist, checkUserSubscription, createUser, getDataFromDatabase } from "../../components/_shared/ApiRequests";
import { GlobalState, getCachedData } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/_shared/lottie/Loading";
import logo from "../../assets/Icons/logo.svg";
import GoogleSiginButton from "../auth/component/GoogleSiginButton";
import { Email } from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase/firebase.config";
import Newsletter from "../../Newsletter";
import { decryptJSON, encryptJSON } from "../../utils/extension";
const HomePublic = () => {

    const navigate = useNavigate()
    const [homeData, setHomeData] = useState();
    const [showLoading, setShowLoading] = useState(true);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const v1200 = useMediaQuery(theme.breakpoints.down("1200"));
    const v700 = useMediaQuery(theme.breakpoints.down("700"));
    const { isDarkTheme } = useContext(GlobalState)
    const [user, loading, error] = useAuthState(auth);

    const { setUserDetails, subscription, setSubscription, } =
        useContext(GlobalState);

    const checkSubscription = async () => {
        try {
            const subscriptionStatus = await checkUserSubscription(auth);
            setSubscription(subscriptionStatus?.data);
            navigate(!subscriptionStatus.data ? "/pricing" : "/");
            // navigate(!subscriptionStatus.data ? "/" : "/");
            setShowLoading(false);
        } catch (error) {

        }
    }

    useEffect(() => {
        localStorage.removeItem("home/public");
        try {
            let key = "home/public"
            const cachedData = localStorage.getItem(key);
            cachedData && setHomeData(decryptJSON(cachedData))
            getDataFromDatabase(key).then((list) => {
                const ciphertext = encryptJSON(list);
                localStorage.setItem(key, ciphertext);
                setHomeData(list);
            });
        } catch {
            window.localStorage.clear()
            window.location.reload()
        }
    }, [setSubscription]);

    useEffect(() => {
        if (!loading) {
            setShowLoading(true);
            if (!user) {
                setShowLoading(false);
            } else {
                setUserDetails(user);
                checkUserExist(auth).then(async (data) => {
                    if (data.userExist === false) {
                        await createUser(auth);
                        checkSubscription();
                    } else {
                        checkSubscription();
                    }
                }).catch((e) => {
                    setShowLoading(false)
                    auth.signOut()
                })

                setShowLoading(false);
            }
        }
    }, [user, loading, setUserDetails]);



    useEffect(() => { }, [homeData]);
    const lightGradient = "linear-gradient(to bottom, transparent 0%, #F6F6F6 100%)";
    const dartGradient = "linear-gradient(to bottom, transparent 0%, #212230 100%)";
    return (
        <Box sx={{
            height: "100vh !important",
            // position:"relative"
        }}>
            {
                showLoading ? <Loading />
                    :
                    <>
                        <MainContainer>
                            <Box
                                sx={{
                                    bgcolor: "background.main",
                                    width: "100%",
                                    color: "text.primary.main",
                                    paddingTop: "56px",
                                    position: "relative"
                                }}
                            >
                                <MasonryLayout>
                                    <CategoryLayout data={homeData?.arxiv} title="ArXiv">
                                    </CategoryLayout>

                                    {!v1200 && <CategoryLayout category="ssrn" data={homeData?.ssrn} title="SSRN PAPERS">
                                    </CategoryLayout>}

                                    {!v700 && <CategoryLayout data={homeData?.blogs} title="Blogs">
                                    </CategoryLayout>
                                    }
                                </MasonryLayout>

                                <a href="#subscription-section"><Box sx={{
                                    cursor: "default"
                                }} position={"absolute"} cursor="" onClick={() => {

                                }} height={"100%"} top={0} left={0} right={0} bgcolor={"rgba(255,255,255, 0.0)"}>
                                </Box></a>  </Box>
                        </MainContainer>

                        <Box sx={{
                            position: "relative",
                            top: "-300px",
                            left: 0,
                            right: 0,
                            height: "200px",
                            background: !localStorage.getItem("theme") ? dartGradient : lightGradient,
                            padding: "200px 0px 0px 0px",
                            zIndex: 1,

                        }}>

                            <Box id="subscription-section" sx={{
                                // position: "absolute",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px",
                                paddingBottom: {xs:"32px", md:"64px"},
                                bgcolor: "background.main",
                                paddingTop: "0px",
                                // backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))'
                            }}>

                                <Box sx={{ width: "100%", }}>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{ width: "52px", height: "52px" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                color: "text.primary.main",
                                                fontSize: "32px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                lineHeight: "normal",
                                            }}
                                        >
                                           <Typography sx={{
                                            fontSize:{xs:"18px", md:"22px"}
                                           }}>Create your account</Typography> 
                                        </Box>
                                        <Box
                                            sx={{
                                                color: "text.secondary.main",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                maxWidth: "350px",
                                                fontFamily: "Poppins",
                                            }}
                                        >
                                          <Typography sx={{
                                            fontSize:"14px"
                                          }}>ArXiv, SSRN, Blogs, Videos, Podcasts, News, LinkedIn, GitHub, and
                                            Reddit</Typography>  
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "12px",
                                    mt:"8px"
                                }}>
                                    <Link
                                        to="/signup"
                                        style={{
                                            textDecoration: "none",
                                            marginBottom:"8px"
                                        }}>
                                        <button
                                            style={{
                                                borderRadius: "24px",
                                                paddingTop: "13px",
                                                paddingBottom: "13px",
                                                paddingLeft: "32px",
                                                paddingRight: "32px",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                color: "white",
                                                backgroundColor: !localStorage.getItem("theme") ? "#2A2D3E" : "#3367B3",
                                                border: "none",
                                                outline: "none",
                                                cursor: "pointer",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '8px',
                                                width: md ? '260px' : '350px',
                                                height: '52px'
                                            }}
                                        >

                                            <Email />
                                            <Typography sx={{
                                                lineHeight: "100%",
                                                color: '#FFF',
                                                textAlign: 'center',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal',
                                            }}>
                                                Signup with email
                                            </Typography>
                                        </button>
                                    </Link>
                                    <GoogleSiginButton />
                                </Box>
                                <Box sx={{
                                    // marginTop: "25px",
                                }}>
                                    <Typography
                                        sx={{
                                            color: "text.primary.main",
                                            textAlign: "center",
                                            fontFamily: "Poppins",
                                            fontSize: "12.76px",
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            lineHeight: "normal",
                                            letterSpacing: "0.255px",
                                        }}
                                    >
                                        Already have an account?{" "}
                                        <Link to={"/login"} style={{ textDecoration: "none", color: "#3367B3" }}>
                                            Sign In
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>

                            <Newsletter />
                        </Box>
                    </>
            }
        </Box>
    );
};

export default HomePublic;
