import React, { useState, useEffect } from 'react'
import { genRandNum, isImageUrlValid } from '../utils/extension';

const Image = ({ title, cover, style, url, category, imageHasLoaded, cachedImg}) => {

  const [imgErr, setImgErr] = useState(false);
  const [coverUrlErr, setCoverUrlErr] = useState(false);
  const placeholderUrl = `https://ui-avatars.com/api/?color=text.secondary.main&name=${title}`;
  const websiteScreenshot = `https://image.thum.io/get/auth/53787-sov/${url}/`
  const randomImg = `https://picsum.photos/${200 + genRandNum(0, 30)}`
  
  isImageUrlValid(cachedImg || cover, function (isValid) {
    if (isValid) {
      setCoverUrlErr(false);
    } else {
      setTimeout(() => {
        setCoverUrlErr(true);
      }, 1000)
    }
  });
  
  const handleImageLoaded = () => {
    imageHasLoaded && imageHasLoaded(true)
  //   setTimeout(() => {
      
  // }, 5000)
   
  };
  useEffect(() => { }, [imgErr, coverUrlErr])
  return (
    <>
      <img
        onError={(e) => { setImgErr(true) }}
        onLoad={handleImageLoaded}
        style={{
          ...style,
          objectFit: "cover",
          opacity: imgErr ? "0.9" : cachedImg || cover ? 1 : "1",
          borderRadius: "8px",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
        }}
        width="100%"
        height="100%"
        alt=""
        loading="lazy"
        src={category?.toUpperCase() === "SSRN" ?
          `https://picsum.photos/200/300?random=${genRandNum(0, 255)}`
          // : imgErr ? placeholderUrl
         : category === "profile" ? cover? cover: placeholderUrl :
             cover ? coverUrlErr ? websiteScreenshot ? websiteScreenshot
              : placeholderUrl : cover
              : websiteScreenshot ? websiteScreenshot
                : placeholderUrl
        }
      //   src={category?.toUpperCase() === "SSRN" ?
      //   `https://picsum.photos/200/300?random=${genRandNum(0, 255)}`
      //   : imgErr ? placeholderUrl : websiteScreenshot
      // }
      />
    </>
  )
}

export default Image
