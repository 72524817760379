import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NavbarTab from "../components/NavbarTab";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreHoriz, MoreVert } from "@mui/icons-material";
import { GlobalState } from "../App";

const NavOthersDropdown = () => {

  const { isDarkMode } = useContext(GlobalState);

  const [activeTab, setActiveTab] = useState("/");
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  useEffect(() => {
    const currentUrlPath = location.pathname.split("/")[1];
    if (currentUrlPath !== "") {
      setActiveTab(currentUrlPath);
    } else {
      setActiveTab("home");
    }
  }, [location]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {/* <Box> */}
      <button
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingLeft: "23px",
          paddingRight: "23px",
          backgroundColor: "transparent",
          border: "none",
          marginTop: "2px",
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "text.secondary.main",
            cursor: "pointer",
            fontWeight: "500",
          }}
        >
          More
        </Typography>

      </button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{bgcolor:"transparent",}}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            WebkitBoxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
            MozBoxShadow: " 2px 10px 27px -8px rgba(0,0,0,0.75)",
            boxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
            paddingY: "24px",
            bgcolor: isDarkMode ?"#1F1D2B" :"#FFFFFF",
            gap: "16px",
            zIndex: 1,
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="jobs"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              title="Jobs"
              isDropdownItem={true}
              styles={{
                // color: activeTab.toLowerCase() === "blogs" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
                paddingY: "0px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="repec"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              title="Repec"
              isDropdownItem={true}
              styles={{
                // color: activeTab.toLowerCase() === "blogs" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
                paddingY: "0px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="podcasts"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              title="Podcasts"
              isDropdownItem={true}
              styles={{
                // color: activeTab.toLowerCase() === "blogs" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
                paddingY: "0px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="videos"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              isDropdownItem={true}
              title="Videos"
              styles={{
                // color: activeTab.toLowerCase() === "videos" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="pwc"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              isDropdownItem={true}
              title="PwC"
              styles={{
                // color: activeTab.toLowerCase() === "pwc" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="news"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              isDropdownItem={true}
              title="News"
              styles={{
                // color: activeTab.toLowerCase() === "news" ? "#3367B3" : "text.primary.main",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="linkedin"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              isDropdownItem={true}
              title="Linkedin"
              styles={{
                // color: activeTab.toLowerCase() === "linkedin" ? "#3367B3" : "black",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            />
          </MenuItem>

          <MenuItem
            sx={{ width: "100%", padding: "6px", marginRight: "48px" }}
            onClick={handleClose}
          >
            <NavbarTab
              redirectTo="reddit"
              iconName="heart"
              iconSize="24"
              activeTab={activeTab}
              isDropdownItem={true}
              title="Reddit"
              styles={{
                // color: activeTab.toLowerCase() === "reddit" ? "#3367B3" : "black",
                fontWeight: "500",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            />
          </MenuItem>
        </Box>

      </Menu>


      {/* <Box   sx={{
            paddingY: "16px",
          }} onClick={() => setIsActiveDropdown(!isActiveDropdown)}>
        <Typography
          sx={{
            fontSize: "14px",
            paddingLeft: "23px",
            paddingRight: "23px",
            marginTop:"2px",
            color: "text.secondary.main",
            cursor: "pointer",
          }}
        >
          Others
        </Typography>
      </Box>

      <Box
        sx={{
          display:isActiveDropdown ? "flex" : "none",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: "48px",
          WebkitBoxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
          MozBoxShadow: " 2px 10px 27px -8px rgba(0,0,0,0.75)",
          boxShadow: "2px 10px 27px -8px rgba(0,0,0,0.75)",
          paddingY: "24px",
          position: "absolute",
          top: "50px",
          left: "0px",
          bgcolor: "primary.main",
          gap: "16px",
          zIndex: 1,
          borderRadius: "8px",
        }}
      >
        <NavbarTab
          redirectTo="blogs"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          title="Blogs"
          isDropdownItem={true}
          styles={{
            // color: activeTab.toLowerCase() === "blogs" ? "#3367B3" : "text.primary.main",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
            paddingY: "0px",
          }}
        />
        <NavbarTab
          redirectTo="videos"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          isDropdownItem={true}
          title="Videos"
          styles={{
            // color: activeTab.toLowerCase() === "videos" ? "#3367B3" : "text.primary.main",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
          }}
        />

        <NavbarTab
          redirectTo="pwc"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          isDropdownItem={true}
          title="PwC"
          styles={{
            // color: activeTab.toLowerCase() === "pwc" ? "#3367B3" : "text.primary.main",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
          }}
        />
        <NavbarTab
          redirectTo="news"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          isDropdownItem={true}
          title="News"
          styles={{
            // color: activeTab.toLowerCase() === "news" ? "#3367B3" : "text.primary.main",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
          }}
        />
        <NavbarTab
          redirectTo="linkedin"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          isDropdownItem={true}
          title="Linkedin"
          styles={{
            // color: activeTab.toLowerCase() === "linkedin" ? "#3367B3" : "black",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
          }}
        />
        <NavbarTab
          redirectTo="reddit"
          iconName="heart"
          iconSize="24"
          activeTab={activeTab}
          isDropdownItem={true}
          title="Reddit"
          styles={{
            // color: activeTab.toLowerCase() === "reddit" ? "#3367B3" : "black",
            fontWeight: "500",
            lineHeight: "150%",
            fontSize: "16px",
          }}
        />
      </Box> */}
    </Box>
  );
};

export default NavOthersDropdown;
