import { Box, TextField, Typography, colors } from '@mui/material'
import logo from '../../assets/Icons/logo.svg'
import React, { useState } from 'react'
import MyTextField from './component/MyTextField'
import Button from '../../components/Button'
import GoogleSiginButton from './component/GoogleSiginButton'
import { Link } from 'react-router-dom'
import MainContainer from '../../components/MainContainer'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../services/firebase/firebase.config'
const textStyle = {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '15.495px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.155px',
}
const ResetPassword = () => {
    const [email, setEmail] = useState()

    var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'https://www.ml-quant.com/login',
        handleCodeInApp: false
    };
    return (
        <MainContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '72px 0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={logo} alt='logo' style={{ width: '62px', height: '62px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{
                            color: 'text.primary.main',
                            fontSize: {xs:"18px", md:'22px !important'},
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}>Reset Password</Box>
                        <Box sx={{
                            color: 'text.secondary.main',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            textAlign: 'center',
                            maxWidth: '350px'
                        }}>To reset your password, please click on the 'Forgot Password' link below and follow the instructions sent to your registered email address.</Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '9px' }}>
                        <MyTextField label="Password" placeholder="Enter your password" />
                        <MyTextField label="Confirm Password" placeholder="Enter your password" />

                    </Box>
                    <Box sx={{ marginTop: '40px' }}>
                        <Button onClick={
                            async () => {
                                await sendPasswordResetEmail(auth, email, actionCodeSettings);;
                                alert(
                                    "You will receive the password reset link shortly on your registered email!"
                                );
                            }
                        } button_class="login_btn" text="Reset Password" textStyles={textStyle} />
                    </Box>
                </Box>
            </Box>
        </MainContainer>
    )
}

export default ResetPassword