import React from 'react'
import Loading from '../../../../components/_shared/lottie/Loading'
import { Box } from '@mui/material'
const CardLoading = ({size}) => {
  return (
    <div className="blur" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", display: "flex", justifyContent: 'center', alignItems: 'center', borderRadius: "8px" }}>
      <Box width={size ? size : "25px"} height={size ? size : "25px"}><Loading minHeight="15px" /></Box>
    </div>
  )
}

export default CardLoading
