import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
    convertDateFormat,
} from "../../../../utils/extension";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image";
import MultilineEllipsis from "../../../../components/_shared/MultilineEllipsis";
import Button from "../../../../components/Button";
import { createStyles, makeStyles } from "@mui/styles";
import { destuctureData } from "../../../../utils/DataDestructure";
import { useTheme } from "@mui/material";
import Tooltip from "../../../../components/Tooltip";
import CardLoading from "./Loading";
const RepecCard = ({ data, category }) => {
    const styles = useStyles();
    const [date, setDate] = useState();
    const [item, setItem] = useState();
    const theme = useTheme()
    const [isLoaded, setIsLoaded] = useState(false)
    useEffect(() => {
        convertDateFormat(item?.date).then((date) => {
            setDate(date);
        });
    }, [item]);

    useEffect(() => {
        const item = destuctureData(data, "repec");
        setItem(item)
    }, [])
    return (
        <Box
            key={item?.id}
            sx={{
                display: "flex",
                padding: "16px",
                justifyContent: "flex-between",
                alignItems: "flex-start",
                width: "100%",
                maxWidth: { xs: "350px", sm: "350px", md: "420px" },
                gap: "16px",
            }}
        >

            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {/* Card image */}
                <Box
                    sx={{
                        maxWidth: "50px",
                        maxHeight: "50px",
                        borderRadius: "8px",
                        aspectRatio: "1/1",
                        width: "100%",
                        height: "100%",
                        position:"relative",
                        flex: { xs: "0 0 auto", sm: "none" }, // Set flex-basis to auto on small screens
                    }}
                >
                    <Image cover={item?.image} url={item?.url} title={item?.title} imageHasLoaded={(value) => setIsLoaded(value)} />
          {!isLoaded && <CardLoading/>}
                </Box>

                <Button
                    target="_blank"
                    url={`/detail/${category}/${item?.id}`}
                    text="Open"
                    button_class={styles.openBtn}
                    textStyles={{ fontSize: "10px" }}
                />
            </Box>

            {/* Card Details */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Box
                    sx={{
                        color: "text.secondary.main",
                        maxWidth: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "4px",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "100%",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                            flexWrap: "wrap",
                        }}
                    >
                        <Link
                            target="_blank"
                            style={{ cursor: "pointer", textDecoration: "none" }}
                            to={item?.url}
                            data-tooltip-id={`my-tooltip-${item?.id}`}
                        >
                            <MultilineEllipsis
                                style={{ maxWidth: "100%" }}
                                color="text.primary.main"
                                text={item?.title}
                            />
                        </Link>

                        <MultilineEllipsis
                            color="text.secondary.main"
                            text={item?.description}
                        />
                    </Box>

                </Box>
                <Box
                    sx={{
                        color: "text.secondary.main",
                        width: "100%",
                        display: "flex",
                        flexDirection: { xs: "row", lg: "row" },
                        justifyContent: "space-between",
                        gap: "4px",
                    }}
                >
                    <Typography sx={{ fontSize: "12px" }}>
                        Downloads : {item?.totalDownloads?.toFixed(2) || "-"}
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>{date}</Typography>
                </Box>
            </Box>
            <Tooltip id={item?.id} place={"left"} data={item} />
        </Box>
    )
}

export default RepecCard;

const useStyles = makeStyles(
    createStyles({
        openBtn: {
            paddingTop: "5px !important",
            paddingBottom: "5px !important",
            paddingLeft: "10px !important",
            paddingRight: "10px !important",
            justifySelf: "flex-end",
            width: "100%",
        },
    })
);