import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import { convertDateFormat } from "../../../utils/extension";
import Image from "../../../components/Image";
import SingleLineEllipsis from "../../../components/_shared/SingleLineEllipsis";
import MultilineEllipsis from "../../../components/_shared/MultilineEllipsis";
import Tooltip from "../../../components/Tooltip";
import Button from "../../../components/Button";
import { createStyles, makeStyles } from "@mui/styles";

const PrimaryCard = ({ data, category }) => {
  const [date, setDate] = useState();
  const styles = useStyles();

  useEffect(() => {
    convertDateFormat(data.date).then((date) => {
      setDate(date);
    });
  }, [data]);
  const [isLoaded, setIsLoaded] = useState(false)
  const handleImageLoaded = () => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 4200)

  };

  return (
    // <Link
    //   target="_blank"
    //   to={`/detail/${category}/${data?.id}`}
    //   style={{
    //     textDecoration: "none",
    //   }}
    // >
    <Box
      sx={{
        width: "100%",
        padding: "18px",
        display: "flex",
        flexDirection: "column",
        bgcolor: "surface.main",
        borderRadius: "20px",
        maxWidth: { xs: "342px", md: "342px", lg: "400px" },
        alignItems: "flex-start",
        // aspectRatio: "400/552",
        color: "text.primary.main",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "20px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: { xs: "5px", lg: "16px" },
        }}
      >
        <Link
          target="_blank"
          to={data?.url}
          style={{
            textDecoration: "none",
            // aspectRatio: "368/240",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              // aspectRatio: "368/240",
              borderRadius: "8px",
              position: "relative",
              overflow:'hidden'
            }}
          >

            <Image
              style={{ aspectRatio: "368/240", 
              // scale: isLoaded ? '1' : '2'
             }}
              cover={data?.image}
              title={data?.title}
              url={data?.url}
              imageHasLoaded={(value) => setIsLoaded(value)}
            />
            {/* {!isLoaded && <CardLoading size={"55px"}/>} */}
          </Box>
        </Link>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: { xs: "12px", lg: "16px" },
            }}
          >

            <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", gap: "16px" }}>

              <Link
                target="_blank"
                to={data?.url}
                style={{
                  textDecoration: "none",
                }}
              >
                <MultilineEllipsis
                  style={{ fontSize: "14px", }}
                  color="text.primary.main"
                  text={data?.title}
                  tooltipId={`my-tooltip-${data?.id}`}
                />
              </Link>
              <Button
                target="_blank"
                url={`/detail/${category}/${data?.id}`}
                text="Open"
                button_class={styles.openBtn}
                textStyles={{ fontSize: "10px" }}
              />
            </Box>

            <MultilineEllipsis
              style={{ fontSize: "13px" }}
              color="text.secondary.main"
              text={data?.description}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          color: "text.secondary.main",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SingleLineEllipsis
          style={{ fontSize: "14px", maxWidth: "60%" }}
          text={data?.author ?? data?.author}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", lg: "center" },
          gap: "8px",
          marginTop: { xs: "8px", lg: "24px" },
        }}
      >
        <Typography
          sx={{
            color: "text.secondary.main",
            fontSize: { xs: "13px", sm: "13px" },
          }}
          fontWeight={400}
          lineHeight="120%"
        >
          {date}
        </Typography>

        <Box Box sx={{ display: "flex", gap: "10px", alignSelf: { xs: "flex-start", sm: "flex-end" } }}>

          {
            data?.forks !== undefined &&
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <Icon size={22} name="fork" fill="#9C9C9C" />
              <Typography
                sx={{
                  color: "text.secondary.main",
                  fontSize: { xs: "13px", sm: "13px" },
                }}
              >
                {data?.forks || 0}
              </Typography>
            </Box>
          }
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Icon size={22} name="star" fill="#9C9C9C" />
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
            >
              {data?.stars}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Tooltip id={data?.id} place={"right"} data={data} containerStyles={{ maxWidth: "250px", width: "100%", minWidth: "150px" }} />

    </Box>
    // {/* </Link> */}
  );
};

export default PrimaryCard;

const useStyles = makeStyles(
  createStyles({
    openBtn: {
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      justifySelf: "flex-end",
      width: "100%",
    },
  })
);