import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";

const SingleLineEllipsis = ({ text, color, style }) => {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: "500",
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
        maxHeight: "40px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        whiteSpace: "normal",
        color: color,
        ...style
      }}
    >
      {text}
    </Typography>
  );
};

export default SingleLineEllipsis;
