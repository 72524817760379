import { Typography, Box } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import ArXivLogo from "../../assets/arxivLogo.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PrimaryCard from "../../components/PrimaryCard";
import { getDataFromDatabase } from "../../components/_shared/ApiRequests";
import Loading from "../../components/_shared/lottie/Loading";
import { GlobalState, getDataFromIndexedDB, storeDataInIndexedDB } from "../../App";
import Dropdown from "../../components/Dropdown";
import { Helmet } from "react-helmet";
import CardLoading from "../home/components/homeCards/Loading";
import { DB_KEY } from "../../utils/extension";

const ArXiv = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ display: "flex" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [activePageNumber, setActivePageNumber] = useState(1);
  // const [valueMonthlyTopics, setValueMonthlyTopics] = useState(0);

  const [sortBy, setSortBy] = useState("Score")

  const [dataList, setDataList] = useState([]);
  const [arxivData, setArxivData] = useState([]);

  // view more handling states
  const [activeLatestViewMore, setActiveLatestViewMore] = useState(true);
  const [activeWeeklyViewMore, setActiveWeeklyViewMore] = useState(true);
  const [activeMonthlyViewMore, setActiveMonthlyViewMore] = useState(true);
  const [activeAllViewMore, setActiveAllViewMore] = useState(true);

  const { isDarkMode, ARXIVCategories } = useContext(GlobalState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activePageNumber === 1) {
      getCachedData().then((data => {
        const rData = data;
        
        const retrievedData = rData.data
        if (retrievedData) {
          setDataAndStopLoading(retrievedData)
        }
      }))
    }
    getDataFromDatabase("arxiv", activePageNumber).then(async (list) => {
      await storeDataInIndexedDB(DB_KEY.ARXIV, list)
      const rData = await getDataFromIndexedDB(DB_KEY.ARXIV);
      
      const retrievedData = rData.data
      if (retrievedData) {
        if (retrievedData?.latest?.length === 0) {
          setActiveLatestViewMore(false);
        }
        if (retrievedData?.weekly?.length === 0) {
          setActiveWeeklyViewMore(false);
        }
        if (retrievedData?.monthly?.length === 0) {
          setActiveMonthlyViewMore(false);
        }
        if (retrievedData?.all?.length === 0) {
          setActiveAllViewMore(false);
        }
        setDataAndStopLoading(retrievedData)
      }
    });
  }, [activePageNumber]);

  const getCachedData = async () => {
    const data = await getDataFromIndexedDB(DB_KEY.ARXIV);
    return data;
  }

  const setDataAndStopLoading = (data) => {
    setDataList(data);
    setShowLoading(false);
    setPaginationLoading(false);
  }

  useEffect(() => {
    if (dataList.length !== 0) {
      setArxivData((prevList) => {

        if (prevList?.length !== 0) {

          // FILTER PREVIOUS LIST
          let prevLatestList = prevList?.latest?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let prevWeeklyList = prevList?.weekly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let prevMonthlyList = prevList?.monthly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let prevAllList = prevList?.all?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          // FILTER DATA LIST
          let dataListLatest = dataList?.latest?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListWeekly = dataList?.weekly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListMonthly = dataList?.monthly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListAll = dataList?.all?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          // Spread the previous list and append the new data
          return {
            latest: [...prevLatestList, ...dataListLatest],
            monthly: [...prevMonthlyList, ...dataListMonthly],
            weekly: [...prevWeeklyList, ...dataListWeekly],
            all: [...prevAllList, ...dataListAll],
          };
        } else {
          // If prevList is undefined or empty, set dataList directly
          // FILTER DATA LIST
          let dataListLatest = dataList?.latest?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListWeekly = dataList?.weekly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListMonthly = dataList?.monthly?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          let dataListAll = dataList?.all?.filter((item) => {
            const category = item?.properties?.Category?.rich_text[0]?.text?.content;
            const containsForbiddenCategory = ARXIVCategories.some(item => item === category?.toString()?.toLowerCase());
            return !containsForbiddenCategory;
          })

          return {
            latest: dataListLatest,
            monthly: dataListMonthly,
            weekly: dataListWeekly,
            all: dataListAll
          };
        }
      });
    }
  }, [dataList]);

  return (
    <Box
      sx={{
        bgcolor: "background.main",
        width: "100%",
        color: "text.primary.main",
        paddingTop: "56px",
        paddingBottom: "72px",
      }}
    >
      <Helmet>
        <title>ML-Quant ArXiv: Machine Learning & Quantitative Finance</title>
        <meta
          name="description"
          content="ArXiv is a free distribution service and an open-access archive for nearly 2.4 million scholarly articles in the fields of physics, mathematics, computer science, quantitative biology, quantitative finance, statistics, electrical engineering and systems science, and economics. Materials on this site are not peer-reviewed by arXiv."
        />
        <meta
          name="keywords"
          content="ArXiv, ML-Quant, Finance, Machine Learning, Quantitative Finance"
        />
      </Helmet>
      <MainContainer>
        <Box
          sx={{
            width: "100%",
            color: "text.primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "40px", md: "56px" },
          }}
        >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            position: "relative",
          }}>

            <Box
              sx={{
                display:{xs:"none", md:"block"},
                maxWidth: { xs: "150px", sm: "250px", lg: "444px" },
                maxHeight: "200px",
                // aspectRatio: "444/200",
                aspectRatio: "444/100",
                width: "100%",
                position: "absolute",
                left: "0"
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  aspectRatio: "444/100",
                  // aspectRatio: "444/200",
                  objectFit: "contain",
                }}
                alt="ArXiv logo"
                src={ArXivLogo}
              />
            </Box>
            <Typography
              sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
              fontWeight={500}
            >
              ArXiv-Quant
            </Typography>

          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >

            {/* Tabs section */}
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", md: "42px" },
              }}
            >
              <Box sx={{
                display: "flex", width: "100%",
                justifyContent: "space-between", flexWrap: "wrap", flexDirection: { xs: "column", md: "row" },
                alignItems: "center", gap: { xs: "40px", md: "0px" },
              }}>
                <Box sx={{ minWidth: "168px", display: { xs: "none", md: "flex" }, width: { xs: "0px", md: "auto" }, }}></Box>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    borderRadius: "14px",
                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    maxHeight: { xs: "45px", md: "50px" },
                    display: "flex",
                  }}
                >
                  <Tabs
                    indicatorColor="none"
                    sx={{
                      maxHeight: { xs: "45px", md: "50px" },
                      minHeight: "auto",
                    }}
                    style={{
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 0.876712px solid #373A50",
                      borderRadius: " 14.0274px",
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 0 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 0
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 0 ? "600" : "600",
                      }}
                      label="Latest"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 1 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 1
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 1 ? "600" : "600",
                      }}
                      label="Weekly"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 2 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 2
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 2 ? "600" : "600",
                      }}
                      label="Monthly"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        fontSize: { xs: "11px !important", md: "12px !important" },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 3 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 3
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 3 ? "600" : "600",
                      }}
                      label="All"
                      {...a11yProps(3)}
                    />
                  </Tabs>

                </Box>

                <Box
                  sx={{ width: { xs: "100%", md: "fit-content" }, display: "flex", justifyContent: "flex-start" }}
                >
                  <Dropdown
                    options={["All", "Score", "Date Retrived"]}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  />
                </Box>
              </Box>

              {value === 0 ? (
                <TabPanel value={value} index={0}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        key={0}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {arxivData?.latest
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Score" ? b.properties.Score.number - a.properties.Score.number :
                              sortBy === "Date Retrived" && new Date(b.properties["Date Retrieved"].date.start) - new Date(a.properties["Date Retrieved"].date.start)
                          )
                          ?.map((data, index) => {
                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Date Retrieved": {
                                  date: { start },
                                },
                                URL: { url },
                                Score: { number },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: url,
                                score: number,
                              },
                            ];
                            return <PrimaryCard
                              category="Arxiv"
                              data={filteredData[0]}
                            />
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeLatestViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeLatestViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {arxivData?.weekly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Score" ? b.properties.Score.number - a.properties.Score.number :
                              sortBy === "Date Retrived" && new Date(b.properties["Date Retrieved"].date.start) - new Date(a.properties["Date Retrieved"].date.start)
                          )
                          ?.map((data, index) => {
                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Date Retrieved": {
                                  date: { start },
                                },
                                URL: { url },
                                Score: { number },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: url,
                                score: number,
                              },
                            ];
                            return <PrimaryCard
                              category="Arxiv"
                              data={filteredData[0]}
                            />
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeWeeklyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}

                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeWeeklyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {arxivData?.monthly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Score" ? b.properties.Score.number - a.properties.Score.number :
                              sortBy === "Date Retrived" && new Date(b.properties["Date Retrieved"].date.start) - new Date(a.properties["Date Retrieved"].date.start)
                          )
                          ?.map((data, index) => {
                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                "Date Retrieved": {
                                  date: { start },
                                },
                                URL: { url },
                                Score: { number },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: cover,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: url,
                                score: number,
                              },
                            ];
                            return <PrimaryCard
                              category="Arxiv"
                              data={filteredData[0]}
                            />
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}

                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : (
                value === 3 && (
                  <TabPanel value={value} index={3}>
                    {showLoading ? (
                      <Loading />
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                              md: "repeat(3, 1fr)",
                            },
                            gap: { xs: "24px", lg: "32px" },
                          }}
                        >
                          {
                            // [...arxivData?.latest, ...arxivData?.monthly, ...arxivData?.weekly]
                            arxivData?.all
                              ?.filter((value, index, self) => {
                                const firstIndex = self.findIndex(
                                  (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                                );
                                return index === firstIndex;
                              })
                              .slice()
                              ?.sort((a, b) =>
                                sortBy === "Score" ? b.properties.Score.number - a.properties.Score.number :
                                  sortBy === "Date Retrived" && new Date(b.properties["Date Retrieved"].date.start) - new Date(a.properties["Date Retrieved"].date.start)
                              )
                              ?.map((data, index) => {
                                const author =
                                  data.properties.Author.rich_text[0]?.plain_text;
                                const {
                                  id,
                                  cover,
                                  properties: {
                                    Title: {
                                      title: [
                                        {
                                          text: { content },
                                        },
                                      ],
                                    },
                                    Description: {
                                      rich_text: [plain_text],
                                    },
                                    "Date Retrieved": {
                                      date: { start },
                                    },
                                    URL: { url },
                                    Score: { number },
                                  },
                                } = data;

                                const filteredData = [
                                  {
                                    id: id,
                                    image: cover,
                                    title: content,
                                    description: plain_text.text.content,
                                    date: start,
                                    author: author,
                                    url: url,
                                    score: number,
                                  },
                                ];
                                return <PrimaryCard
                                  category="Arxiv"
                                  data={filteredData[0]}
                                />
                              })}
                        </Box>
                        {
                          paginationLoading && (
                            <Loading />
                          )
                        }
                        <Box
                          onClick={() => { activeAllViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}

                          sx={{
                            paddingY: "13px",
                            bgcolor: "#1E1F2E",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "14px",
                            width: "100%",
                            maxWidth: { xs: "100%", sm: "170px" },
                            marginTop: "40px",
                            marginBottom: "16px",
                            border: "0.876712px solid #373A50",
                            opacity: activeAllViewMore ? 1 : 0.1,
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            color="white"
                            fontWeight={600}
                            fontSize="12px"
                          >
                            VIEW MORE
                          </Typography>
                        </Box>
                      </>
                    )}
                  </TabPanel>
                ))}
            </Box>
            {/* End Tabs section */}
          </Box>

          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
              fontWeight={500}
            >
              Monthly Topics
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                },
                gap: { xs: "24px", lg: "32px" },
                marginBottom: "75px",
                width:"100%"
              }}
            >
        
                    {arxivData?.monthly.map((data, index) => {
                      const {
                        id,
                        cover,
                        properties: {
                          Title: {
                            title: [
                              {
                                text: { content },
                              },
                            ],
                          },
                          Description: {
                            rich_text: [plain_text],
                          },
                          "Date Retrieved": {
                            date: { start },
                          },
                        },
                      } = data;

                      const filteredData = [
                        {
                          id: id,
                          image: cover,
                          title: content,
                          description: plain_text.text.content,
                          date: start,
                        },
                      ];
                      return index < 3 ? (
                        <PrimaryCard category="Arxiv" data={filteredData[0]} />
                      ) : (
                        <></>
                      );
                    })}
              

              {aiData.length > 3 ? (
                <Box
                  sx={{
                    paddingY: "13px",
                    bgcolor: "#1E1F2E",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "14px",
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "170px" },
                    marginTop: "40px",
                    marginBottom: "16px",
                    border: "0.876712px solid #373A50",
                  }}
                >
                  <Typography color="white" fontWeight={600} fontSize="12px">
                    VIEW MORE
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          
          </Box> */}

          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "32px", sm: "40px", lg: "48px" } }}
              fontWeight={500}
            >
              Cover Papers
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                },
                gap: { xs: "24px", lg: "32px" },
                marginTop: "46px",
              }}
            >
              {shuffle(aiData).map((data, index) => {
                return index < 3 ? <CoverCard data={data} /> : <></>;
              })}
            </Box>
            <Box
              sx={{
                paddingY: "13px",
                bgcolor: "#1E1F2E",
                // bgcolor: "primary.main",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "14px",
                width: "100%",
                maxWidth: { xs: "100%", sm: "170px" },
                marginTop: "40px",
                marginBottom: "75px",
                border: "0.876712px solid #373A50",
              }}
            >
              <Typography color="white" fontWeight={600} fontSize="12px">
                VIEW MORE
              </Typography>
            </Box>
          </Box> */}
        </Box>
      </MainContainer>
    </Box>
  );
};

export default ArXiv;
