import { Box } from '@mui/material'
import React from 'react'
import Loading from './lottie/Loading'
import logo from ".././../../src/assets/Icons/logo.svg";
const LoadingDefault = () => {
  return (
    <Box
        sx={{
          marginTop: "15%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ width: "56px", height: "56px" }} />
        <Box>
          <Loading minHeight="100px" />
        </Box>
      </Box>
  )
}

export default LoadingDefault