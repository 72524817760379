import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import MainContainer from "../../components/MainContainer";
import PrimaryCard from "../../components/PrimaryCard";
import SSRNLogo from "../../assets/ssrn.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getDataFromDatabase } from "../../components/_shared/ApiRequests";
import Loading from "../../components/_shared/lottie/Loading";
import { useContext } from "react";
import { GlobalState, getDataFromIndexedDB, storeDataInIndexedDB } from "../../App";
import Dropdown from "../../components/Dropdown";
import { Helmet } from "react-helmet";
import { destuctureData } from "../../utils/DataDestructure";
import { DB_KEY } from "../../utils/extension";

const Linkedin = () => {

  const { isDarkMode, LinkedinPeople } = useContext(GlobalState);

  // data sort by handling state
  const [sortBy, setSortBy] = useState("Likes")

  const [value, setValue] = React.useState(0);
  const [linkedinData, setLinkedinData] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [activePageNumber, setActivePageNumber] = useState(1);

  // view more handling states
  const [activeLatestViewMore, setActiveLatestViewMore] = useState(true);
  const [activeWeeklyViewMore, setActiveWeeklyViewMore] = useState(true);
  const [activeMonthlyViewMore, setActiveMonthlyViewMore] = useState(true);
  const [activeAllViewMore, setActiveAllViewMore] = useState(true);

  const [paginationLoading, setPaginationLoading] = useState(false);

  const [showLoading, setShowLoading] = useState(true);


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ display: "flex" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (activePageNumber === 1) {
      getCachedData().then((data => {
        const rData = data; 
        const retrievedData = rData.data
        if (retrievedData) {
          setDataAndStopLoading(retrievedData)
        }
      }))
    }
    getDataFromDatabase("linkedin", activePageNumber).then(async(listData) => {
      await storeDataInIndexedDB(DB_KEY.LINKEDIN, listData)
      const retrievedData = await getDataFromIndexedDB(DB_KEY.LINKEDIN);
      const list = retrievedData.data
      if (list) {
        if (list?.latest?.length === 0) {
          setActiveLatestViewMore(false);
        }
        if (list?.weekly?.length === 0) {
          setActiveWeeklyViewMore(false);
        }
        if (list?.monthly?.length === 0) {
          setActiveMonthlyViewMore(false);
        } if (list?.all?.length === 0) {
          setActiveAllViewMore(false);
        }

        setDataAndStopLoading(list)
      }
    });
  }, [activePageNumber]);

  const getCachedData = async () => {
    const data = await getDataFromIndexedDB(DB_KEY.LINKEDIN);
    return data;
  }

  const setDataAndStopLoading = (data) => {
    setDataList(data);
    setShowLoading(false);
    setPaginationLoading(false);
  }
  useEffect(() => {
    if (dataList.length !== 0) {

      setLinkedinData((prevList) => {
        if (prevList?.length !== 0) {
          // Spread the previous list and append the new data
          // FILTER PREVIOUS LIST
          let prevLatestList = prevList?.latest?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let prevMonthlyList = prevList?.monthly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let prevWeeklyList = prevList?.weekly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let prevAllList = prevList?.all?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          // FILTER DATA LIST
          let dataListLatest = dataList?.latest?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListMonthly = dataList?.monthly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListWeekly = dataList?.weekly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListAll = dataList?.all?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          return {
            latest: [...prevLatestList, ...dataListLatest],
            monthly: [...prevMonthlyList, ...dataListMonthly],
            weekly: [...prevWeeklyList, ...dataListWeekly],
            all: [...prevAllList, ...dataListAll]
          };
        } else {
          // If prevList is undefined or empty, set dataList directly
          // FILTER DATA LIST
          let dataListLatest = dataList?.latest?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListMonthly = dataList?.monthly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListWeekly = dataList?.weekly?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          let dataListAll = dataList?.all?.filter((item) => {
            const name = item?.properties?.Name?.title[0]?.text?.content;
            const containsForbiddenName = LinkedinPeople.some(word => word === name?.toString()?.toLowerCase());
            return !containsForbiddenName;
          })

          return {
            latest: dataListLatest,
            monthly: dataListMonthly,
            weekly: dataListWeekly,
            all: dataListAll
          };
        }
      });
    }
  }, [dataList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        bgcolor: "background.main",
        width: "100%",
        color: "text.primary.main",
        paddingTop: "56px",
      }}
    >

      <Helmet>
        <title>ML-Quant - LinkedIn: Explore Professional Insights and Networking</title>
        <meta
          name="description"
          content="Explore ML-Quant's LinkedIn Data List, a hub of professional insights and networking opportunities. Discover curated profiles, industry connections, and valuable data. Elevate your professional networking experience with our comprehensive collection of LinkedIn data in the fields of technology, finance, and more."
        />
        <meta
          name="keywords"
          content="LinkedIn Data, ML-Quant Professional Insights, Networking Opportunities, Industry Connections, Data Analytics, Technology Networking, Finance Professionals, ML-Quant LinkedIn, Career Development, Business Networking"
        />
      </Helmet>

      <MainContainer>
        <Box
          sx={{
            width: "100%",
            color: "text.primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "40px", md: "56px" },
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "42px",
              }}
            >
              <Box sx={{
                display: "flex", width: "100%",
                justifyContent: "space-between", flexWrap: "wrap", flexDirection: { xs: "column", md: "row" },
                alignItems: "center", gap: { xs: "40px", md: "0px" },
              }}>
                <Box sx={{ minWidth: "168px", display: { xs: "none", md: "flex" }, width: { xs: "0px", md: "auto" }, }}></Box>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    borderRadius: "14px",
                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    maxHeight: { xs: "45px", md: "50px" },
                    display: "flex",
                  }}
                >
                  <Tabs
                    indicatorColor="none"
                    sx={{
                      maxHeight: { xs: "45px", md: "50px" },
                      minHeight: "auto",
                    }}
                    style={{
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 0.876712px solid #373A50",
                      borderRadius: " 14.0274px",
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 0 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 0
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 0 ? "600" : "600",
                      }}
                      label="Latest"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 1 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 1
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 1 ? "600" : "600",
                      }}
                      label="Weekly"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 2 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 2
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 2 ? "600" : "600",
                      }}
                      label="Monthly"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 3 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 3
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 3 ? "600" : "600",
                      }}
                      label="All"
                      {...a11yProps(3)}
                    />
                  </Tabs>

                </Box>

                <Box
                  sx={{ width: { xs: "100%", md: "fit-content" }, display: "flex", justifyContent: "flex-start" }}
                >
                  <Dropdown
                    options={["All", "Date", "ML Score", "Likes"]}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  />
                </Box>
              </Box>
              {value === 0 ? (
                <TabPanel value={value} index={0}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {linkedinData?.latest
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name?.title[0].plain_text === value.properties?.Name?.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) => sortBy.toLowerCase() === "likes" ? b.properties.Likes.number - a.properties.Likes.number :
                            sortBy.toLowerCase() === "date" ? new Date(b.properties.Date.date.start).getTime() - new Date(a.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "ml score" && b.properties['ML Score'].number - a.properties['ML Score'].number
                          )
                          ?.map((data, index) => {

                            var link = data?.properties?.Link?.url;
                            var author =
                              data?.properties?.Name?.title[0]?.plain_text;
                            const {
                              id,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Content: {
                                  rich_text: [plain_text],
                                },
                                Date: {
                                  date: { start },
                                },
                                "Image Link": { url },
                                "ML Score": { number },
                              },
                            } = data;
                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                url: link,
                                author: author,
                                score: number,
                              },
                            ];

                            return (
                              <PrimaryCard
                                category="Linkedin"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeLatestViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          opacity: activeLatestViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {linkedinData?.weekly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name?.title[0].plain_text === value.properties?.Name?.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) => sortBy.toLowerCase() === "likes" ? b.properties.Likes.number - a.properties.Likes.number :
                            sortBy.toLowerCase() === "date" ? new Date(b.properties.Date.date.start).getTime() - new Date(a.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "ml score" && b.properties['ML Score'].number - a.properties['ML Score'].number
                          )
                          ?.map((data, index) => {
                            var link = data?.properties?.Link?.url;
                            var author =
                              data?.properties?.Name?.title[0]?.plain_text;
                            const {
                              id,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Content: {
                                  rich_text: [plain_text],
                                },
                                Date: {
                                  date: { start },
                                },
                                "Image Link": { url },
                                "ML Score": { number },
                              },
                            } = data;
                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                url: link,
                                author: author,
                                score: number,
                              },
                            ];

                            return (
                              <PrimaryCard
                                category="Linkedin"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeWeeklyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          opacity: activeWeeklyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {linkedinData?.monthly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Name?.title[0].plain_text === value.properties?.Name?.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) => sortBy.toLowerCase() === "likes" ? b.properties.Likes.number - a.properties.Likes.number :
                            sortBy.toLowerCase() === "date" ? new Date(b.properties.Date.date.start).getTime() - new Date(a.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "ml score" && b.properties['ML Score'].number - a.properties['ML Score'].number
                          )
                          ?.map((data, index) => {
                            var link = data?.properties?.Link?.url;
                            var author =
                              data?.properties?.Name?.title[0]?.plain_text;
                            const {
                              id,
                              properties: {
                                Name: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Content: {
                                  rich_text: [plain_text],
                                },
                                Date: {
                                  date: { start },
                                },
                                "Image Link": { url },
                                "ML Score": { number },
                              },
                            } = data;
                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                url: link,
                                author: author,
                                score: number,
                              },
                            ];
                            return (
                              <PrimaryCard
                                category="Linkedin"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 3 ? (
                <TabPanel value={value} index={3}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {
                          linkedinData?.all
                            // [...linkedinData?.latest, ...linkedinData?.weekly, ...linkedinData?.monthly]
                            ?.filter((value, index, self) => {
                              const firstIndex = self.findIndex(
                                (other) => other?.properties?.Name?.title[0]?.plain_text === value?.properties?.Name?.title[0]?.plain_text
                              );
                              return index === firstIndex;
                            })
                            .slice()
                            ?.sort((a, b) => sortBy.toLowerCase() === "likes" ? b.properties.Likes.number - a.properties.Likes.number :
                              sortBy.toLowerCase() === "date" ? new Date(b.properties.Date.date.start).getTime() - new Date(a.properties.Date.date.start).getTime() :
                                sortBy.toLowerCase() === "ml score" && b.properties['ML Score'].number - a.properties['ML Score'].number
                            )
                            ?.map((data, index) => {
                              const item = destuctureData(data, "linkedin");
                              return (
                                <PrimaryCard
                                  category="Linkedin"
                                  data={item}
                                />
                              )
                            })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeAllViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          opacity: activeAllViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : <></>}
            </Box>
            {/* End Tabs section */}
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Linkedin;
