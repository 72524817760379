import { Typography, Box } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PrimaryCard from "../../components/PrimaryCard";
import { getDataFromDatabase } from "../../components/_shared/ApiRequests";
import Loading from "../../components/_shared/lottie/Loading";
import { GlobalState } from "../../App";
import Dropdown from "../../components/Dropdown";
import { destuctureData } from "../../utils/DataDestructure";
import { Helmet } from "react-helmet";

const Repec = () => {

  const { isDarkMode } = useContext(GlobalState);

  const [value, setValue] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  // const [valueMonthlyTopics, setValueMonthlyTopics] = useState(0);

  const [sortBy, setSortBy] = useState("Rank")

  const [repecData, setRepecData] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [activePageNumber, setActivePageNumber] = useState(1);

  // view more handling states
  const [activeLatestViewMore, setActiveLatestViewMore] = useState(true);
  const [activeWeeklyViewMore, setActiveWeeklyViewMore] = useState(true);
  const [activeMonthlyViewMore, setActiveMonthlyViewMore] = useState(true);
  const [activeAllViewMore, setActiveAllViewMore] = useState(true);

  const [paginationLoading, setPaginationLoading] = useState(false);


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ display: "flex" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    getDataFromDatabase("repec", activePageNumber).then((list) => {
      if (list) {
        // setArxivData(list);
        if (list?.latest?.length === 0) {
          setActiveLatestViewMore(false);
        }
        if (list?.weekly?.length === 0) {
          setActiveWeeklyViewMore(false);
        }
        if (list?.monthly?.length === 0) {
          setActiveMonthlyViewMore(false);
        }
        if (list?.all?.length === 0) {
          setActiveAllViewMore(false);
        }

        setDataList(list);
        setShowLoading(false);
        setPaginationLoading(false);
      }
    });
  }, [activePageNumber]);


  useEffect(() => {
    if (dataList.length !== 0) {

      setRepecData((prevList) => {
        if (prevList?.length !== 0) {
          // Spread the previous list and append the new data
          return {
            latest: [...prevList?.latest, ...dataList?.latest],
            monthly: [...prevList?.monthly, ...dataList?.monthly],
            weekly: [...prevList?.weekly, ...dataList?.weekly],
            all: [...prevList?.all, ...dataList?.all],
          };
        } else {
          // If prevList is undefined or empty, set dataList directly
          return dataList;
        }
      });
    }
  }, [dataList]);

  return (
    <Box
      sx={{
        bgcolor: "background.main",
        width: "100%",
        color: "text.primary.main",
        paddingTop: "56px",
        paddingBottom: "72px",
      }}
    >

      <Helmet>
        <title>ML-Quant - Economics Research Papers: Explore Academic Insights</title>
        <meta
          name="description"
          content="Discover a rich collection of research papers in economics on ML-Quant. Explore academic insights across diverse subjects. Stay informed with thought-provoking research covering a spectrum of economic themes. Your go-to platform for academic publications in the dynamic field of economics."
        />
        <meta
          name="keywords"
          content="Research Papers, Academic Publications, Economics Studies, ML-Quant Research, Economic Themes, Academic Insights, Economic Research, Cross-disciplinary Studies, Interdisciplinary Economics, ML-Quant Papers"
        />
      </Helmet>

      <MainContainer>
        <Box
          sx={{
            width: "100%",
            color: "text.primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "40px", md: "56px" },
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "26px", sm: "40px", lg: "48px" } }}
              fontWeight={500}
            >
              Repec
            </Typography>

            {/* Tabs section */}
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", md: "42px" },
              }}
            >
              <Box sx={{
                display: "flex", width: "100%",
                justifyContent: "space-between", flexWrap: "wrap", flexDirection: { xs: "column", md: "row" },
                alignItems: "center", gap: { xs: "40px", md: "0px" },
                // justifyContent: "center", position:"relative"
              }}>
                <Box sx={{ minWidth: "168px", display: { xs: "none", md: "flex" }, width: { xs: "0px", md: "auto" }, }}></Box>
                <Box
                  sx={{
                    // marginX: "15px",
                    bgcolor: "primary.main",
                    borderRadius: "14px",
                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    maxHeight: { xs: "45px", md: "50px" },
                    display: "flex",
                  }}
                >
                  <Tabs
                    indicatorColor="none"
                    sx={{
                      maxHeight: { xs: "45px", md: "50px" },
                      minHeight: "auto",
                    }}
                    style={{
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 0.876712px solid #373A50",
                      borderRadius: " 14.0274px",
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 0 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 0
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 0 ? "600" : "600",
                      }}
                      label="Latest"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 1 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 1
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 1 ? "600" : "600",
                      }}
                      label="Weekly"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 2 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 2
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 2 ? "600" : "600",
                      }}
                      label="Monthly"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 3 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 3
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 3 ? "600" : "600",
                      }}
                      label="All"
                      {...a11yProps(3)}
                    />
                  </Tabs>

                </Box>

                <Box
                  sx={{ width: { xs: "100%", md: "fit-content" }, display: "flex", justifyContent: "flex-start" }}
                >
                  <Dropdown
                    options={["All", "Rank", "Latest"]}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  />
                </Box>
              </Box>

              {value === 0 ? (
                <TabPanel value={value} index={0}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        key={0}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {repecData?.latest
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Title.title[0].plain_text === value.properties?.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Rank" ? b.properties.Rank.number - a.properties.Rank.number :
                              sortBy === "Latest" && new Date(b.created_time) - new Date(a.created_time)
                          )
                          ?.map((data, index) => {
                            const filteredData = destuctureData(data, "repec")
                            return (
                              <PrimaryCard
                                category="Repec"
                                data={filteredData}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeLatestViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeLatestViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {repecData?.weekly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Title.title[0].plain_text === value.properties?.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Rank" ? b.properties.Rank.number - a.properties.Rank.number :
                              sortBy === "Latest" && new Date(b.created_time) - new Date(a.created_time)
                          )
                          ?.map((data, index) => {
                            const filteredData = destuctureData(data, "repec")
                            return (
                              <PrimaryCard
                                category="Repec"
                                data={filteredData}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeWeeklyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}

                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          // bgcolor: "primary.main",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeWeeklyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {repecData?.monthly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties?.Title.title[0].plain_text === value.properties?.Title.title[0].plain_text
                            );
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy === "Rank" ? b.properties.Rank.number - a.properties.Rank.number :
                              sortBy === "Latest" && new Date(b.created_time) - new Date(a.created_time)
                          )
                          ?.map((data, index) => {
                            const filteredData = destuctureData(data, "repec")
                            return (
                              <PrimaryCard
                                category="Repec"
                                data={filteredData}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 3 ? (
                <TabPanel value={value} index={3}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {
                          repecData?.all
                            // [...repecData?.latest, ...repecData?.weekly, ...repecData?.monthly]
                            ?.filter((value, index, self) => {
                              const firstIndex = self.findIndex(
                                (other) => other.properties?.Title.title[0].plain_text === value.properties?.Title.title[0].plain_text
                              );
                              return index === firstIndex;
                            })
                            .slice()
                            ?.sort((a, b) =>
                              sortBy === "Rank" ? b.properties.Rank.number - a.properties.Rank.number :
                                sortBy === "Latest" && new Date(b.created_time) - new Date(a.created_time)
                            )
                            ?.map((data, index) => {
                              const filteredData = destuctureData(data, "repec")
                              return (
                                <PrimaryCard
                                  category="Repec"
                                  data={filteredData}
                                />
                              )
                            })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeAllViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "16px",
                          border: "0.876712px solid #373A50",
                          opacity: activeAllViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : <></>}
            </Box>
            {/* End Tabs section */}
          </Box>

        </Box>
      </MainContainer>
    </Box>
  );
};

export default Repec;
