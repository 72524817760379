import { Box } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import NavbarTab from "./NavbarTab";
import { useLocation } from "react-router-dom";
import Button from "./Button";
import ThemeSwitchButton from "./ThemeSwitchButton";
import { useAuthState } from "react-firebase-hooks/auth";
import { GlobalState } from "../App";
import { auth } from "../services/firebase/firebase.config";
import IconSSvg from "../assets/Icons/IconSSvg";
import IconBlogSvg from "../assets/Icons/IconBlogSvg";
import IconASvg from "../assets/Icons/IconASvg";

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState("/");
  const { isDarkMode } = useContext(GlobalState);
  const location = useLocation();
  const [user, loading] = useAuthState(auth);


  const listItems = [
    {
      redirectTo: "/",
      // iconName: "home",
      iconSize: "24",
      title: "Home",
    },
    {
      redirectTo: "arxiv",
      // iconName: "activity",
      iconSize: "24",
      title: "Arxiv",
      // icon:<IconASvg
      //   color={activeTab === 'arxiv' ? "#3367B3" : "#9C9C9C"}
      // />
    },
    {
      redirectTo: "ssrn",
      // iconName: "activity",
      iconSize: "24",
      title: "SSRN",
      // icon: <IconSSvg
      //   color={activeTab === 'ssrn' ? "#3367B3" : "#9C9C9C"}
      // />
    },
    {
      redirectTo: "blogs",
      // iconName: "activity",
      iconSize: "24",
      title: "Blogs",
      // icon:<IconBlogSvg
      //   color={activeTab === 'blogs' ? "#3367B3" : "#9C9C9C"}
      // />
    },
    {
      redirectTo: "github",
      // iconName: "github",
      iconSize: "24",
      title: "Github"
    },
    {
      redirectTo: "jobs",
      // iconName: "activity",
      iconSize: "24",
      title: "Jobs"
    },
    {
      redirectTo: "repec",
      // iconName: "activity",
      iconSize: "24",
      title: "Repec"
    },
    {
      redirectTo: "podcasts",
      // iconName: "activity",
      iconSize: "24",
      title: "Podcasts"
    },
    {
      redirectTo: "videos",
      // iconName: "activity",
      iconSize: "24",
      title: "Videos"
    },
    {
      redirectTo: "pwc",
      // iconName: "activity",
      iconSize: "24",
      title: "PWC"
    },
    {
      redirectTo: "news",
      // iconName: "activity",
      iconSize: "24",
      title: "News"
    },
    {
      redirectTo: "linkedin",
      // iconName: "activity",
      iconSize: "24",
      title: "Linkedin"
    },
    {
      redirectTo: "reddit",
      // iconName: "activity",
      iconSize: "24",
      title: "Reddit"
    },
  ]

  useEffect(() => {
    const currentUrlPath = location.pathname.split("/")[1];
    if (currentUrlPath !== "") {
      setActiveTab(currentUrlPath);
    } else {
      setActiveTab("home");
    }
  }, [location]);

  function close_sidebar() {
    document.querySelector("body").style.overflow = "scroll";
    document.getElementById("mySidenav").style.display = "none";
  }

  return (
    <Box
      sx={{
        display: "none",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        // right: "0px",
        top: "0px",
        left: "0px",
        right: "0px",
        height: "100vh",
        zIndex: 1000,
        width: "100% !important",
        // border:"1px solid red",
        // marginTop:"150px"
      }}
      id="mySidenav"
    >
      <Box
        onClick={() => close_sidebar()}
        sx={{
          width: "65%",
          backgroundColor: "transparent",
          height: "100%",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          width: "300px",
          paddingTop: "24px",
          paddingBottom: "24px",
          paddingRight: "24px",
          paddingLeft: "10px",
          gap: "24px",
          backgroundColor: "primary.main",
        }}
      >
        <button
          style={{
            fontSize: "32px",
            cursor: "pointer",
            backgroundColor: "transparent",
            color: isDarkMode ? "white" : "black",
            border: "none",
            marginRight: "5px",
          }}
          onClick={() => {
            close_sidebar();
          }}
        >
          &times;
        </button>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "100%",
            overflowY: "scroll",
            gap: "50px",
            maxHeight: "80vh"
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              maxHeight: "80%",
              overflowY: "scroll"

            }}
          >
            {
              listItems?.map((item, index) => {
                return (
                  <NavbarTab
                    redirectTo={item?.redirectTo}
                    iconName={item?.iconName}
                    iconSize="24"
                    activeTab={activeTab}
                    title={item?.title}
                    icon={item?.icon}
                  />
                )
              })
            }
            {/* <NavbarTab
              redirectTo="/"
              iconName="home"
              iconSize="24"
              activeTab={activeTab}
              title="Home"
            /> */}
            {/* <NavbarTab
              redirectTo="arxiv"
              iconName="activity"
              iconSize="24"
              activeTab={activeTab}
              title="Arxiv"
            />
            <NavbarTab
              redirectTo="ssrn"
              iconName="activity"
              iconSize="24"
              activeTab={activeTab}
              title="SSRN"
            />
            <NavbarTab
              redirectTo="blogs"
              iconName="activity"
              iconSize="24"
              activeTab={activeTab}
              title="Blogs"
            />
            <NavbarTab
              redirectTo="github"
              iconName="github"
              iconSize="24"
              activeTab={activeTab}
              title="Github"
            /> */}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "14px",
              alignItems: "center",
              gap: { xs: "8px", sm: "15px" },
              // height:"100px"
            }}
          >
            {!loading && !(user) && (
              <Button
                textStyles={{ fontSize: { xs: "13px", md: "14px", lg: "16px" } }}
                button_class="nav_login_btn"
                text="Login"
              />
            )}
            <ThemeSwitchButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
