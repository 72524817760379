import React, { useContext } from 'react';
import { Box } from '@mui/material'
import Button from '../../../components/Button'
import { GlobalState } from '../../../App';
const PriceDiv = ({amount=35, per='/month'}) => {
    const { isDarkMode } = useContext(GlobalState);
    return (
        <Box display="flex" alignItems="center" sx={{ height: amount===580?'70px':'94px' }}>
            <Box
                component="div"
                style={{
                    color: isDarkMode ? '#FFF' : 'black',
                    fontFamily: 'Poppins',
                    fontSize: '26px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    height: '100%'
                }}
            >
                $
            </Box>
            <Box
                component="div"
                style={{
                    color: isDarkMode ? '#FFF' : 'black',
                    fontFamily: 'Poppins',
                    fontSize: '56px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    textAlign: 'center',
                    height: '100%'
                }}
            >{amount}
            </Box>
            <Box
                component="div"
                style={{
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.50)' : 'rgba(0, 0, 0, 0.5)',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    height: amount===580?'0%':'50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
            >
                {per}
            </Box>
            
        </Box>
    )
}

export default PriceDiv