import React from 'react'
import cn from "classnames";

const icons = {
  home: `M6.63478 18.7733V15.7156C6.63478 14.9351 7.27217 14.3023 8.05844 14.3023H10.9326C11.3102 14.3023 11.6723 14.4512 11.9393 14.7163C12.2063
   14.9813 12.3563 15.3408 12.3563 15.7156V18.7733C12.3539 19.0978 12.4821 19.4099 12.7124 19.6402C12.9427 19.8705 13.2561 20 13.5829 20H15.5438C16.4596
    20.0023 17.3388 19.6428 17.9872 19.0008C18.6356 18.3588 19 17.487 19 16.5778V7.86686C19 7.13246 18.6721 6.43584 18.1046 5.96467L11.434 0.675869C10.2737
    -0.251438 8.61111 -0.221498 7.48539 0.746979L0.967012 5.96467C0.372741 6.42195 0.0175523 7.12064 0 7.86686V16.5689C0 18.4639 1.54738 20 3.45617
    20H5.37229C6.05123 20 6.603 19.4562 6.60792 18.7822L6.63478 18.7733Z`,

  heart: `M2.3237 9.123C3.7257 13.485 9.2647 17.012 10.7367 17.885C12.2137 17.003 17.7927 13.437 19.1497 9.127C20.0407 6.341 19.2137 2.812 15.9277
   1.753C14.3357 1.242 12.4787 1.553 11.1967 2.545C10.9287 2.751 10.5567 2.755 10.2867 2.551C8.9287 1.53 7.1547 1.231 5.5377 1.753C2.2567 2.811
  1.4327 6.34 2.3237 9.123ZM10.7377 19.501C10.6137 19.501 10.4907 19.471 10.3787 19.41C10.0657 19.239 2.6927 15.175 0.895701 9.581C0.894701
  9.581 0.894701 9.58 0.894701 9.58C-0.233299 6.058 1.0227 1.632 5.0777 0.324996C6.9817 -0.291004 9.0567 -0.020004 10.7347 1.039C12.3607 0.010996
  14.5207 -0.273004 16.3867 0.324996C20.4457 1.634 21.7057 6.059 20.5787 9.58C18.8397 15.11 11.4127 19.235 11.0977 19.408C10.9857 19.47 10.8617
  19.501 10.7377 19.501Z M16.6537 7.6249C16.2667 7.6249 15.9387 7.3279 15.9067 6.9359C15.8407 6.1139 15.2907 5.4199 14.5077 5.1669C14.1127 5.0389
  13.8967 4.6159 14.0237 4.2229C14.1527 3.8289 14.5717 3.6149 14.9677 3.7389C16.3307 4.1799 17.2857 5.3869 17.4027 6.8139C17.4357 7.2269 17.1287
  7.5889 16.7157 7.6219C16.6947 7.6239 16.6747 7.6249 16.6537 7.6249Z`,

  setting: `M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12
   9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z M15.21
  22.19C15 22.19 14.79 22.16 14.58 22.11C13.96 21.94 13.44 21.55 13.11 21L12.99 20.8C12.4 19.78 11.59 19.78
    11 20.8L10.89 20.99C10.56 21.55 10.04 21.95 9.42 22.11C8.79 22.28 8.14 22.19 7.59 21.86L5.87 20.87C5.26 20.52 4.82 19.95 4.63 19.26C4.45
    18.57 4.54 17.86 4.89 17.25C5.18 16.74 5.26 16.28 5.09 15.99C4.92 15.7 4.49 15.53 3.9 15.53C2.44 15.53 1.25 14.34 1.25 12.88V11.12C1.25
      9.66004 2.44 8.47004 3.9 8.47004C4.49 8.47004 4.92 8.30004 5.09 8.01004C5.26 7.72004 5.19 7.26004 4.89 6.75004C4.54 6.14004 4.45 5.42004
      4.63 4.74004C4.81 4.05004 5.25 3.48004 5.87 3.13004L7.6 2.14004C8.73 1.47004 10.22 1.86004 10.9 3.01004L11.02 3.21004C11.61 4.23004 12.42
      4.23004 13.01 3.21004L13.12 3.02004C13.8 1.86004 15.29 1.47004 16.43 2.15004L18.15 3.14004C18.76 3.49004 19.2 4.06004 19.39 4.75004C19.57
      5.44004 19.48 6.15004 19.13 6.76004C18.84 7.27004 18.76 7.73004 18.93 8.02004C19.1 8.31004 19.53 8.48004 20.12 8.48004C21.58 8.48004
      22.77 9.67004 22.77 11.13V12.89C22.77 14.35 21.58 15.54 20.12 15.54C19.53 15.54 19.1 15.71 18.93 16C18.76 16.29 18.83 16.75 19.13
      17.26C19.48 17.87 19.58 18.59 19.39 19.27C19.21 19.96 18.77 20.53 18.15 20.88L16.42 21.87C16.04 22.08 15.63 22.19 15.21 22.19ZM12
      18.49C12.89 18.49 13.72 19.05 14.29 20.04L14.4 20.23C14.52 20.44 14.72 20.59 14.96 20.65C15.2 20.71 15.44 20.68 15.64 20.56L17.37
      19.56C17.63 19.41 17.83 19.16 17.91 18.86C17.99 18.56 17.95 18.25 17.8 17.99C17.23 17.01 17.16 16 17.6 15.23C18.04 14.46 18.95
      14.02 20.09 14.02C20.73 14.02 21.24 13.51 21.24 12.87V11.11C21.24 10.48 20.73 9.96004 20.09 9.96004C18.95 9.96004
      18.04 9.52004 17.6 8.75004C17.16 7.98004 17.23 6.97004 17.8 5.99004C17.95 5.73004 17.99 5.42004 17.91 5.12004C17.83 4.82004 17.64 4.58004
      17.38 4.42004L15.65 3.43004C15.22 3.17004 14.65 3.32004 14.39 3.76004L14.28 3.95004C13.71 4.94004 12.88 5.50004 11.99 5.50004C11.1 5.50004 10.27
      4.94004 9.7 3.95004L9.59 3.75004C9.34 3.33004 8.78 3.18004 8.35 3.43004L6.62 4.43004C6.36 4.58004 6.16 4.83004 6.08 5.13004C6 5.43004 6.04 5.74004
      6.19 6.00004C6.76 6.98004 6.83 7.99004 6.39 8.76004C5.95 9.53004 5.04 9.97004 3.9 9.97004C3.26 9.97004 2.75 10.48 2.75 11.12V12.88C2.75 13.51 3.26 14.03 3.9
      14.03C5.04 14.03 5.95 14.47 6.39 15.24C6.83 16.01 6.76 17.02 6.19 18C6.04 18.26 6 18.57 6.08 18.87C6.16 19.17 6.35 19.41 6.61 19.57L8.34 20.56C8.55
      20.69 8.8 20.72 9.03 20.66C9.27 20.6 9.47 20.44 9.6 20.23L9.71 20.04C10.28 19.06 11.11 18.49 12 18.49Z`,

  activity: `M5.71629 14.0029C5.55629 14.0029 5.39529 13.9519 5.25929 13.8479C4.93129 13.5949 4.86929 13.1239 5.12229 12.7959L8.11529 8.90587C8.23729 8.74687 8.41829
   8.64387 8.61629 8.61887C8.81829 8.59287 9.01629 8.64887 9.17329 8.77387L11.9933 10.9889L14.4603 7.80587C14.7143 7.47687 15.1843 7.41587 15.5123 7.67187C15.8403
    7.92587 15.9003 8.39687 15.6463 8.72387L12.7163 12.5039C12.5943 12.6619 12.4143 12.7649 12.2163 12.7889C12.0163 12.8159 11.8183 12.7579 11.6603 12.6349L8.84229
     10.4209L6.31129 13.7099C6.16329 13.9019 5.94129 14.0029 5.71629 14.0029Z
  M18.4673 1.5C17.8213 1.5 17.2953 2.025 17.2953 2.672C17.2953 3.318 17.8213 3.845 18.4673 3.845C19.1133 3.845 19.6393 3.318 19.6393 2.672C19.6393 2.025 19.1133
   1.5 18.4673 1.5ZM18.4673 5.345C16.9943 5.345 15.7953 4.146 15.7953 2.672C15.7953 1.198 16.9943 0 18.4673 0C19.9413 0 21.1393 1.198 21.1393 2.672C21.1393 4.146
    19.9413 5.345 18.4673 5.345Z
  M14.7329 20.7032H6.12891C2.76191 20.7032 0.499908 18.3382 0.499908 14.8182V6.73616C0.499908 3.21116 2.76191 0.842163 6.12891 0.842163H13.3969C13.8109 0.842163
   14.1469 1.17816 14.1469 1.59216C14.1469 2.00616 13.8109 2.34216 13.3969 2.34216H6.12891C3.62091 2.34216 1.99991 4.06616 1.99991 6.73616V14.8182C1.99991 17.5232
    3.58191 19.2032 6.12891 19.2032H14.7329C17.2409 19.2032 18.8619 17.4822 18.8619 14.8182V7.77916C18.8619 7.36516 19.1979 7.02916 19.6119 7.02916C20.0259 7.02916
     20.3619 7.36516 20.3619 7.77916V14.8182C20.3619 18.3382 18.0999 20.7032 14.7329 20.7032Z`,

  user: `M16.1583 8.23285C16.1583 10.5825 14.2851 12.4666 11.949 12.4666C9.61292 12.4666 7.73974 10.5825 
  7.73974 8.23285C7.73974 5.88227 9.61292 4 11.949 4C14.2851 4 16.1583 5.88227 16.1583 8.23285ZM11.949 
  20C8.51785 20 5.58809 19.456 5.58809 17.2802C5.58809 15.1034 8.49904 14.5396 11.949 14.5396C15.3802 
  14.5396 18.31 15.0836 18.31 17.2604C18.31 19.4362 15.399 20 11.949 20ZM17.9571 8.30922C17.9571 9.50703 
  17.5998 10.6229 16.973 11.5505C16.9086 11.646 16.9659 11.7748 17.0796 11.7946C17.2363 11.8216 17.3984 
  11.8369 17.5631 11.8414C19.2062 11.8846 20.6809 10.821 21.0883 9.21974C21.6918 6.84123 19.9198 4.7059 
  17.6634 4.7059C17.4181 4.7059 17.1835 4.73201 16.9551 4.77884C16.9238 4.78605 16.8907 4.80046 16.8728 
  4.82838C16.8513 4.8626 16.8674 4.90853 16.8889 4.93825C17.5667 5.8938 17.9571 7.05918 17.9571 8.30922ZM20.6782 
  13.5126C21.7823 13.7296 22.5084 14.1727 22.8093 14.8166C23.0636 15.3453 23.0636 15.9586 22.8093 16.4864C22.349 
  17.4851 20.8654 17.8058 20.2887 17.8886C20.1696 17.9066 20.0738 17.8031 20.0864 17.6833C20.3809 14.9157 18.0377 
  13.6035 17.4315 13.3018C17.4055 13.2883 17.4002 13.2676 17.4028 13.255C17.4046 13.246 17.4154 13.2316 17.4351 
  13.2289C18.7468 13.2046 20.1571 13.3847 20.6782 13.5126ZM6.43711 11.8413C6.60186 11.8368 6.76304 11.8224 6.92063 
  11.7945C7.03434 11.7747 7.09165 11.6459 7.02718 11.5504C6.4004 10.6228 6.04313 9.50694 6.04313 8.30913C6.04313 
  7.05909 6.43353 5.89371 7.11135 4.93816C7.13284 4.90844 7.14806 4.86251 7.12746 4.82829C7.10956 4.80127 7.07553 
  4.78596 7.04509 4.77875C6.81586 4.73192 6.58127 4.70581 6.33593 4.70581C4.07951 4.70581 2.30751 6.84114 2.91191 
  9.21965C3.31932 10.8209 4.79405 11.8845 6.43711 11.8413ZM6.59694 13.2545C6.59962 13.268 6.59425 13.2878 6.56918 
  13.3022C5.9621 13.6039 3.61883 14.9161 3.91342 17.6827C3.92595 17.8034 3.83104 17.9061 3.71195 17.889C3.13531 
  17.8061 1.65163 17.4855 1.19139 16.4867C0.936203 15.9581 0.936203 15.3457 1.19139 14.817C1.49225 14.1731 2.21752 
  13.73 3.32156 13.512C3.84358 13.385 5.25294 13.2049 6.5656 13.2292C6.5853 13.2319 6.59515 13.2464 6.59694 13.2545Z`,

  star: `M11.7499 4.5C11.6589 4.5 11.4349 4.525 11.3159 4.763L9.48992 8.414C9.20092 8.991 8.64392 9.392 7.99992 9.484L3.91192
   10.073C3.64192 10.112 3.54992 10.312 3.52192 10.396C3.49692 10.477 3.45692 10.683 3.64292 10.861L6.59892 13.701C7.06992 
   14.154 7.28392 14.807 7.17192 15.446L6.47592 19.456C6.43292 19.707 6.58992 19.853 6.65992 19.903C6.73392 
   19.959 6.93192 20.07 7.17692 19.942L10.8319 18.047C11.4079 17.75 12.0939 17.75 12.6679 18.047L16.3219 
   19.941C16.5679 20.068 16.7659 19.957 16.8409 19.903C16.9109 19.853 17.0679 19.707 17.0249 19.456L16.3269 
   15.446C16.2149 14.807 16.4289 14.154 16.8999 13.701L19.8559 10.861C20.0429 10.683 20.0029 10.476 19.9769 
   10.396C19.9499 10.312 19.8579 10.112 19.5879 10.073L15.4999 9.484C14.8569 9.392 14.2999 8.991 14.0109 
   8.413L12.1829 4.763C12.0649 4.525 11.8409 4.5 11.7499 4.5ZM6.94692 21.5C6.53392 21.5 6.12392 21.37 5.77292 
   21.114C5.16692 20.67 4.86992 19.937 4.99892 19.199L5.69492 15.189C5.72092 15.04 5.66992 14.889 5.55992 
   14.783L2.60392 11.943C2.05992 11.422 1.86492 10.652 2.09492 9.937C2.32692 9.214 2.94092 8.697 
   3.69792 8.589L7.78592 8C7.94392 7.978 8.07992 7.881 8.14792 7.743L9.97492 4.091C10.3119 3.418 10.9919 3 11.7499 
   3C12.5079 3 13.1879 3.418 13.5249 4.091L15.3529 7.742C15.4219 7.881 15.5569 7.978 15.7139 8L19.8019 8.589C20.5589
    8.697 21.1729 9.214 21.4049 9.937C21.6349 10.652 21.4389 11.422 20.8949 11.943L17.9389 14.783C17.8289 14.889 
    17.7789 15.04 17.8049 15.188L18.5019 19.199C18.6299 19.938 18.3329 20.671 17.7259 21.114C17.1109 21.565 
    16.3099 21.626 15.6309 21.272L11.9779 19.379C11.8349 19.305 11.6639 19.305 11.5209 19.379L7.86792 21.273C7.57592
     21.425 7.26092 21.5 6.94692 21.5Z`,
    github: 'M15.935,22.006c0.005,-0.002 0.009,-0.004 0.014,-0.006c3.982,-1.575 6.801,-5.461 6.801,-10c0,-5.933 -4.817,-10.75 -10.75,-10.75c-5.933,0 -10.75,4.817 -10.75,10.75c0,4.539 2.819,8.424 6.798,10.005c0.005,0.002 0.01,0.004 0.015,0.006c0.384,0.143 0.814,0.089 1.151,-0.145c0.336,-0.234 0.537,-0.619 0.536,-1.029l-0,-2.137c-0,-0.49 0.162,-0.962 0.509,-1.308c0.149,-0.149 0.32,-0.27 0.507,-0.36c0.286,-0.137 0.455,-0.441 0.42,-0.758c-0.035,-0.316 -0.265,-0.576 -0.575,-0.648c-1.986,-0.462 -3.494,-1.891 -3.494,-3.643c-0,-0.731 0.269,-1.41 0.724,-1.984c0.15,-0.189 0.2,-0.439 0.134,-0.671c-0.163,-0.573 -0.157,-1.14 -0.117,-1.544c0.719,0.09 1.19,0.345 1.473,0.581c0.201,0.168 0.475,0.219 0.723,0.135c0.598,-0.204 1.256,-0.317 1.946,-0.317c0.69,0 1.348,0.113 1.946,0.317c0.248,0.084 0.522,0.033 0.723,-0.135c0.283,-0.236 0.754,-0.491 1.473,-0.581c0.04,0.404 0.046,0.971 -0.117,1.544c-0.066,0.232 -0.016,0.482 0.134,0.671c0.455,0.574 0.724,1.253 0.724,1.984c0,1.752 -1.508,3.181 -3.494,3.643c-0.31,0.072 -0.54,0.332 -0.575,0.648c-0.035,0.317 0.134,0.621 0.42,0.758c0.187,0.09 0.358,0.211 0.507,0.36c0.347,0.346 0.509,0.818 0.509,1.308c0,-0 -0,-0 -0,-0c-0,-0 0,1.3 0.005,2.143c-0,0.404 0.199,0.786 0.535,1.018c0.335,0.233 0.762,0.287 1.145,0.145Zm-0.182,-1.55c-0.003,-0.802 -0.003,-1.756 -0.003,-1.756c0,-0.754 -0.229,-1.48 -0.682,-2.067c1.993,-0.908 3.315,-2.669 3.315,-4.65c0,-0.947 -0.297,-1.839 -0.827,-2.615c0.27,-1.346 -0.066,-2.566 -0.066,-2.566c-0.089,-0.324 -0.381,-0.549 -0.716,-0.552c-1.292,-0.012 -2.163,0.342 -2.726,0.712c-0.641,-0.18 -1.33,-0.279 -2.048,-0.279c-0.718,0 -1.407,0.099 -2.049,0.279c-0.563,-0.37 -1.433,-0.724 -2.725,-0.712c-0.335,0.003 -0.627,0.228 -0.716,0.552c0,-0 -0.336,1.22 -0.066,2.568c-0.53,0.774 -0.827,1.666 -0.827,2.613c-0,1.981 1.322,3.742 3.315,4.65c-0.453,0.587 -0.682,1.313 -0.682,2.067l0,1.763c-3.238,-1.444 -5.5,-4.691 -5.5,-8.463c0,-5.105 4.145,-9.25 9.25,-9.25c5.105,0 9.25,4.145 9.25,9.25c0,3.769 -2.259,7.015 -5.497,8.456Z',
   fork: 'M7 5C7 3.89543 7.89543 3 9 3C10.1046 3 11 3.89543 11 5C11 5.74028 10.5978 6.38663 10 6.73244V14.0396H11.7915C12.8961 14.0396 13.7915 13.1441 13.7915 12.0396V10.7838C13.1823 10.4411 12.7708 9.78837 12.7708 9.03955C12.7708 7.93498 13.6662 7.03955 14.7708 7.03955C15.8753 7.03955 16.7708 7.93498 16.7708 9.03955C16.7708 9.77123 16.3778 10.4111 15.7915 10.7598V12.0396C15.7915 14.2487 14.0006 16.0396 11.7915 16.0396H10V17.2676C10.5978 17.6134 11 18.2597 11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 18.2597 7.4022 17.6134 8 17.2676V6.73244C7.4022 6.38663 7 5.74028 7 5Z',
   aLetter: 'M16.186,52.505c0,-0.913 0.295,-2.239 0.766,-3.535l10.49,-28.816c1.561,-4.332 4.184,-6.306 8.396,-6.306c4.449,0 7.043,1.886 8.664,6.306l10.576,28.816c0.502,1.385 0.707,2.387 0.707,3.506c0,3.035 -2.268,5.187 -5.42,5.187c-3.123,0 -4.744,-1.415 -5.688,-4.892l-1.443,-4.715h-14.556l-1.443,4.538c-1.031,3.595 -2.652,5.068 -5.539,5.068c-3.419,0 -5.51,-1.945 -5.51,-5.157zM40.877,39.895l-4.98,-16.383h-0.234l-4.833,16.383z',
};

const Icon = (props) => {
  const size = props.size ? props.size : 16;
  return (
    <svg
      className={cn(props.className)}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={props.style}
      id={props.id}
    >
      <path fill={props.fill} d={icons[props.name]}></path>
    </svg>
  );
};

export default Icon;


