export const destuctureData = (data, category) => {
  if (category.toLowerCase().toString() === "arxiv") {
    const author = data.properties.Author.rich_text[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Title: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        "Date Retrieved": {
          date: { start },
        },
        URL: { url },
        Score: { number },
      },
    } = data;

    const item = {
      id: id,
      image: cover,
      title: content,
      description: plain_text?.text?.content,
      date: start,
      author: author,
      url: url,
      score: number,
    };
    return item;
  }
  if (category.toLowerCase().toString() === "ssrn") {
    const author = data.properties.Author.rich_text[0]?.plain_text;
    const {properties} = data
    const {
      "Abstract Views":views,
    } = properties;
    const {
      id,
      cover,
      properties: {
        Title: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        "Date Retrieved": {
          date: { start },
        },
        URL: { url },
      },
    } = data;

    const item = {
      title: content,
      id: id,
      image: cover,
      description: plain_text?.text?.content,
      date: start,
      url: url,
      author: author,
      views: views?.number
    };
    return item;
  }
  if (category.toLowerCase().toString() === "reddit") {
    const author = data.properties.Author.rich_text[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Title: {
          title: [
            {
              text: { content },
            },
          ],
        },
        "Date Retrieved": {
          date: { start },
        },
        Permalink: { url },
        Score: { number },
      },
    } = data;

    const item = {
      id: id,
      image: cover,
      title: content,
      date: start,
      author: author,
      url: url,
      score: number,
    };
    return item;
  }
  if (category.toLowerCase().toString() === "github") {
    var stars = data.properties.Stars.number;
    var score = data.properties.Score.number;
    const {
      id,
      cover,
      properties: {
        Name: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        "Last Update": {
          date: { start },
        },
        Forks: { number },
        URL: { url },
      },
    } = data;

    const item = {
      id: id,
      image: cover,
      title: content,
      description: plain_text?.text.content,
      date: start,
      stars: stars,
      forks: number,
      url: url,
      score: score,
    };

    return item;
  }
  if (category.toLowerCase().toString() === "pwc") {
    var author = data?.properties?.Name?.title[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Name: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        Retrieved: {
          date: { start },
        },
        "Code Link": { url },
        Stars: { number },
      },
    } = data;

    const item = {
      id: id,
      image: cover,
      title: content,
      description: plain_text?.text?.content,
      date: start,
      url: url,
      author: author,
      rating: number,
    };

    return item;
  }
  if (category.toLowerCase().toString() === "videos") {
    const author = data?.properties?.Name?.rich_text[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Title: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        Date: {
          date: { start },
        },
        Rating: { number },
        URL: { url },
      },
    } = data;

    const item = {
      id: id,
      image: cover || url,
      title: content,
      description: plain_text?.text?.content,
      date: start,
      author: author,
      url: url,
      rating: number,
    };
    return item;
  }
  if (category.toLowerCase().toString() === "blogs") {
    const author = data.properties.Author.rich_text[0]?.plain_text;
    const link = data.properties.URL.url;
    const websiteLink = data?.properties?.Site?.rich_text[0]?.text?.content;
    const date = data?.properties?.Date?.date?.start;
    const {
      id,
      cover,
      properties: {
        Title: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        Image: { url },
        // Date: {
        //   date: { start },
        // },
      },
    } = data;

    const item = {
      id: id,
      image: cover || url,
      title: content,
      description: plain_text?.text?.content,
      date: date,
      author: author,
      url: link,
      websiteLink: websiteLink
    };

    return item;
  }
  if (category.toLowerCase().toString() === "news") {
    const author = data.properties.Author.rich_text[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Name: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Description: {
          rich_text: [plain_text],
        },
        Publication: {
          date: { start },
        },
        Link: { url },
      },
    } = data;
    const item = {
      id: id,
      image: cover,
      title: content,
      description: plain_text?.text?.content,
      date: start,
      author: author,
      url: url,
    };
    return item;
  }
  if (category.toLowerCase().toString() === "linkedin") {
    var link = data?.properties?.Link?.url;
    var author = data?.properties?.Name?.title[0]?.plain_text;
    const {
      id,
      cover,
      properties: {
        Name: {
          title: [
            {
              text: { content },
            },
          ],
        },
        Content: {
          rich_text: [plain_text],
        },
        Date: {
          date: { start },
        },
        "Image Link": { url },
        "ML Score": { number },
      },
    } = data;
    const item = {
      id: id,
      image: cover || url || link,
      title: content,
      description: plain_text?.text?.content,
      date: start,
      url: link,
      author: author,
      score: number,
    };

    return item;
  }
  if (category.toLowerCase().toString() === "jobs") {
    const {
      id,
      cover,
      properties,
    } = data;

    const {
      Date,
      "Apply Link": applyLink,
      "Company Name": companyName,
      "Job Title": jobTitle,
      Location,
      "Logo Link": logoLink,
      Salary,
      Title,
      Skills
    } = properties;

    const item = {
      id: id,
      date: Date?.date?.start,
      url: applyLink?.url,
      companyName: companyName?.rich_text[0]?.plain_text,
      jobTitle: jobTitle?.rich_text[0]?.text?.content,
      location: Location?.rich_text[0]?.text?.content,
      image: cover || logoLink?.url,
      salary: Salary?.number,
      title: Title?.title[0]?.plain_text,
      skills: Skills?.rich_text.map(item => item.text?.content),
    };

    return item;
  }
  if (category.toLowerCase().toString() === "repec") {
    const {
      id,
      properties: {
        "Abstract": {
          rich_text: [{ text: { content: abstract } }],
        },
        "Authors": { rich_text: [{ text: { content: author } }] },
        "Download Link": { url: downloadLink },
        "Keywords": { rich_text: [] },
        "Rank": { number: rank },
        "Search": { rich_text: [{ text: { content: search } }] },
        "Series": { rich_text: [{ text: { content: series } }] },
        "Time Added": { created_time: timeAdded },
        "Title": { title: [{ plain_text: title }] },
        "Total Downloads": { number: totalDownloads },
        "Year Published": { number: yearPublished },
      },
      cover
    } = data;

    // Create the 'item4' object in the desired format
    const item = {
      id: id,
      description: abstract,
      author: author,
      url: downloadLink,
      rank: rank,
      search: search,
      series: series,
      date: timeAdded,
      title: title,
      totalDownloads: totalDownloads,
      yearPublished: yearPublished,
      image: cover
    };

    return item;
  }
  if (category.toLowerCase().toString() === "podcasts") {

    const { id, properties, public_url, cover } = data;
    const { Title, Date } = properties;

    // Create the 'item5' object in the desired format
    const item = {
      id: id,
      title: Title?.title[0]?.plain_text,
      url: public_url,
      date: Date?.date?.start,
      image: cover
    };


    return item;
  }
};
