import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./OutlinedCard.css";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { convertDateFormat } from "../../utils/extension";
import { useState } from "react";

export default function OutlinedCard({ data }) {
  const {
    id,
    cover,
    properties: {
      Name: {
        title: [
          {
            text: { content },
          },
        ],
      },
      "Details/Title": {
        rich_text: [plain_text],
      },
      Date: {
        date: { start },
      },
      Link:{url}
    },
  } = data;

  const [date, setDate] = useState();

  useEffect(() => {
    convertDateFormat(start).then((date) => {
      setDate(date);
    });
  }, [start]);
  return (
    <a style={{textDecoration:"none"}} target="_blank"  href={url} rel="noreferrer">
    <Card
      className="card-container"
      sx={{
        maxWidth: "400px",
        width:"100%",
        display:"flex",
        flexDirection:"column",
        flexWrap:"wrap",
        blockSize: "fit-content",
        bgcolor: "surface.main",
        boxShadow: "8px 8px 30px rgba(0, 0, 0, 0.03)",
        borderRadius: "20px",
        marginTop: "24px",
      }}
      key={id}
    >
      <CardContent>
        <Typography
          className="text-card"
          sx={{
            color: "text.primary.main",
            fontStyle: "normal",
            fontWeight: "700",
            wordWrap: "break-word",
            fontSize: "14px",
          }}
        >
          {content}
        </Typography>
        <Typography
          className="textlarge-card"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: {xs:"18px",md:"20px"},
            lineHeight: "32px",
            wordWrap: "break-word",
            color: "text.primary.main",
            marginTop: "30px",
          }}
        >
          {plain_text?.text.content}
        </Typography>

        <Typography
          className="text-card"
          sx={{
            color: "text.primary.main",
            fontStyle: "normal",
            fontWeight: "700",
            wordWrap: "break-word",
            fontSize: "16px",
            marginTop: "30px",
          }}
        >
         {date}
        </Typography>
      </CardContent>
    </Card>
    </a>
  );
}
