import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { convertDateFormat, limitDecimalPlaces, videoUrlToImgUrl } from "../utils/extension";
import { Link } from "react-router-dom";
import Image from "./Image";
import MultilineEllipsis from "./_shared/MultilineEllipsis";
import SingleLineEllipsis from "./_shared/SingleLineEllipsis";
import Button from "./Button";
import { createStyles, makeStyles } from "@mui/styles";
import Tooltip from "./Tooltip";
import ImageTemplate from "./ImageTemplate";
import CardLoading from "../pages/home/components/homeCards/Loading";

const PrimaryCard = ({ data, category, id }) => {


  const styles = useStyles();

  const [date, setDate] = useState();
  const [thumnailUrl, setThumnailUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    convertDateFormat(data.date || data?.created_time).then((date) => {
      setDate(date);
    });

    if (
      data?.image !== "" &&
      data?.image !== null &&
      data?.image !== undefined
    ) {
      if (data?.image.toString().includes("youtube")) {
        videoUrlToImgUrl(data.image).then((imgUrl) => {
          setThumnailUrl(imgUrl);
        });
      } else {
        setThumnailUrl(data?.image);
      }
    }
  }, [data]);

  useEffect(() => {

  }, [date]);

  return (
    // <Link
    //   target="_blank"
    //   key={id && id}
    //   to={`/detail/${category}/${data?.id}`}
    //   style={{
    //     textDecoration: "none",
    //   }}
    // >
    <Box
      sx={{
        width: "100%",
        padding: "18px",
        display: "flex",
        flexDirection: "column",
        bgcolor: "surface.main",
        borderRadius: "20px",
        // minWidth:  "300px" ,
        maxWidth: { xs: "300px", md: "300px", lg: "400px" },
        gap: "20px",
        color: "text.primary.main",
      }}
    >
      <Link
        target="_blank"
        key={id && id}
        to={data?.url}
        style={{
          textDecoration: "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            aspectRatio: "368/240",
            borderRadius: "8px",
            maxHeight: "240px",
            position:'relative',
            overflow:'hidden'
          }}
        >
          {
            category === "Ssrn" ?
              <ImageTemplate title={data?.title} date={date} views={data?.views} data={data} /> :
              <Image
                category={category}
                style={{
                  aspectRatio: "368/240",
                  maxHeight: "240px",
                  // scale: isLoaded ? '1' : '2'
                }}
                cover={thumnailUrl}
                url={data?.url}
                title={data?.title || "-"}
                imageHasLoaded={(value) => setIsLoaded(value)}
              />
              
          }
          {/* {category === "Ssrn" ? <></> : !isLoaded && <CardLoading size={"55px"}/>} */}

        </Box>
      </Link>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          // flexWrap: "wrap",
          gap: { xs: "32px", lg: "50px" },
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: { xs: "8px", lg: "10px" },
            width: "100%"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", gap: "16px" }}>
            <Link
              target="_blank"
              key={id && id}
              to={data?.url}
              style={{
                textDecoration: "none",
              }}
            >
              <MultilineEllipsis
                style={{ fontSize: "14px" }}
                color="text.primary.main"
                text={data?.title}
                tooltipId={`my-tooltip-${data?.id}`}
              />
            </Link>

            <Button
              target="_blank"
              url={`/detail/${category}/${data?.id}`}
              text="Open"
              button_class={styles.openBtn}
              textStyles={{ fontSize: "10px" }}
            />
          </Box>

          <MultilineEllipsis
            style={{ fontSize: "13px" }}
            // color="text.primary.main"
            color="text.secondary.main"
            text={data?.description}
          />
        </Box>

        <Box>
          {
            data?.score &&
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
              fontWeight={400}
            >
              Score:  {limitDecimalPlaces(data?.score, 2)}
            </Typography>
          }

          {
            data?.views?.toString() &&
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
              fontWeight={400}
            >
              Views:  {data?.views}
            </Typography>
          }

          {
            data?.rank &&
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
              fontWeight={400}
            >
              rank :  {data?.rank}
            </Typography>
          }

          {
            data?.totalDownloads?.toString() &&
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
              fontWeight={400}
            >
              Total Downloads :  {data?.totalDownloads?.toString()}
            </Typography>
          }

          <Box
            sx={{
              color: "text.secondary.main",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {
              data?.author &&
              <SingleLineEllipsis
                style={{ fontSize: "13px", maxWidth: "60%" }}
                text={data?.author && data?.author}
              />
            }
            {
              data?.location &&
              <Box sx={{ width: "fit-content", }}>
                <Typography
                  sx={{
                    color: "text.secondary.main",
                    fontSize: { xs: "13px", sm: "13px" },
                    display: "flex",
                    alignItems: "center", gap: "4px"
                  }}
                  fontWeight={400}
                >
                  Location:
                </Typography>
                <Typography>{data?.location}</Typography>
              </Box>
            }
            <Typography
              sx={{
                color: "text.secondary.main",
                fontSize: { xs: "13px", sm: "13px" },
              }}
              fontWeight={400}
            >
              {date !== "NaN invalid month NaN" ? date : "Date: -"}
            </Typography>
          </Box>
        </Box>

      </Box>
      <Tooltip id={data?.id} place={"left"} data={data} containerStyles={{ maxWidth: "250px", width: "100%", minWidth: "150px" }} />
    </Box>
    // </Link>
  );
};

export default PrimaryCard;

const useStyles = makeStyles(
  createStyles({
    openBtn: {
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      justifySelf: "flex-end",
      width: "100%",
    },
  })
);
