import getStripe from "../lib/getStripe";
import { loadStripe } from '@stripe/stripe-js';
import { destuctureData } from "./DataDestructure";
import CryptoJS from 'crypto-js';

// Function to encrypt JSON data
export const encryptJSON = (data) => {
  const jsonString = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_CRYPTO_KEY).toString();
  return ciphertext;
};

// Function to decrypt JSON data
export const decryptJSON = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_CRYPTO_KEY);
  const jsonString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(jsonString);
};

export const DB_KEY = {
  ARXIV: "ARXIV",
  SSRN: "SSRN",
  BLOG: "BLOG",
  GITHUB: "GITHUB",
  JOBS: "JOBS",
  REPEC: "REPEC",
  PODCAST: "PODCAST",
  VIDEOS: "VIDEOS",
  PWC: "PWC",
  NEWS: "NEWS",
  LINKEDIN: "LINKEDIN",
  REDDIT: "REDDIT",
}

// Load the Stripe client-side library
// const stripePromise = loadStripe('YOUR_STRIPE_PUBLIC_KEY');

// Function to retrieve subscription details
// export async function fetchSubscriptionDetails(subscriptionId) {
//   const stripe = await stripePromise;

//   try {
//     const subscription = await stripe.subscriptions.retrieve(subscriptionId);
//     return subscription;
//   } catch (error) {
//     console.error('Error fetching subscription details:', error);
//     return null;
//   }
// }


export function shuffle(a) {
  var j, x, i;
  for (i = a?.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

const getMonth = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "invalid month";
  }
};

export const convertDateFormat = (isoDate) => {
  return new Promise(async (resolve, reject) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    const monthName = getMonth(month);
    const dateString = `${dt} ${monthName} ${year}`
    resolve(dateString);
  });
};

export const videoUrlToImgUrl = (url) => {
  return new Promise(async (resolve, reject) => {
    const videoId = url?.split("v=")[1]
    const imgUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
    resolve(imgUrl)
  })
}

export const GetFirebaseToken = async (auth) => {
  try {
    const token = await auth.currentUser.getIdToken();
    return token;
  } catch (error) {
    return null; // or handle the error in an appropriate way
  }
};

export const genRandNum = (min, max) => {
  for (var i = 1; i <= 5; i++) {
    var range = max - min + 1;
    return Math.floor(Math.random() * range) + min;
  }
}

export const BACKEND_URL = "https://ml-quant-dot-alert-howl-193320.uc.r.appspot.com/api/"
export const BACKEND_URL_LOCALHOST = "http://localhost:8000/api/"

export const limitDecimalPlaces = (number, decimalPlaces) => {
  const value = Number(number.toFixed(decimalPlaces));
  return (value)
}

export function distinct(array) {
  return [...new Set(array)];
}

export const searchFilter = async (data, searchBy) => {
  return new Promise(async (resolve, reject) => {
    const arxiv = []
    const ssrn = []
    const seminar = []
    const github = []
    const blogs = []
    const video = []
    const pwc = []
    const news = []
    const linkedin = []
    const reddit = []
    data?.arxiv?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'arxiv');
      arxiv.push(destucturedData)
    });

    data?.ssrn?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'ssrn');
      ssrn.push(destucturedData)
    });

    data?.seminar?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'seminar');
      seminar.push(destucturedData)
    });

    data?.github?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'github');
      github.push(destucturedData)
    });

    data?.blogs?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'blogs');
      blogs.push(destucturedData)
    });

    data?.video?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'videos');
      video.push(destucturedData)
    });

    data?.pwc?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'pwc');
      pwc.push(destucturedData)
    });

    data?.news?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'news');
      news.push(destucturedData)
    });

    data?.linkedin?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'linkedin');
      linkedin.push(destucturedData)
    });

    data?.reddit?.monthly?.forEach(element => {
      const destucturedData = destuctureData(element, 'reddit');
      reddit.push(destucturedData)
    });

    const searchResults =
    {
      "arxiv": arxiv?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "ssrn": ssrn?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "seminar": seminar?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "github": github?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "blogs": blogs?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "video": video?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "pwc": pwc?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "news": news?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "linkedin": linkedin?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
      "reddit": reddit?.filter((data) => {
        if (data?.title?.includes(searchBy) || data?.description?.includes(searchBy)) {
          return data ?? []
        }
      }),
    }
    if (searchResults === undefined) {
      resolve([])
    } else {
      resolve(searchResults)
    }
  })
}

export const isImageUrlValid = (url, callback) => {
  var img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

// const axios = require('axios');
// let data = {
//   "firebaseUID" : ""
// };

// let config = {
//   method: 'post',
//   maxBodyLength: Infinity,
//   url: 'http://localhost:8000/api/signup',
//   headers: { 
//     'Authorization': 'Bearer ' + await GetFirebaseToken(auth);
//   },
//   data : data
// }