import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Imagedetails from "./components/Imagedetails";
import Textdetails from "./components/Textdetails";
import { useLocation } from "react-router-dom";
import "./Details.css";
import {
  getArxivDataById,
  getDataById,
} from "../../components/_shared/ApiRequests";
import { destuctureData } from "../../utils/DataDestructure";
import MainContainer from "../../components/MainContainer";
import Loading from "../../components/_shared/lottie/Loading";
import { Helmet } from "react-helmet";

const Details = () => {

  const [data, setData] = useState();

  const [showLoading, setShowLoading] = useState(true)

  const [dataCategory, setDataCategory] = useState();

  const getData = async (id, category) => {
    await getDataById(category, id).then((data) => {
      const item = destuctureData(data.data, category);
      setData(item);
      setShowLoading(false)
    }).catch((error) => {
      console.log(error)
      setShowLoading(false)
    })
  };
  useEffect(() => {
    const id = window.location.pathname.split("/")[3];
    const category = window.location.pathname.split("/")[2];
    setDataCategory(category);

    if (category) {
      getData(id, category);
    }
  }, []);

  useEffect(() => { }, [data]);

  return (
    <Box sx={{ backgroundColor: "primary.main", width: "100%", marginTop:"-72px"}}>
      <Helmet>
        <title>{data?.title}</title>
        <meta
          name="description"
          content={data?.description}
        />
      </Helmet>
      {
        showLoading ? <Loading /> :
          <>
            <Imagedetails data={data} category={dataCategory} />
            <MainContainer>
              <Textdetails data={data} category={dataCategory} />
              <Box sx={{ marginTop: "20px" }}>&nbsp;</Box>
            </MainContainer></>
      }

    </Box>
  );
};

export default Details;
