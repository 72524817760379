import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import MainContainer from "../../components/MainContainer";
import PrimaryCard from "../../components/PrimaryCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getDataFromDatabase } from "../../components/_shared/ApiRequests";
import Loading from "../../components/_shared/lottie/Loading";
import { useContext } from "react";
import { GlobalState, getCachedData, getDataFromIndexedDB, storeDataInIndexedDB } from "../../App";
import Dropdown from "../../components/Dropdown";
import { Helmet } from "react-helmet";
import { DB_KEY } from "../../utils/extension";

const Blogs = () => {

  const { isDarkMode } = useContext(GlobalState);

  // data sort by handling state
  const [sortBy, setSortBy] = useState("Latest")

  const [value, setValue] = React.useState(0);

  const [blogsData, setBlogsData] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [activePageNumber, setActivePageNumber] = useState(1);

  // view more handling states
  const [activeLatestViewMore, setActiveLatestViewMore] = useState(true);
  const [activeWeeklyViewMore, setActiveWeeklyViewMore] = useState(true);
  const [activeMonthlyViewMore, setActiveMonthlyViewMore] = useState(true);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ display: "flex" }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activePageNumber === 1) {
      getCachedData().then((data => {
        const rData = data;
        const retrievedData = rData.data
        if (retrievedData) {
          setDataAndStopLoading(retrievedData)
        }
      }))
    }
    getDataFromDatabase("blogs", activePageNumber).then(async (listData) => {
      await storeDataInIndexedDB(DB_KEY.BLOG, listData)
      const retrievedData = await getDataFromIndexedDB(DB_KEY.BLOG);
      const list = retrievedData.data
      if (list) {
        // setArxivData(list);
        if (list?.latest?.length === 0) {
          setActiveLatestViewMore(false);
        }
        if (list?.weekly?.length === 0) {
          setActiveWeeklyViewMore(false);
        }
        if (list?.monthly?.length === 0) {
          setActiveMonthlyViewMore(false);
        }

        setDataAndStopLoading(list)
      }
    });
  }, [activePageNumber]);

  const getCachedData = async () => {
    const data = await getDataFromIndexedDB(DB_KEY.BLOG);
    return data;
  }

  const setDataAndStopLoading = (data) => {
    setDataList(data);
    setShowLoading(false);
    setPaginationLoading(false);
  }

  useEffect(() => {
    if (dataList.length !== 0) {
      setBlogsData((prevList) => {
        if (prevList?.length !== 0) {
          // Spread the previous list and append the new data
          return {
            latest: [...prevList?.latest, ...dataList?.latest],
            monthly: [...prevList?.monthly, ...dataList?.monthly],
            weekly: [...prevList?.weekly, ...dataList?.weekly],
            all: [...prevList?.all, ...dataList?.all],
          };
        } else {
          // If prevList is undefined or empty, set dataList directly
          return dataList;
        }
      });
      if (activePageNumber < 5) {
        setActivePageNumber(activePageNumber + 1)
      }
    }
  }, [dataList]);

  return (
    <Box
      sx={{
        bgcolor: "background.main",
        width: "100%",
        color: "text.primary.main",
        paddingTop: "56px",
      }}
    >
      <Helmet>
        <title>ML-Quant Blogs: Machine Learning & Quantitative Finance</title>
        <meta
          name="description"
          content="Dive into the ML-Quant Blogs Page for insights at the crossroads of Machine Learning and Quantitative Finance. Explore curated posts on algorithmic trading, predictive modeling, and financial analytics. Stay informed and enhance your understanding in this dynamic field. Your go-to source for simple, yet impactful content at the intersection of technology and finance."
        />
        <meta
          name="keywords"
          content="Blogs, Machine Learning, Quantitative Finance, Algorithmic Trading, Predictive Modeling, Financial Analytics, Data-driven Insights, Finance Technology, ML-Quant Blogs, Technology in Finance, Financial Trends, Market Analysis, Investment Strategies, Data Science, Artificial Intelligence, Finance Blogosphere"
        />
      </Helmet>

      <MainContainer>
        <Box
          sx={{
            width: "100%",
            color: "text.primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: "40px", md: "56px" },
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "42px",
            }}
          >

            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "42px",
              }}
            >

              <Box sx={{
                display: "flex", width: "100%",
                justifyContent: "space-between", flexWrap: "wrap", flexDirection: { xs: "column", md: "row" },
                alignItems: "center", gap: { xs: "40px", md: "0px" },
              }}>
                <Box sx={{ minWidth: "168px", display: { xs: "none", md: "flex" }, width: { xs: "0px", md: "auto" }, }}></Box>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    borderRadius: "14px",
                    width: "100%",
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    maxHeight: { xs: "45px", md: "50px" },
                    display: "flex",
                  }}
                >
                  <Tabs
                    indicatorColor="none"
                    sx={{
                      maxHeight: { xs: "45px", md: "50px" },
                      minHeight: "auto",
                    }}
                    style={{
                      padding: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 0.876712px solid #373A50",
                      borderRadius: " 14.0274px",
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 0 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 0
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 0 ? "600" : "600",
                      }}
                      label="Latest"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 1 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 1
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 1 ? "600" : "600",
                      }}
                      label="Weekly"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: {
                          xs: "11px !important",
                          md: "12px !important",
                        },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 2 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 2
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 2 ? "600" : "600",
                      }}
                      label="Monthly"
                      {...a11yProps(2)}
                    />

                    <Tab
                      sx={{
                        fontSize: { xs: "11px !important", md: "12px !important" },
                        minWidth: "auto",
                        padding: {
                          xs: "12px 20px 12px 20px",
                          md: "13px 43px 13px 43px",
                        },
                      }}
                      style={{
                        minHeight: "auto",
                        color: value === 3 ? "white" : "#7D7A89",
                        flex: "1",
                        textAlign: "center",
                        borderRadius: "11px",
                        backgroundColor:
                          value === 3
                            ? isDarkMode
                              ? "#373A50"
                              : "#3367B3"
                            : "transparent",
                        textTransform: "capitalize",
                        letterSpacing: "0.9px",
                        lineHeight: "110%",
                        fontWeight: value === 3 ? "600" : "600",
                      }}
                      label="All"
                      {...a11yProps(3)}
                    />
                  </Tabs>

                </Box>

                <Box
                  sx={{ width: { xs: "100%", md: "fit-content" }, display: "flex", justifyContent: "flex-start" }}
                >
                  <Dropdown
                    options={["All", "Author", "Latest", "Title"]}
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  />
                </Box>
              </Box>

              {value === 0 ? (
                <TabPanel value={value} index={0}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {blogsData?.latest
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            // If the current item is the first occurrence, keep it.
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase() === "latest" ? new Date(b.properties.Date.date.start).getTime() -
                              new Date(a.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "author" ? a.properties.Author.rich_text[0].plain_text.localeCompare(
                                b.properties.Author.rich_text[0].plain_text
                              ) :
                                sortBy.toLowerCase() === "title" && a.properties.Title.title[0].plain_text.localeCompare(
                                  b.properties.Title.title[0].plain_text
                                )
                          )
                          ?.map((data, index) => {

                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const link = data.properties.URL.url;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                Image: { url },
                                Date: {
                                  date: { start },
                                },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: link,
                              },
                            ];
                            return (
                              <PrimaryCard
                                category="Blogs"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeLatestViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          // opacity: isNextActive ? 1 : 0.1,
                          opacity: activeLatestViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 1 ? (
                <TabPanel value={value} index={1}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {blogsData?.weekly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            // If the current item is the first occurrence, keep it.
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase() === "latest" ? new Date(b.properties.Date.date.start).getTime() -
                              new Date(a.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "author" ? a.properties.Author.rich_text[0].plain_text.localeCompare(
                                b.properties.Author.rich_text[0].plain_text
                              ) :
                                sortBy.toLowerCase() === "title" && a.properties.Title.title[0].plain_text.localeCompare(
                                  b.properties.Title.title[0].plain_text
                                )
                          )
                          ?.map((data, index) => {
                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const link = data.properties.URL.url;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                Image: { url },
                                Date: {
                                  date: { start },
                                },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: link,

                              },
                            ];
                            return (
                              <PrimaryCard
                                category="Blogs"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeWeeklyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }}
                        sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          // opacity: isWeeklyNextActive ? 1 : 0.1,
                          opacity: activeWeeklyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 2 ? (
                <TabPanel value={value} index={2}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {blogsData?.monthly
                          ?.filter((value, index, self) => {
                            const firstIndex = self.findIndex(
                              (other) => other.properties.Title.title[0].plain_text === value.properties.Title.title[0].plain_text
                            );
                            // If the current item is the first occurrence, keep it.
                            return index === firstIndex;
                          })
                          .slice()
                          ?.sort((a, b) =>
                            sortBy.toLowerCase() === "latest" ? new Date(a.properties.Date.date.start).getTime() -
                              new Date(b.properties.Date.date.start).getTime() :
                              sortBy.toLowerCase() === "author" ? a.properties.Author.rich_text[0].plain_text.localeCompare(
                                b.properties.Author.rich_text[0].plain_text
                              ) :
                                sortBy.toLowerCase() === "title" && a.properties.Title.title[0].plain_text.localeCompare(
                                  b.properties.Title.title[0].plain_text
                                )
                          )
                          ?.map((data, index) => {
                            const author =
                              data.properties.Author.rich_text[0]?.plain_text;
                            const link = data.properties.URL.url;
                            const {
                              id,
                              cover,
                              properties: {
                                Title: {
                                  title: [
                                    {
                                      text: { content },
                                    },
                                  ],
                                },
                                Description: {
                                  rich_text: [plain_text],
                                },
                                Image: { url },
                                Date: {
                                  date: { start },
                                },
                              },
                            } = data;

                            const filteredData = [
                              {
                                id: id,
                                image: url,
                                title: content,
                                description: plain_text.text.content,
                                date: start,
                                author: author,
                                url: link,
                              },
                            ];
                            return (
                              <PrimaryCard
                                category="Blogs"
                                data={filteredData[0]}
                              />
                            )
                          })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }} sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          // opacity: isMonthlyNextActive ? 1 : 0.1,
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              ) : value === 3 && (
                <TabPanel value={value} index={3}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: { xs: "24px", lg: "32px" },
                        }}
                      >
                        {
                          blogsData?.all
                            // [...blogsData?.latest, ...blogsData?.weekly, ...blogsData?.monthly]
                            ?.filter((value, index, self) => {
                              const firstIndex = self.findIndex(
                                (other) => other?.properties?.Title?.title[0]?.plain_text === value?.properties?.Title?.title[0]?.plain_text
                              );
                              // If the current item is the first occurrence, keep it.
                              return index === firstIndex;
                            })
                            .slice()
                            ?.sort((a, b) =>
                              sortBy.toLowerCase() === "latest" ? new Date(b?.properties?.Date?.date?.start)?.getTime() -
                                new Date(a.properties?.Date?.date?.start).getTime() :
                                sortBy.toLowerCase() === "author" ? a.properties?.Author?.rich_text[0]?.plain_text?.localeCompare(
                                  b?.properties.Author?.rich_text[0]?.plain_text
                                ) :
                                  sortBy.toLowerCase() === "title" && a.properties?.Title?.title[0]?.plain_text?.localeCompare(
                                    b?.properties?.Title?.title[0]?.plain_text
                                  )
                            )
                            ?.map((data, index) => {
                              const author =
                                data?.properties?.Author?.rich_text[0]?.plain_text;
                              const link = data?.properties?.URL?.url;
                              const date = data?.properties?.Date?.date?.start
                              const {
                                id,
                                cover,
                                properties: {
                                  Title: {
                                    title: [
                                      {
                                        text: { content },
                                      },
                                    ],
                                  },
                                  Description: {
                                    rich_text: [plain_text],
                                  },
                                  Image: { url },
                                  // Date: {
                                  //   date: { start },
                                  // },
                                },
                                created_time,
                              } = data;

                              const filteredData = [
                                {
                                  id: id,
                                  image: url,
                                  title: content,
                                  description: plain_text.text.content,
                                  date: date,
                                  author: author,
                                  url: link,
                                  created_time,
                                },
                              ];
                              return (
                                <PrimaryCard
                                  category="Blogs"
                                  data={filteredData[0]}
                                />
                              )
                            })}
                      </Box>
                      {
                        paginationLoading && (
                          <Loading />
                        )
                      }
                      <Box
                        onClick={() => { activeMonthlyViewMore && setPaginationLoading(true); setActivePageNumber(activePageNumber + 1) }} sx={{
                          paddingY: "13px",
                          bgcolor: "#1E1F2E",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "14px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "170px" },
                          marginTop: "40px",
                          marginBottom: "75px",
                          border: "0.876712px solid #373A50",
                          // opacity: isMonthlyNextActive ? 1 : 0.1,
                          opacity: activeMonthlyViewMore ? 1 : 0.1,
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          color="white"
                          fontWeight={600}
                          fontSize="12px"
                        >
                          VIEW MORE
                        </Typography>
                      </Box>
                    </>
                  )}
                </TabPanel>
              )}
            </Box>
            {/* End Tabs section */}
          </Box>
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Blogs;
