import { Box, Stack } from '@mui/material'
import React from 'react'

const MainContainer = ({ children, bgcolor }) => {
    return (
        <Stack width='100%' display="flex" justifyContent="center"
         bgcolor={bgcolor ? bgcolor : "background.main"}
          >
            <Box sx={{
                maxWidth: { xs: 1140, md: 1440 },
                width: '100%',
                alignSelf: 'center',
                paddingInline: { xs: '24px', md: '40px' },
            }} >
                {children}
            </Box>
        </Stack>
    )
}

export default MainContainer