import React from 'react'
import LottieLoader from 'react-lottie-loader'
import lottie from "./lottie.json"
import { Box } from '@mui/material'
const Loading = ({minHeight="250px"}) => {
  return (
    <Box sx={{
        width:"100%",
        height:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        minHeight:minHeight
    }}>
       <LottieLoader style={{width:"50px", height:"50px",}} animationData={lottie} />
    </Box>
  )
}

export default Loading
