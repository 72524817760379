import { Box, useMediaQuery, useTheme } from "@mui/material";
import logo from "../../assets/Icons/logo.svg";
import React from "react";
import MainContainer from "../../components/MainContainer";
import mateyai from "../../images/matey-ai.svg";
import Cards from "./components/Cards";
import { useContext } from "react";
import { GlobalState } from "../../App";
const Pricing = () => {
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const { subscription } = useContext(GlobalState);


  return (
    <MainContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "42px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "52px", height: "52px" }}
            />
            <Box
              sx={{
                color: "text.primary.main",
                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                marginTop: "10px",
                fontFamily: "Poppins",
              }}
            >
              ML & Quant Group
            </Box>
            <Box
              sx={{
                color: "text.secondary.main",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "center",
                maxWidth: "350px",
                fontFamily: "Poppins",
              }}
            >
              Includes: Arxiv, SSRN, Blogs, Videos, Podcasts, News, Linkedin,
              Github, and Reddit
            </Box>
          </Box>

          <Box>
            <img
              src={mateyai}
              alt="pricing_illustration"
              style={{ width: md ? "300px" : "573px" }}
            />
          </Box>
          <Box
            display={"flex"}
            sx={{
              marginTop: "30px",
              gap: "20px",
              flexDirection: md ? "column" : "row",
            }}
          >
            <Cards subscribed={subscription} price={"35"} priceId={process.env.REACT_APP_STRIPE_PRICE_ID_1}/>
            <Cards subscribed={subscription} price={"315"} text={"/year"} priceId={process.env.REACT_APP_STRIPE_PRICE_ID_2}/>
            {/* <CardsYear /> */}
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Pricing;
