import React, { useContext } from 'react';
import { TextField, Box, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { GlobalState } from '../../../App';
import CalendarIcon from '../../../assets/Icons/Calendar'
import Loading from '../../../components/_shared/lottie/Loading';

const CustomTextField = styled(TextField)(({ isDarkMode, md, sm }) => ({
  width: sm ? "260px" : md ? '324px' : '350px',
  height: '52px',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '6px',
  padding: '18px',
  color: isDarkMode ? 'rgba(55, 58, 80, 0.30)' : 'black',
  fontFamily: "Poppins",
  fontSize: md ? "12px" : '14px',

  '& input': {
    fontSize: md ? "12px" : '14px',
  },

  '& input::placeholder': {
    color: isDarkMode ? 'rgba(255, 255, 255, 0.25)' : 'black',
    fontSize: md ? "12px" : '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    paddingLeft: '0px',
    fontFamily: "Poppins"
  },

}));

const CustomTypography = styled('div')(({ sm }) => ({
  fontSize: sm ? "12px" : '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  fontFamily: "Poppins"
}));

const MyTextField = ({ label, placeholder, marginTop = '16px', type = "text", error, helperText, state, setState, disabled, loading }) => {
  const { isDarkMode } = useContext(GlobalState);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("365"));

  return (
    <Box>
      <CustomTypography sm={sm} style={{ marginTop: marginTop, color: isDarkMode ? '#7D7A89' : 'rgba(0, 0, 0, 0.5)', }}>
        {label}
      </CustomTypography>
      <CustomTextField
        disabled={disabled}
        placeholder={placeholder}
        variant="standard"
        sx={{
          background: isDarkMode ? 'rgba(55, 58, 80, 0.30)' : 'white',
          color: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.5)',
        }}
        InputProps={{
          disableUnderline: true,
          // startAdornment: loading && (
          //   <Loading />
          // ),
          endAdornment: loading ? (
            <Loading />
          ) :
            type === "date" && (
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            ),
        }}
        isDarkMode={isDarkMode}
        md={md}
        sm={sm}
        type={type}
        error={error}
        helperText={helperText}
        onChange={(e) => setState(e.target.value)}
        value={state}

      />
    </Box>
  );
};

export default MyTextField;
